import styled from "styled-components";

export const StyledDiv = styled.div.withConfig({
    shouldForwardProp: props => !['fullWidth'].includes(props)
})`
    position: relative;
    width: ${({fullWidth}) => fullWidth ? '100%' : 'auto'};
    padding: 0.5rem 1rem;
    background: transparent;
    border: 1px solid rgba(230, 230, 230, 1);
    border-radius: 1vh;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-color-dark);
    transition: all 0.25s ease-in-out;
`