import React from 'react';
import backgroundImage from '../assets/images/home-bg.png';
import styled from 'styled-components';
import { BACKEND_URL } from '../constants';
import CustomButton from '@/components/UI/buttons/CustomButton';

const BackgroundContainer = styled.div`
    position: relative;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    background-image: url(${backgroundImage});
    background-size: cover;
`;

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 40vh;
    width: 100%;
    padding: 3vh;
    background-color: white;
    border-radius: 24px 24px 0 0;
`;

const Login = () => {
    const handleGoogleLogin = () => {
        const currentOrigin = window.location.origin;
        const backend_redirect = `${BACKEND_URL}/grantcode`;
        const frontend_redirect = `${currentOrigin}/auth/callback`;

        const params = new URLSearchParams({
            redirect_uri: backend_redirect,
            state: frontend_redirect,
            response_type: 'code',
            client_id: '118475204013-lva4jjqeu3trdrcre7ij3i6l45k2t7m1.apps.googleusercontent.com',
            scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid',
            access_type: 'offline',
        });

        const loginLink = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;
        window.location.href = loginLink;
    };

    return (
        <BackgroundContainer>
            <LoginContainer>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5vh' }}>
                    <h2 style={{ textAlign: 'center' }}> Welcome to EasyRentor </h2>
                    <p style={{ textAlign: 'center' }}>
                        Get everything you need for property management in one convenient app
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1.5vh',
                        width: '100%',
                    }}
                >
                    <CustomButton onClick={handleGoogleLogin}>Continue with Google</CustomButton>
                    {/* <CustomButton onClick={() => handleLogin(LANDLORD_ID)}>
                        Continue as LANDLORD
                    </CustomButton>
                    <CustomButton onClick={() => handleLogin(TENANT_ID)}>
                        Continue as TENANT
                    </CustomButton> */}
                </div>
            </LoginContainer>
        </BackgroundContainer>
    );
};

export default Login;
