import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const TriggerableModal = ({ trigger, header, body }) => {
    const [visible, setVisible] = useState(false);
    const toggleOpen = () => {
        setVisible(true);
    };
    const toggleClose = () => {
        setVisible(false);
    };

    return (
        <>
            <div
                onClick={(e) => {
                    toggleOpen();
                    if (trigger.props.onClick) trigger.props.onClick(e);
                }}
            >
                {trigger}
            </div>

            <Modal show={visible} centered={true} onHide={toggleClose}>
                {header && <Modal.Header closeButton>{header}</Modal.Header>}

                <Modal.Body>{body && body}</Modal.Body>
            </Modal>
        </>
    );
};

export default TriggerableModal;
