import { useField } from 'formik';
import { FC } from 'react';
import styles from './CustomRangeField.module.css';
import { CustomRangeFieldProps } from '@/components/UI/inputs/RangeField/types';
import clsx from 'clsx';

const CustomRangeField: FC<CustomRangeFieldProps> = ({ label, className, ...props }) => {
    const [field, meta] = useField(props.name);

    return (
        <div>
            {label && (
                <label htmlFor={props.id || props.name} className={styles.label}>
                    {label}
                </label>
            )}
            <input
                {...field}
                {...props}
                type="number"
                className={clsx(styles.range, props.disabled && styles.disabled, className)}
            />

            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
        </div>
    );
};

export default CustomRangeField;
