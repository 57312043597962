import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Header, ListContainer } from './PropertyForms.styles';
import { useFetching } from '../../hooks/useFetching';
import EasyRentorService from '../../service/EasyRentorService';
import { useSelector } from 'react-redux';
import { Formik, Form, useFormikContext } from 'formik';
import { FormContext } from '../../context/PropertyFormContext';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';
import RoundButton from '../UI/buttons/RoundButton';
import { BackArrowIcon, NotificationsProfileIcon } from '../UI/icons';
import CustomSelect from '@/components/UI/inputs/CustomSelect/CustomSelect';
import CustomButton from '@/components/UI/buttons/CustomButton';
import TextInput from '@/components/UI/inputs/TextInput';

const validationSchema = Yup.object({
    name: Yup.string().required('Max Guest Amount Required'),
    securityDepositAmount: Yup.number()
        .typeError('Must be a number')
        .required('securityDepositAmount Required')
        .positive(), //TODO: implement float validation
    cleaningFee: Yup.number()
        .typeError('Must be a number')
        .required('cleaningFee Required')
        .positive(), //TODO: implement float validation
    minRentalDays: Yup.number()
        .typeError('Must be a number')
        .required('minRentalDays Required')
        .positive()
        .integer(),
    minMonthlyRentalNoticePeriodDays: Yup.number()
        .typeError('Must be a number')
        .required('minMonthlyRentalNoticePeriodDays Required')
        .positive()
        .integer(),
    checkIn: Yup.string().required('Check-In Time Required'),
    checkOut: Yup.string().required('Check-Out Time Required'),
});

const formSchema = {
    name: {
        label: 'Name of the contract template',
        placeholder: 'My contract template',
    },
    securityDepositAmount: {
        label: 'Security deposit amount',
        placeholder: '200',
    },
    cleaningFee: {
        label: 'Cleaning fee',
        placeholder: '20',
    },
    minRentalDays: {
        label: 'Minimum amount of rental days',
        placeholder: '5',
    },
    minMonthlyRentalNoticePeriodDays: {
        label: 'Minimum period of monthly rental notice (days)',
        placeholder: '1',
    },
    checkIn: {
        label: 'Check-in time',
        placeholder: '12:00',
    },
    checkOut: {
        label: 'Check-out time',
        placeholder: '11:00',
    },
};

const FormikStateChanger = ({ creating }) => {
    const { setFieldValue } = useFormikContext();
    const prevCreatingState = useRef(creating);

    useEffect(() => {
        if (creating === true && !prevCreatingState.current) {
            setFieldValue('name', '');
        }
        prevCreatingState.current = creating;
    }, [creating, setFieldValue]);

    return null;
};

const ContractTemplateManager = ({ onNextStep, onPrevStep }) => {
    const userData = useSelector((state) => state.users.userData);
    const { formData, updateContractTemplate } = useContext(FormContext);

    const [contractTemplates, setContractTemplates] = useState([]);
    const [selectedTemplateID, setSelectedTemplateID] = useState(
        formData.contractTemplate.selectedContractTemplateID || null
    );
    const [creating, setCreating] = useState(formData.contractTemplate?.creating || false);
    const [fetchContractTemplates, loadingContractTemplate, errorContractTemplate] = useFetching(
        async (id) => {
            const response = await EasyRentorService.getLandlordContractTemplates(id);
            const templates = response.data.content;
            setContractTemplates(templates);
        }
    );

    const templateOptions = useMemo(() => {
        if (contractTemplates.length === 0) return [];
        return contractTemplates.map((template) => ({ value: template.id, name: template.name }));
    }, [contractTemplates]);

    useEffect(() => {
        fetchContractTemplates(userData.id);
    }, [userData.id]);

    const initialValues = {
        name: formData.contractTemplate.name || '',
        securityDepositAmount: formData.contractTemplate.securityDepositAmount || '',
        cleaningFee: formData.contractTemplate.cleaningFee || '',
        minRentalDays: formData.contractTemplate.minRentalDays || '',
        minMonthlyRentalNoticePeriodDays:
            formData.contractTemplate.minMonthlyRentalNoticePeriodDays || '',
        checkIn: formData.contractTemplate.checkIn || '',
        checkOut: formData.contractTemplate.checkOut || '',
    };

    const onTemplateChange = (e, setFieldValue) => {
        const templateId = e.target.value;
        const template = contractTemplates.find((t) => t.id === templateId);

        if (template) {
            for (const key of Object.keys(initialValues)) {
                setFieldValue(key, template[key]);
            }
            setSelectedTemplateID(templateId);
            setCreating(false);
        } else {
            for (const key of Object.keys(initialValues)) {
                setFieldValue(key, '');
            }
            setSelectedTemplateID(null);
            setCreating(true);
        }
    };

    const handleFieldChange = (e, setFieldValue, field) => {
        if (selectedTemplateID) {
            setSelectedTemplateID(null);
        }
        if (creating === false) {
            setCreating(true);
        }

        console.log('handleFieldChange', field, e.target.value);
        setFieldValue(field, e.target.value);
    };

    const onSubmit = (values) => {
        updateContractTemplate({
            ...values,
            creating: creating,
            selectedContractTemplateID: selectedTemplateID,
        });
        onNextStep();
    };

    // console.log("FORM STATE", formData)

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <RoundButton onClick={onPrevStep}>
                    {/* Button to go back */}
                    <BackArrowIcon />
                </RoundButton>
                <RoundButton>
                    <NotificationsProfileIcon />
                </RoundButton>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '3vh 0 3vh 0',
                    gap: '0.5rem',
                }}
            >
                <Header> Select contract template </Header>
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) => {
                    // console.log("TEMPALTE", Object.keys(values), Object.values(values))
                    return (
                        <Form className="flex-col justify-content-between flex-grow-1">
                            <FormikStateChanger creating={creating} />
                            <div className="flex-col">
                                <div className="flex-col">
                                    {loadingContractTemplate ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                            style={{
                                                width: '5rem',
                                                height: '5rem',
                                                margin: 'auto',
                                            }}
                                        />
                                    ) : contractTemplates.length > 0 ? (
                                        <div style={{ padding: '1rem 0 1.5rem 0' }}>
                                            <CustomSelect
                                                key="selectedTemplateID"
                                                label={'Select contract'}
                                                id="selectedTemplateID"
                                                name="selectedTemplateID"
                                                options={templateOptions}
                                                placeholder={'Select contract from saved'}
                                                onChange={(e) => onTemplateChange(e, setFieldValue)}
                                                value={selectedTemplateID || ''}
                                            />
                                        </div>
                                    ) : (
                                        <div style={{ padding: '1.5rem' }}>
                                            <p className="text-center">
                                                No contract templates found. Please create one using
                                                form below.
                                            </p>
                                        </div>
                                    )}
                                </div>

                                <hr key="contractTemplateDivider" />

                                <ListContainer>
                                    {Object.keys(values).map((value) => {
                                        // TODO: FIX MEE THIS IS VERY UGLY
                                        // console.log('CURRENT FIELD', value);
                                        if (value === 'selectedTemplateID') {
                                            // console.log("SKIPPP")
                                            return null;
                                        }
                                        const schema = formSchema[value];
                                        return (
                                            <div key={value}>
                                                <TextInput
                                                    label={schema.label}
                                                    id={value}
                                                    name={value}
                                                    placeholder={schema.placeholder}
                                                    onChange={(e) =>
                                                        handleFieldChange(e, setFieldValue, value)
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </ListContainer>
                            </div>

                            <CustomButton type="submit" style={{ marginTop: '2rem' }}>
                                Continue
                            </CustomButton>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default ContractTemplateManager;
