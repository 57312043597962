import React, { useEffect, useRef, useState } from 'react'
import FullscreenModal from '../UI/modals/FullscreenModal'
import { Spinner } from 'react-bootstrap';
import { StaticIcon } from '../UI/icons/Icons.styles';
import { CSSTransition } from 'react-transition-group';
import { CheckCircle } from 'react-bootstrap-icons';
import './SubmitStatusModal.css'

const SubmitStatusModal = ({show, onClose}) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const prepareRef = useRef(null)
    const loadingRef = useRef(null)
    const successRef = useRef(null)


    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => setLoading(true), 500);
            return () => clearTimeout(timer)
        }
    }, [show])

    useEffect(() => {
        if (!show && loading) {
            setSuccess(true);
            const timer = setTimeout(() => {
                setLoading(false);
                setSuccess(false)
                onClose && onClose()
            }, 300);
            return () => clearTimeout(timer)
        }
    }, [show, loading, onClose])

    // console.log("SUBMIT MODAL")

    return (
        <FullscreenModal show={show}>
            <div className='text-center'>
                <CSSTransition
                    in={!loading && !success}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                    nodeRef={prepareRef}
                >
                    <h2 ref={prepareRef} className="mb-4">Preparing submission...</h2>
                </CSSTransition>

                <CSSTransition
                    in={loading && !success}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                    nodeRef={loadingRef}
                >
                    <div ref={loadingRef}>
                        <h4>Submitting data...</h4>
                        <Spinner animation="border" role="status" className="mb-3" />
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={success}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                    nodeRef={successRef}
                >
                    <div ref={successRef}>
                        <h4>Submission successful!</h4>
                        <CheckCircle className="text-success mb-3" size={48} />
                    </div>
                </CSSTransition>
            </div>
        </FullscreenModal>
    )
}

export default SubmitStatusModal
