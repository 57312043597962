import React, { useState, useRef, FC } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'react-bootstrap-icons';
import { CustomDropdownProps } from '@/components/UI/dropdowns/CustomDropdown/types';

const dropdownVariants = {
    hidden: {
        height: 0,
        opacity: 0,
        transition: {
            height: {
                duration: 0.3,
            },
            opacity: {
                duration: 0.1,
                delay: 0.2,
            },
        },
    },
    visible: {
        height: 'auto',
        opacity: 1,
        transition: {
            height: {
                duration: 0.3,
            },
            opacity: {
                duration: 0.15,
                delay: 0.05,
            },
        },
    },
};

const CustomDropdown: FC<CustomDropdownProps> = ({ header, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleHeaderClick = (e: React.MouseEvent<HTMLDivElement>) => {
        toggleDropdown();
        if (React.isValidElement(header) && header.props.onClick) {
            header.props.onClick(e);
        }
    };

    return (
        <div className="dropdown">
            <div
                className="flex-row justify-content-between cursor-pointer"
                onClick={handleHeaderClick}
            >
                {header}
                <motion.div
                    animate={{ rotate: isOpen ? -180 : 0 }}
                    transition={{ duration: 0.3 }}
                    className="ml-2"
                >
                    <ChevronDown size={20} />
                </motion.div>
            </div>

            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.div
                        className="dropdown-content"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={dropdownVariants}
                        style={{ overflow: 'hidden' }}
                    >
                        <div ref={contentRef} className="p-2">
                            {children}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CustomDropdown;
