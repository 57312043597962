import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFetching } from '../../hooks/useFetching';
import EasyRentorService from '../../service/EasyRentorService';
import { Spinner } from 'react-bootstrap';
import { Form, Formik, useFormikContext } from 'formik';
import { usePostSingle } from '../../hooks/usePostSingle';
import { REPORT_STATUS } from '../../constants';
import SubmitStatusModal from '../../components/SubmitStatusModal';
import { useNavigate } from 'react-router-dom';
import CustomStepper from '../../components/CustomStepper/CustomStepper';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';
import { formatDateStringISO } from 'utils/utils';
import CommentModal from 'components/UI/modals/CommentModal/CommentModal';
import CustomButton from '@/components/UI/buttons/CustomButton';
import ButtonCheckbox from '@/components/UI/inputs/ButtonCheckbox';
import { ContractStatus } from '@/types/domain/contract/constants';

const FormikStateChanger = ({ onChange }) => {
    const { values } = useFormikContext();

    useEffect(() => {
        onChange(values);
    }, [values]);

    return null;
};

const ReportSingleLandlord = ({ report_uuid, reportStatus, reportCreatedAt, annotations }) => {
    const navigate = useNavigate();
    const [assetPairs, setAssetPairs] = useState([]);
    const [fetchAssetPairs, loadingAssetPairs, errorAssetPairs] = useFetching(async (id) => {
        const response = await EasyRentorService.getReportAssetPairsByID(id);
        // console.log(response.data.content);
        setAssetPairs(response.data.content);
    });
    const [contractData, setContractData] = useState(null);
    const [fetchContractData, loadingContractData, errorContractData] = useFetching(async (id) => {
        const response = await EasyRentorService.getContractByID(id);
        // console.log(response.data);
        setContractData(response.data);
    });

    useEffect(() => {
        fetchAssetPairs(report_uuid);
        fetchContractData(report_uuid);
    }, [report_uuid]);

    const [pairsStatus, setPairsStatus] = useState({});
    const handleFormChange = (values) => {
        setPairsStatus(values);
    };
    const initialFormValues = useMemo(() => {
        return assetPairs.reduce((acc, item) => {
            const { id, landlordComment, approvedByLandlord } = item;
            acc[id] = {
                landlordComment: landlordComment || '',
                approvedByLandlord: approvedByLandlord || false,
            };
            return acc;
        }, {});
    }, [assetPairs]);
    useEffect(() => {
        setPairsStatus(initialFormValues);
    }, [initialFormValues]);

    const isStepApproved = useCallback(
        (index) => {
            const currentId = assetPairs[index]?.id;
            return pairsStatus[currentId]?.approvedByLandlord || false;
        },
        [pairsStatus]
    );

    const [step, setStep] = useState(0);
    const [visibleOriginal, setVisibleOriginal] = useState(false);
    const toggleOriginal = (showOriginal) => {
        setVisibleOriginal(showOriginal);
    };

    const assetAnnotations = useMemo(() => {
        if (loadingAssetPairs || !annotations) {
            return null;
        }
        const currentPair = assetPairs[step];
        if (!currentPair || !currentPair.newAsset) {
            return null;
        }
        // console.log(currentPair);
        return annotations[currentPair?.newAsset.id];
    }, [assetPairs, step, annotations, loadingAssetPairs]);

    const [showModal, setShowModal] = useState(false);
    const [submitSingle, loading, error, response, submitStatus] = usePostSingle();

    const submissionSteps = [
        {
            type: 'REPORT_ASSET_PAIRS',
            submit: EasyRentorService.submitReportAssetPair,
            prepare: (prevData) => {
                const assetPairsDB = Object.entries(prevData.INIT_FORM).map(([id, data]) => {
                    const currentAssetPair = assetPairs.find((pair) => pair.id === id);
                    return {
                        id,
                        reportId: report_uuid,
                        newAssetId: currentAssetPair.newAsset.id,
                        originalAssetId: currentAssetPair.originalAsset.id,
                        ...data,
                    };
                });
                return assetPairsDB;
            },
            isArray: true,
        },
        {
            type: 'REPORT',
            submit: EasyRentorService.submitReport,
            prepare: (prevData) => {
                const isAllApproved = Object.values(prevData.INIT_FORM).every(
                    (item) => item.approvedByLandlord === true
                );
                // console.log("isAllApproved", isAllApproved)
                return {
                    id: report_uuid,
                    annotated: true,
                    status: isAllApproved
                        ? REPORT_STATUS.FINISHED
                        : REPORT_STATUS.WAITING_FOR_AGREEMENT,
                };
            },
        },
        {
            type: 'CONTRACT_UPDATE',
            submit: EasyRentorService.submitContract,
            prepare: (prevData) => {
                const isAllApproved = Object.values(prevData.INIT_FORM).every(
                    (item) => item.approvedByLandlord === true
                );
                // console.log("isAllApproved", isAllApproved)
                return {
                    ...contractData,
                    status: isAllApproved ? ContractStatus.FINISHED : ContractStatus.ACTIVE,
                };
            },
        },
    ];

    const submitSequentially = async (steps, initialResults = {}) => {
        let results = { ...initialResults };
        for (const step of steps) {
            const data = step.prepare(results);
            // console.log(`prepared type: ${step.type}, data:`, data);
            try {
                if (step.isArray) {
                    results[step.type] = await Promise.all(
                        data.map((item) => submitSingle(step.submit, item))
                    );
                } else {
                    results[step.type] = await submitSingle(step.submit, data);
                }
                console.log(`Submitted ${step.type}:`, results[step.type]);
            } catch (error) {
                console.error(`Error submitting ${step.type}:`, error);
                throw error;
            }
        }
        return results;
    };

    const handleSubmitData = async (formData) => {
        // console.log('formData', formData);
        setShowModal(true);
        try {
            const results = await submitSequentially(submissionSteps, { INIT_FORM: formData });
            // Hello from this: src\components\ContractCreateForm\ContractCreateForm.jsx
            await new Promise((resolve) => setTimeout(resolve, 2000));
            console.log('All data submitted successfully:', results);
        } catch (error) {
            console.error('Error in submission process:', error);
        } finally {
            setShowModal(false);
        }
    };

    const handleModalExited = () => {
        // console.log('handleModalExited');
        navigate('/reports');
    };

    if (loadingAssetPairs || loadingContractData) {
        return (
            <Spinner
                animation="border"
                role="status"
                style={{ width: '10rem', height: '10rem', margin: 'auto auto' }}
            />
        );
    }

    if (
        reportStatus === REPORT_STATUS.EXISTS ||
        reportStatus === REPORT_STATUS.WAITING_FOR_ASSETS ||
        assetPairs.length === 0
    ) {
        return (
            <>
                <p className="p-3 text-center">
                    Preview unavailable. Report is processing or no asset pair data is available{' '}
                </p>
                <CustomButton className="mt-auto" onClick={() => navigate('/reports')}>
                    Back to Reports
                </CustomButton>
            </>
        );
    }

    return (
        <>
            <SubmitStatusModal show={showModal} onClose={handleModalExited} />

            <div className="flex-col my-4 font-poppins fw-400 fs-16 lh-26 text-color-dark">
                <span> Created at: {formatDateStringISO(reportCreatedAt)} </span>
                <span> Photo: {visibleOriginal ? 'Original' : 'New asset'} </span>
            </div>

            {/* stepper */}
            <CustomStepper
                steps={assetPairs.length}
                currentStep={step}
                onStepChange={setStep}
                isApprovedByLandlord={isStepApproved}
            />

            {/* carousel */}
            <ImageCarousel
                currentPair={assetPairs[step]}
                dataSize={assetPairs.length - 1}
                currentStep={step}
                onStepChange={setStep}
                annotations={assetAnnotations}
                onToggleOriginal={toggleOriginal}
            />

            <Formik
                initialValues={initialFormValues}
                onSubmit={(values) => handleSubmitData(values)}
                enableReinitialize={true}
            >
                {({ values, isSubmitting }) => {
                    // console.log('form values', values);
                    return (
                        <Form className="flex-col flex-grow-1 mt-3">
                            <FormikStateChanger onChange={handleFormChange} />
                            <div className="flex-row">
                                <ButtonCheckbox
                                    id={`${assetPairs[step]?.id}.approvedByLandlord`}
                                    name={`${assetPairs[step]?.id}.approvedByLandlord`}
                                    fullWidth={true}
                                    className="me-2"
                                />
                                <CommentModal name={`${assetPairs[step]?.id}.landlordComment`} />
                            </div>

                            <CustomButton
                                type="submit"
                                style={{ marginTop: 'auto' }}
                                disabled={isSubmitting}
                            >
                                Submit
                            </CustomButton>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default ReportSingleLandlord;
