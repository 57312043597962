import React from 'react'
import HeaderRenderer from '../components/Header/HeaderRenderer'
import { Outlet } from 'react-router-dom'

const MainLayout = () => {
    return (
        <>
            <HeaderRenderer/>
            <Outlet/>
        </>
    )
}

export default MainLayout
