import React from 'react';
import TextInput from '@/components/UI/inputs/TextInput';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const AddressGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.7rem;
    row-gap: 1rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const AddressCol = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const AddressInline = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
`;

const AddressField = ({ label, name }) => {
    return (
        <div className="flex-col">
            {label && <label className="fw-500 fs-16 lh-26 text-color-black"> {label} </label>}
            <AddressGrid>
                <AddressCol>
                    <TextInput
                        id={`${name}.street`}
                        name={`${name}.street`}
                        placeholder={'Street'}
                    />
                    <TextInput id={`${name}.city`} name={`${name}.city`} placeholder={'City'} />
                    <TextInput
                        id={`${name}.country`}
                        name={`${name}.country`}
                        placeholder={'Country'}
                    />
                </AddressCol>

                <AddressCol>
                    <AddressInline>
                        <TextInput
                            id={`${name}.streetNumber`}
                            name={`${name}.streetNumber`}
                            placeholder={'Street number'}
                        />
                        <TextInput
                            id={`${name}.flat`}
                            name={`${name}.flat`}
                            placeholder={'Flat number'}
                        />
                    </AddressInline>
                    <TextInput
                        id={`${name}.zipCode`}
                        name={`${name}.zipCode`}
                        placeholder={'Postal code'}
                    />
                    <TextInput id={`${name}.state`} name={`${name}.state`} placeholder={'State'} />
                </AddressCol>
            </AddressGrid>
        </div>
    );
};

export default AddressField;
