import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { uuidSchema } from "../router/validationSchemas"


export const useValidateParamsUUID = (uuid, fallbackRoute='/') => {
    const navigate = useNavigate()

    useEffect(() => {
        const {error} = uuidSchema.validate(uuid)
        if (error) {
            navigate(fallbackRoute)
        }
    }, [uuid, fallbackRoute, navigate])

}   