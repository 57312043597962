import {
    ACTION_NOTIFICATION_ADD,
    ACTION_NOTIFICATION_CLEAR_ALL,
    ACTION_NOTIFICATION_CLEAR_ROLE,
    ACTION_NOTIFICATION_REMOVE,
    ACTION_NOTIFICATION_SET_CONNECTION_STATUS,
    ROLE_LANDLORD,
    ROLE_TENANT,
    STATUS_SOCKET_DISCONNECTED,
} from '../constants';

const initialNotificationState = {
    items: {
        [ROLE_LANDLORD]: [],
        [ROLE_TENANT]: [],
    },
    connectionStatus: STATUS_SOCKET_DISCONNECTED,
};

const notificationReducer = (state = initialNotificationState, action) => {
    switch (action.type) {
        case ACTION_NOTIFICATION_ADD: {
            const { notification, role } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [role]: [...state.items[role], notification],
                },
            };
        }
        case ACTION_NOTIFICATION_REMOVE: {
            const { objectId, role } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [role]: state.items[role].filter((notif) => notif.reportId !== objectId),
                },
            };
        }
        case ACTION_NOTIFICATION_CLEAR_ROLE: {
            const role = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [role]: initialNotificationState.items[role],
                },
            };
        }
        case ACTION_NOTIFICATION_CLEAR_ALL: {
            return {
                ...state,
                items: initialNotificationState.items,
            };
        }
        case ACTION_NOTIFICATION_SET_CONNECTION_STATUS: {
            return {
                ...state,
                connectionStatus: action.payload,
            };
        }
        default:
            return state;
    }
};

export default notificationReducer;
