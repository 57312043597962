import React, { useEffect, useState } from 'react';
import { useCurrentRoute } from '../../hooks/useCurrentRoute';
import { useLocation } from 'react-router-dom';
import { HEADER_TYPES } from '../../constants';
import { EditHeader, NavigationHeader, ProfileHeader } from './Headers';

const HeaderRenderer = () => {
    const location = useLocation();
    const currentRoute = useCurrentRoute();
    const [headerType, setHeaderType] = useState(null);

    useEffect(() => {
        if (currentRoute.header && currentRoute.header.visible === true) {
            setHeaderType(currentRoute.header);
        } else {
            setHeaderType(null);
        }
        // console.log(currentRoute, location.pathname)
    }, [location.pathname, currentRoute, headerType]);

    const renderHeader = () => {
        // console.log(headerType)
        if (!headerType || headerType.visible === false) {
            return null;
        }
        switch (headerType.type) {
            case HEADER_TYPES.PROFILE:
                return <ProfileHeader />;
            case HEADER_TYPES.NAVIGATION:
                return <NavigationHeader fallbackRoute={headerType?.fallbackRoute || -1} />;
            case HEADER_TYPES.EDIT:
                return <EditHeader />;
            default:
                return null;
        }
    };

    return renderHeader();
};

export default HeaderRenderer;
