import styled from 'styled-components';

export const StatisticsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 1rem 2rem;
    width: 100%;
    max-width: 700px;
    border: 1px solid #141723;
    border-radius: 48px;
    background: linear-gradient(to right, #413f3f 1%, #0f0c29, #24243e, #302b63);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.93), 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
`;

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (props) => !['side'].includes(props),
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        top: -1px;
        ${({ side }) => side}:0;
        width: 50%;
        height: calc(100% + 2px);
        transition: background 0.2s ease-in-out;
    }

    &:hover::after {
        background: rgba(255, 255, 255, 0.2);
    }
`;

export const StatsValue = styled.div`
    font-family: 'Onest', 'sans-serif';
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--text-color-green);
`;

export const StatsName = styled.div`
    font-family: 'Poppins', 'sans-serif';
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: var(--text-color-light);
`;
