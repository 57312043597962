import {
    ACTION_NOTIFICATION_ADD,
    ACTION_NOTIFICATION_CLEAR_ALL,
    ACTION_NOTIFICATION_CONNECT_WEBSOCKET,
    ACTION_NOTIFICATION_DISCONNECT_WEBSOCKET,
    ACTION_NOTIFICATION_SET_CONNECTION_STATUS,
    ACTION_NOTIFICATION_REMOVE,
    ACTION_NOTIFICATION_CLEAR_ROLE,
} from '../constants';

export const addNotification = (notification, role) => ({
    type: ACTION_NOTIFICATION_ADD,
    payload: {
        notification: notification,
        role: role,
    },
});

export const removeNotification = (object_id, role) => ({
    type: ACTION_NOTIFICATION_REMOVE,
    payload: {
        objectId: object_id,
        role: role,
    },
});

export const clearAllNotificationsRole = (role) => ({
    type: ACTION_NOTIFICATION_CLEAR_ROLE,
    payload: role,
});

export const clearAllNotifications = () => ({
    type: ACTION_NOTIFICATION_CLEAR_ALL,
});

export const connectWebSocket = () => ({
    type: ACTION_NOTIFICATION_CONNECT_WEBSOCKET,
});

export const disconnectWebSocket = () => ({
    type: ACTION_NOTIFICATION_DISCONNECT_WEBSOCKET,
});

export const setConnectionStatus = (status) => ({
    type: ACTION_NOTIFICATION_SET_CONNECTION_STATUS,
    payload: status,
});
