import React, { useContext, useMemo } from 'react';
import { RoomListContainer, PlusButton, PreviewImageContainer } from './PropertyForms.styles';
import defaultSmallRoom from '../../assets/images/default-room-vertical.png';
import { BackArrowIcon, CrossIcon, NotificationsProfileIcon, PlusIconBig } from '../UI/icons';
import { FormContext } from '../../context/PropertyFormContext';
import * as Yup from 'yup';
import { FieldArray, Form, Formik, ErrorMessage } from 'formik';
import RoundButton from '../UI/buttons/RoundButton';
import Wrapper from 'components/UI/wrappers/Wrapper/Wrapper';
import { capitalizeString } from 'utils/utils';
import CustomButton from '@/components/UI/buttons/CustomButton';

const validationSchema = Yup.object({
    rooms: Yup.array().min(1, 'Minimum of 1 rooms is required').required('Required'),
});

const RoomListForm = ({ onNextStep, onPrevStep, startSubStep }) => {
    const { formData, updateBasicInfo, removeRoom, removeRoomPhotos } = useContext(FormContext);

    const onRemoveRoom = (room_id, remove, index) => {
        // console.log('REMOVE', index, typeof index);
        removeRoom(room_id);
        removeRoomPhotos(room_id);
        remove(index);
    };

    const onSubmit = (values) => {
        console.log('ListRoomForm', values);
        console.log('FORM DATA', formData);
        if (!formData.basicInfo.previewImage) {
            const firstRoom = formData.rooms[0];
            const firstRoomPhotos = formData.photos.filter(
                (photo) => photo.internalRoomID === firstRoom.internalID
            );
            updateBasicInfo({ previewImage: firstRoomPhotos[0] });
        }

        // if (formData.rooms.length !== values.rooms.length) {
        //     updateBasicInfo({ roomAmount: values.room.length });
        // }
        updateBasicInfo({ roomAmount: values.rooms.length });
        onNextStep();
    };

    const roomFirstPhotoMap = useMemo(() => {
        const roomPhotoMap = new Map();
        formData.rooms.forEach((room) => {
            const roomPhotos = formData.photos.filter(
                (photo) => photo.internalRoomID === room.internalID
            );
            if (roomPhotos.length > 0) {
                roomPhotoMap.set(room.internalID, roomPhotos);
            }
        });
        return roomPhotoMap;
    }, [formData.rooms, formData.photos]);

    const roomNumberedMap = useMemo(() => {
        const roomNumberMap = new Map();
        const typeCounts = {};
        const typeOccurrences = {};

        formData.rooms.forEach((room) => {
            typeCounts[room.type] = (typeCounts[room.type] || 0) + 1;
        });

        formData.rooms.forEach((room) => {
            const { type, internalID } = room;
            typeOccurrences[type] = (typeOccurrences[type] || 0) + 1;

            const displayName =
                typeCounts[type] > 1
                    ? `${capitalizeString(type)} Nr.${typeOccurrences[type]}`
                    : capitalizeString(type);

            roomNumberMap.set(internalID, displayName);
        });
        return roomNumberMap;
    }, [formData.rooms]);

    // console.log('ROOM PHOTO MAP', roomFirstPhotoMap);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <RoundButton onClick={onPrevStep}>
                    <BackArrowIcon />
                </RoundButton>
                <RoundButton>
                    <NotificationsProfileIcon />
                </RoundButton>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '3vh 0 3vh 0',
                    gap: '0.5rem',
                }}
            >
                <h1 className="font-onest fs-24 fw-700 lh-28 text-color-dark">
                    Rooms ({formData.rooms.length})
                </h1>
            </div>
            <Formik
                initialValues={{ rooms: formData.rooms }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ values }) => (
                    <Form className="flex-col justify-content-between flex-grow-1">
                        <RoomListContainer>
                            <FieldArray name="rooms">
                                {({ remove }) => (
                                    <>
                                        {values.rooms.map((room, index) => {
                                            const roomPhotos = roomFirstPhotoMap.get(
                                                room.internalID
                                            );
                                            const roomDisplayName = roomNumberedMap.get(
                                                room.internalID
                                            );
                                            // console.log('Room photos for room', roomPhotos);
                                            return (
                                                <Wrapper
                                                    key={index}
                                                    className="flex-row justify-content-start"
                                                >
                                                    <div className="flex-col">
                                                        <PreviewImageContainer>
                                                            {roomPhotos?.length > 0 ? (
                                                                <img
                                                                    src={roomPhotos[0].url}
                                                                    alt="Room preview"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'cover',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={defaultSmallRoom}
                                                                    alt="Room preview"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'cover',
                                                                    }}
                                                                />
                                                            )}
                                                        </PreviewImageContainer>
                                                    </div>
                                                    <div className="flex-col ms-4">
                                                        <div className="font-onest fw-500 fs-16 lh-26">
                                                            {roomDisplayName}
                                                        </div>
                                                        <div className="font-onest fw-500 fs-16 lh-26">
                                                            Photos: {roomPhotos?.length || 0}
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                        }}
                                                    >
                                                        <RoundButton
                                                            type="button"
                                                            size={3}
                                                            onClick={() =>
                                                                onRemoveRoom(
                                                                    room.internalID,
                                                                    remove,
                                                                    index
                                                                )
                                                            }
                                                            style={{
                                                                overflow: 'hidden',
                                                                padding: '20%',
                                                                backgroundColor:
                                                                    'rgba(255, 254, 254, 0.9)',
                                                            }}
                                                        >
                                                            <CrossIcon />
                                                        </RoundButton>
                                                    </div>
                                                </Wrapper>
                                            );
                                        })}
                                    </>
                                )}
                            </FieldArray>
                            <PlusButton type="button" onClick={startSubStep}>
                                <PlusIconBig />
                            </PlusButton>
                        </RoomListContainer>
                        <div className="flex-col">
                            <ErrorMessage
                                name="rooms"
                                component="span"
                                className="error text-center"
                            />
                            <CustomButton type="submit">Continue</CustomButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default RoomListForm;
