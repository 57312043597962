import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ProfileStatistics from '../../components/ProfileStatistics';
import { ProfileEmailDataIcon, ProfilePhoneDataIcon } from '../../components/UI/icons';
import { useAuthAction } from 'hooks/useAuthAction';
import { ROLE_TENANT } from 'constants';
import { useFetching } from 'hooks/useFetching';
import { setUser } from 'actions/userActions';
import EasyRentorService from 'service/EasyRentorService';
import { ROLE_LANDLORD } from 'constants';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RoleSwitcher from 'components/RoleSwitcher/RoleSwitcher';
import { DEFAULT_PROFILE_URL_IMG } from 'constants';
import CustomButton from '@/components/UI/buttons/CustomButton';

const ProfileInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);

    span {
        font-family: 'Onest';
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #000000;
    }
`;

const ProfileView = () => {
    const navigate = useNavigate();
    const { currentRole, userData } = useSelector((state) => state.users);
    const { logout } = useAuthAction();
    const dispatch = useDispatch();
    const [fetchUser, loadingUser, errorUser] = useFetching(async (id, fetchCallback) => {
        const response = await fetchCallback(id);
        const data = response.data;
        dispatch(setUser(data));
    });

    const handleLogout = async () => {
        await logout();
    };

    const getFetchCallback = useMemo(() => {
        switch (currentRole) {
            case ROLE_TENANT: {
                return EasyRentorService.getTenantByID;
            }
            case ROLE_LANDLORD: {
                return EasyRentorService.getLandlordByID;
            }
            default: {
                return EasyRentorService.getTenantByID;
            }
        }
    }, [currentRole]);

    useEffect(() => {
        fetchUser(userData.id, getFetchCallback);
    }, [userData.id]);

    if (loadingUser) {
        return (
            <Spinner
                animation="border"
                role="status"
                style={{ width: '10rem', height: '10rem', margin: 'auto auto' }}
            />
        );
    }

    if (errorUser) {
        return (
            <>
                <h1 className="font-onest fw-700 fs-24 lh-28 text-color-dark mb-0">
                    Error loading user info
                </h1>
                <p className="p-3 text-center">
                    {errorUser?.message || 'An unknown error occured!'}
                </p>
                <CustomButton className="mt-5" onClick={() => navigate('/login')}>
                    Back to Login page
                </CustomButton>
            </>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6vh' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '4vh',
                }}
            >
                <div className="flex-col ">
                    <div
                        style={{
                            maxWidth: '200px',
                            maxHeight: '200px',
                            aspectRatio: 1,
                            borderRadius: '50%',
                        }}
                    >
                        <img
                            src={userData?.picUrl || DEFAULT_PROFILE_URL_IMG}
                            alt="Profile"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: 'inherit',
                            }}
                        />
                    </div>

                    <h4 className="fw-700 text-center mt-2">
                        {userData.firstName} {userData.lastName}
                    </h4>
                </div>
                <ProfileStatistics
                    key={`${userData.id}-${currentRole}`}
                    userId={userData.id}
                    userRole={currentRole}
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '2vh',
                        maxWidth: '700px',
                    }}
                >
                    <ProfileInfo>
                        <ProfileEmailDataIcon />
                        <span> {userData.email} </span>
                    </ProfileInfo>
                    <ProfileInfo>
                        <ProfilePhoneDataIcon />
                        <span> {userData?.phoneNumber || 'Not assigned'} </span>
                    </ProfileInfo>
                    {/* <ProfileInfo>
                        <span> Logged in as {currentRole} </span>
                    </ProfileInfo> */}
                    <RoleSwitcher
                        header={
                            <ProfileInfo>
                                <span> Logged in as {currentRole} </span>
                            </ProfileInfo>
                        }
                    />
                </div>
            </div>

            <CustomButton onClick={handleLogout}>Logout</CustomButton>
        </div>
    );
};

export default ProfileView;
