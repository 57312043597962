import styled, {css} from "styled-components";


export const FullscreenContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const CameraHeader = styled.div`
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Onest;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    color: white;
    z-index: 1;
    
` 

export const VideoPreview = styled.video.withConfig({
    shouldForwardProp: props => !['frontCamera'].includes(props)
})`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: ${({frontCamera}) => frontCamera ? 'scaleX(-1)' : 'none'};
`;

export const CaptureButton = styled.button.withConfig({
    shouldForwardProp: props => !['horizontal'].includes(props)
})`
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    ${({horizontal}) => horizontal && css`
        // Rotate 90 degrees around the center of the screen
        transform: 
            translateX(-50%)
            rotate(-90deg)
            translateY(calc(50vw - 50%))
            translateX(calc(50vh - 100%));
    `}

    background-color: white;
    border: 5px solid rgba(255, 255, 255, 0.5);
    transition: all 0.2 ease;

    

    &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        background-color: white;
}
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
`;


export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: white;
    font-size: 1.5rem;
    text-align: center;
    padding: 20px;
    backdrop-filter: blur(10px);
`