import React from 'react';
import { Spinner } from 'react-bootstrap';
import ReportCard from '../ReportCard/ReportCard';

const ReportList = ({ reports, loading }) => {
    return (
        <div>
            <h1 className="font-onest fs-24 fw-700 lh-28 text-color-dark mb-4"> Reports </h1>

            <div className="flex-col">
                {loading ? (
                    <Spinner
                        animation="border"
                        role="status"
                        style={{ width: '10rem', height: '10rem', margin: 'auto auto' }}
                    />
                ) : (
                    <>
                        {reports.length > 0 ? (
                            reports.map((report) => <ReportCard key={report.id} report={report} />)
                        ) : (
                            <p className="no-items-list-text">
                                Currently, there are no reports waiting for your check.
                            </p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ReportList;
