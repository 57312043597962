import { HeaderContainer, Header } from '../PropertyList/PropertyList.styles';
import RoundButton from '../UI/buttons/RoundButton';
import { PlusIcon } from '../UI/icons';
import { Spinner } from 'react-bootstrap';
import ContractCardExtended from '../ContractCard/ContractCardExtended';
import styled from 'styled-components';
import CustomDropdown from '@/components/UI/dropdowns/CustomDropdown';
import { ChevronDown } from 'react-bootstrap-icons';

const ListContainer = styled.div`
    display: grid;
    justify-items: stretch;
    gap: 24px;
    margin-top: 2vh;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

    grid-template-columns: 1fr;

    @media (min-width: 576px) {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }

    /* Desktop */
    @media (min-width: 992px) {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
    }

    /* Extra large screens */
    @media (min-width: 1400px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const ContractList = ({ contracts, loading, openContractCodeInvite }) => {
    return (
        <div style={{ height: '50vh', height: '50svh' }}>
            <HeaderContainer>
                <Header> Contracts </Header>
                <RoundButton size={1} onClick={openContractCodeInvite}>
                    <PlusIcon />
                </RoundButton>
            </HeaderContainer>
            <ListContainer>
                {loading ? (
                    <Spinner
                        animation="border"
                        role="status"
                        style={{
                            width: '10rem',
                            height: '10rem',
                            color: 'var(--primary-color)',
                        }}
                    />
                ) : (
                    <>
                        {contracts.length > 0 ? (
                            contracts.map((contract) => (
                                <ContractCardExtended key={contract.id} contractInfo={contract} />
                            ))
                        ) : (
                            <p className="no-items-list-text">
                                Currently, you have no active contracts. To add a new contract,
                                press the plus sign located next to the "Contracts" title.
                            </p>
                        )}
                    </>
                )}
            </ListContainer>
        </div>
    );
};

export const ContractListFinished = ({ contracts, loading }) => {
    return (
        <div style={{ marginBottom: '5rem' }}>
            <CustomDropdown
                header={
                    <div className="flex-row align-items-center justify-content-start">
                        <h1 className="font-onest fs-24 fw-700 lh-28 p-0 m-0 text-color-dark">
                            History
                        </h1>
                    </div>
                }
            >
                <ListContainer>
                    {loading ? (
                        <Spinner
                            animation="border"
                            role="status"
                            style={{
                                width: '10rem',
                                height: '10rem',
                                color: 'var(--primary-color)',
                            }}
                        />
                    ) : (
                        <>
                            {contracts.length > 0 ? (
                                contracts.map((contract) => (
                                    <ContractCardExtended
                                        key={contract.id}
                                        contractInfo={contract}
                                    />
                                ))
                            ) : (
                                <p className="no-items-list-text">
                                    No finished contracts to show here.
                                </p>
                            )}
                        </>
                    )}
                </ListContainer>
            </CustomDropdown>
        </div>
    );
};
