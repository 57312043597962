import React, { useRef, useState } from 'react';
import { ImageCanvasContainer } from './AnnotationCanvas.styles';
import { Layer, Rect, Stage } from 'react-konva';
import { useDisplayedImageSize } from 'hooks/useDisplayedImageSize';
import { getDamageColor } from 'utils/utils';
import { Spinner } from 'react-bootstrap';

const AnnotationCanvas = ({ originalAsset, newAsset, annotations, onToggleAsset }) => {
    const imageRef = useRef(null);
    const containerRef = useRef(null);
    const stageRef = useRef(null);
    const dims = useDisplayedImageSize(containerRef, imageRef, newAsset ? newAsset : null);

    // console.log('annotations', annotations);

    const [showOriginal, setShowOriginal] = useState();
    const handleToggleOriginal = () => {
        setShowOriginal(!showOriginal);
        onToggleAsset(!showOriginal);
    };

    return (
        <ImageCanvasContainer ref={containerRef} onClick={handleToggleOriginal}>
            {!showOriginal && annotations && (
                <Stage
                    ref={stageRef}
                    width={dims.width}
                    height={dims.height}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1,
                        pointerEvents: 'none',
                    }}
                >
                    <Layer>
                        {annotations.length > 0 &&
                            annotations.map((anno) => {
                                return (
                                    <Rect
                                        key={anno.id}
                                        x={parseFloat(anno.x) * dims.width}
                                        y={parseFloat(anno.y) * dims.width}
                                        width={parseFloat(anno.width) * dims.width}
                                        height={parseFloat(anno.height) * dims.width}
                                        stroke={getDamageColor(anno.damageClass)}
                                        fill="transparent"
                                        strokeWidth={2}
                                    />
                                );
                            })}
                    </Layer>
                </Stage>
            )}

            <img
                ref={imageRef}
                src={showOriginal ? originalAsset : newAsset}
                alt="Asset"
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                }}
            />
        </ImageCanvasContainer>
    );
};

export default React.memo(AnnotationCanvas);
