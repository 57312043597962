import React from 'react';
import { StepperButtonPill, StepperContainer } from './CustomStepper.styles';

const CustomStepper = ({ steps, currentStep, onStepChange, ...props }) => {
    const handleStep = (index) => {
        onStepChange(index);
    };
    return (
        <StepperContainer>
            {Array.from({ length: steps }, (_, index) => (
                <StepperButtonPill
                    key={`step_${index}`}
                    onClick={() => handleStep(index)}
                    className={`
                        ${index === currentStep ? 'active-stepper-button' : ''}
                        ${
                            props.isApprovedByLandlord && props.isApprovedByLandlord(index)
                                ? 'approved-stepper-button'
                                : ''
                        }
                    `}
                />
            ))}
        </StepperContainer>
    );
};

export default React.memo(CustomStepper);
