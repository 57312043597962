import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { ListContainer } from '../PropertyForms/PropertyForms.styles';
import { useNavigate } from 'react-router-dom';
import EasyRentorService from '../../service/EasyRentorService';
import CustomButton from '@/components/UI/buttons/CustomButton';
import TextInput from '@/components/UI/inputs/TextInput';

const initialValues = {
    code: '',
};

const ContractCodeModal = ({ show, onClose }) => {
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        code: Yup.string().uuid('Invite code not valid!').required('Invite code is required'),
    });

    const validateInviteCode = async (code) => {
        let contractData = null;

        // validate if contract for that code exists (EDIT 21.12.2024: currently code == contract.id)
        try {
            const response = await EasyRentorService.getContractByID(code);
            console.log('RESPONSE', response);
            contractData = response.data;
        } catch (error) {
            console.log('ERROR', error);
            let msg = '';
            switch (error.status) {
                case 404: {
                    msg = 'No contract for that code exist!';
                    break;
                }
                case 500: {
                    msg = 'Something broke on backend ¯_(ツ)_/¯';
                    break;
                }
                default: {
                    msg = 'Unknown error has occured while checking if contract exists!';
                }
            }
            return { success: false, errorMessage: msg };
        }

        // validate if contract for that code is still available
        if (contractData.tenantId !== null) {
            return { success: false, errorMessage: 'Contract for this code is not available!' };
        }

        return { success: true, data: contractData };
    };

    const onSubmit = async (values, { setSubmitting, setFieldError }) => {
        //checkFetchContract(values.code);
        setSubmitting(true);
        const validationResults = await validateInviteCode(values.code);
        switch (validationResults.success) {
            case true: {
                navigate('/contracts/sign', {
                    state: { invitationContract: validationResults.data },
                });
                break;
            }
            case false: {
                setFieldError('code', validationResults.errorMessage);
                break;
            }
            default: {
                setFieldError('code', 'Unknown error occured! Please try again...');
            }
        }
        setSubmitting(false);
    };

    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            onHide={onClose}
            animation={true}
            centered={true}
        >
            <Modal.Header closeButton>
                <h3>Enter invite code for Contract</h3>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting }) => {
                        return (
                            <Form className="flex-col justify-content-between flex-grow-1">
                                <ListContainer>
                                    <TextInput
                                        id="code"
                                        name="code"
                                        placeholder={'Enter invite code here'}
                                    />

                                    <CustomButton type="submit" disabled={isSubmitting}>
                                        Continue
                                    </CustomButton>
                                </ListContainer>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default ContractCodeModal;
