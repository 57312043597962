import { format, parseISO } from 'date-fns';

export const calcDiscountPrice = (discount_percentage, fullPrice) => {
    const discount = (fullPrice * (100 - discount_percentage)) / 100;
    return roundToTwoDecimals(discount);
};

export const formatDateString = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy');
};

export const formatDateStringISO = (dateString) => {
    const date = parseISO(dateString);
    return format(date, 'dd/MM/yyyy');
};

export const roundToTwoDecimals = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const capitalizeString = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const getDamageColor = (type) => {
    const DAMAGE_COLORMAP = {
        SCRATCH: 'rgb(255,0,0)',
        SPOT: 'rgb(0,0,255)',
        OTHER: 'rgb(0,255,0)',
        DEFAULT: 'rgb(0, 0, 0)',
    };

    try {
        return DAMAGE_COLORMAP[type];
    } catch (e) {
        console.log(`DAMAGE_COLOR ${type} is not in MAP`);
        return DAMAGE_COLORMAP.DEFAULT;
    }
};

export function blobToBase64(blob) {
    // console.log(blob);
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
    });
}

export function stripBase64Prefix(base64String) {
    const match = base64String.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
    if (match) {
        return match[2];
    }
    return base64String; // Return as is if no prefix found
}
