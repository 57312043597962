export enum PaymentMethods {
    EUR = 'EUR',
    US = 'US',
}

export enum ContractStatus {
    WAITING_FOR_SIGNATURES = 'WAITING_FOR_SIGNATURES',
    WAITING_FOR_START_DATE = 'WAITING_FOR_START_DATE',
    ACTIVE = 'ACTIVE',
    ANNOTATED = 'ANNOTATED',
    FINISHED = 'FINISHED',
}
