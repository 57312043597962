import React, { useState, useContext } from 'react';
import { FormContext } from '../../context/PropertyFormContext';
import RoomListForm from './RoomListForm';
import CreateRoomForm from './CreateRoomForm';

const RoomManager = ({ onNextStep, onPrevStep }) => {
    const [subStep, setSubStep] = useState(0);
    const { formData } = useContext(FormContext);

    const startSubStep = () => setSubStep(1);
    const endSubStep = () => {
        setSubStep(0);
    };

    console.log('ROOM MANAGER', formData);

    const formSteps = [
        <RoomListForm
            key="roomListForm"
            onNextStep={onNextStep}
            startSubStep={startSubStep}
            onPrevStep={onPrevStep}
        />,
        <CreateRoomForm key="createRoomForm" onNextStep={endSubStep} onPrevStep={endSubStep} />,
    ];

    return <>{formSteps[subStep]}</>;
};

export default RoomManager;
