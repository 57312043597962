import { useField, useFormikContext } from 'formik';
import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
// import './DatePicker.css';
import { CustomDatePickerProps } from '@/components/UI/inputs/DatePicker/types';
import styles from './DatePicker.module.css';
import clsx from 'clsx';

const CustomDatePicker: FC<CustomDatePickerProps> = ({
    label,
    fullWidth = false,
    disabled,
    className,
    ...props
}) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props.name);

    const handleChange = (
        date: Date[] | null,
        event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
    ) => {
        const selectedDate = date && date.length > 0 ? date[0] : null;
        setFieldValue(field.name, selectedDate);
    };

    // const selectedDate = field.value ? parseISO(field.value) : null;
    return (
        <div className={clsx(styles.container, fullWidth && styles.fullWidth)}>
            {label && (
                <label htmlFor={props.id || props.name} className={styles.label}>
                    {label}
                </label>
            )}
            <DatePicker
                selected={(field.value && new Date(field.value)) || null}
                onChange={(date: Date | null) => {
                    setFieldValue(field.name, date);
                }}
                disabled={disabled}
                popperPlacement="bottom-end"
                dateFormat={'d MMMM, yyyy'}
                className={clsx(styles.input, disabled && styles.disabled, className)}
                {...props}
            />
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
        </div>
    );
};

export default CustomDatePicker;
