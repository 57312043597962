import styled from 'styled-components';

export const NavContainer = styled.div`
    position: fixed;
    width: 60%;
    max-width: 450px;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, -1rem);
    background: rgba(255, 254, 254, 0.9);
    border: 1px solid rgba(247, 242, 242, 1);
    border-radius: 48px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
`;

export const NavWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2.5rem;
`;
