import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2vh;
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
`;

// export const ListContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     flex-wrap: wrap;
//     gap: 1vw;
//     margin-top: 2vh;
// `

export const ListContainer = styled.div`
    display: grid;
    justify-items: stretch;
    gap: 24px;
    margin-top: 2vh;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

    grid-template-columns: 1fr;

    @media (min-width: 576px) {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }

    /* Desktop */
    @media (min-width: 992px) {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
    }

    /* Extra large screens */
    @media (min-width: 1400px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const Header = styled.h1`
    font-family: 'Onest', 'sans-serif';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    padding: 0;
    margin: 0;
    color: var(--text-color-dark);
`;
