import { useState } from "react"


export const useCamera = () => {
    const [frontCamera, setFrontCamera] = useState(false)

    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    const getCamera = async () => {
        try {
            if (isMobileDevice()) {
                const backCamera = await navigator.mediaDevices.getUserMedia({ 
                    audio: false,
                    video: { facingMode: 'environment' } 
                });
                setFrontCamera(false)
                return backCamera
            } else {
                throw new Error('Not a mobile device')
            }
        } catch (error) {
            console.warn("back camera fails, try default camera (webcam on laptops)")
            // back camera fails, try default camera (webcam on laptops)
            try {
                const defaultCamera = await navigator.mediaDevices.getUserMedia({
                    video: true
                });
                setFrontCamera(true)
                return defaultCamera;
            } catch (error) {
                console.error("No camera available:", error);
                throw error;
            }
        }
    }
    return {getCamera, frontCamera}
}