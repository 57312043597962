import React, { useEffect, useState } from 'react';
import defaultRoomImageSrc from '../../assets/images/default-room.png';
import { CardInfoContainer, CardInfoRow, CardWrapper, ImageWrapper } from './PropertyCard.styles';
import { LocationIcon, RoomsBedIcon } from '../UI/icons/index.js';
import { Link, useNavigate } from 'react-router-dom';
import { roundToTwoDecimals } from '../../utils/utils.js';

const PropertyCard = ({ propertyInfo }) => {
    const navigate = useNavigate();

    const handleSingleView = (e) => {
        // e.stopPropagation();
        // console.log("propertyInfo.id", propertyInfo.id)
        navigate(`/properties/${propertyInfo.id}`);
    };

    return (
        <div onClick={handleSingleView} style={{ cursor: 'pointer' }}>
            <CardWrapper>
                <ImageWrapper>
                    <img
                        src={propertyInfo?.previewPhotoUrl || defaultRoomImageSrc}
                        alt="Property preview"
                    />
                </ImageWrapper>

                <CardInfoContainer>
                    <div className="font-onest fw-700 fs-16 lh-24 text-color-dark">
                        {propertyInfo.name}
                    </div>
                    <CardInfoRow>
                        <LocationIcon />
                        <span className="font-poppins fw-400 fs-14 lh-24 text-color-grey">
                            {propertyInfo.address.city}, {propertyInfo.address.street}{' '}
                            {propertyInfo.address.streetNumber}
                        </span>
                    </CardInfoRow>
                    <div className="flex-row justify-content-between">
                        <CardInfoRow>
                            <RoomsBedIcon />
                            <span className="font-poppins fw-400 fs-14 lh-24 text-color-grey">
                                {propertyInfo.roomAmount}
                            </span>
                        </CardInfoRow>
                        <div className="font-poppins fw-400 fs-14 lh-24 text-color-grey">
                            <span className="font-poppins fw-600 fs-20 lh-28 text-color-blue">
                                {roundToTwoDecimals(propertyInfo.rentCostDaily)}&#8364;
                            </span>
                            <span>/per day</span>
                        </div>
                    </div>
                </CardInfoContainer>
            </CardWrapper>
        </div>
    );
};

export default PropertyCard;
