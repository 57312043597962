import CustomButton from '@/components/UI/buttons/CustomButton';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <>
            <div
                style={{
                    marginTop: 'auto',
                }}
            >
                <h1 className="font-onest fs-32 fw-700 lh-36 p-0 m-0 text-color-dark">
                    Page not found
                </h1>
                <p className="text-color-dark py-3 px-1">
                    Uh well, we can't seem to find the page you're looking for. Try going back to
                    the previous page.
                </p>
            </div>
            <CustomButton
                type="button"
                className="mt-auto"
                onClick={(e) => {
                    navigate('/profile');
                }}
            >
                Go to Profile
            </CustomButton>
        </>
    );
};

export default NotFound;
