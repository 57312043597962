import styled from 'styled-components';

export const StepperContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
`;

export const StepperButtonPill = styled.div`
    position: relative;
    width: 3rem;
    height: 5px;
    border-radius: 5vh;
    background-color: rgba(195, 195, 195, 1);
    border: none;
`;
