import React from 'react';
import { useSelector } from 'react-redux';
import { ROLE_LANDLORD } from 'constants';
import { ROLE_TENANT } from 'constants';
import LandlordPropertyList from './LandlordPropertyList';
import TenantPropertyList from './TenantPropertyList';

const PropertyListView = () => {
    const currentRole = useSelector((state) => state.users.currentRole);

    switch (currentRole) {
        case ROLE_LANDLORD:
            return <LandlordPropertyList />;
        case ROLE_TENANT:
            return <TenantPropertyList />;
        default:
            return null;
    }
};

export default PropertyListView;
