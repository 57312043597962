import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 152, 152, 0.7);
  }
  
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(255, 152, 152, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 152, 152, 0);
  }
`;

export const NotificationsContainer = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 80vw;
    width: 80svw;
    height: 80vh;
    height: 80svh;
    // padding: 2rem;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 1);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
`;

export const NotificationHeader = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin: 0;
`;

export const NotificationsContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    padding: 0 2rem;
    overflow-y: auto;
    padding-bottom: 4px;
`;

export const NotificationCardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`;

export const NotificationStatusIcon = styled.div.withConfig({
    shouldForwardProp: (props) => !['bgColor'].includes(props),
})`
    position: relative;
    min-width: 8px;
    min-height: 8px;
    border-radius: 50%;
    background-color: ${({ bgColor }) => bgColor};
`;

export const NotificationsLisIconWrapper = styled.div`
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% + 16px);
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
`;

export const NotificationsListIcon = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(255, 152, 152, 1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: white;
    animation: ${pulse} 2s infinite;
`;
