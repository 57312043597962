import React, { useCallback, useState } from 'react';

export const useFetching = (callback) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetch = useCallback(
        async (...args) => {
            setLoading(true);
            setError(null);
            try {
                await callback(...args);
            } catch (e) {
                // console.log("useFetching", e)
                setError(e?.response?.data || e);
            } finally {
                setLoading(false);
            }
        },
        [callback]
    );

    return [fetch, loading, error];
};
