import React, { useContext, useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { FormContext } from '../../context/PropertyFormContext';
import { FormDataField, Header } from './PropertyForms.styles';
import {
    BackArrowIcon,
    BadgeIcon,
    EditProfileIcon,
    HouseIcon,
    LocationIcon,
    NotificationsProfileIcon,
    RoomsBedIcon,
} from '../UI/icons';
import defaultPropertyPreview from '../../assets/images/default-room.png';
import { usePostSingle } from '../../hooks/usePostSingle';
import EasyRentorService from '../../service/EasyRentorService';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SubmitStatusModal from '../SubmitStatusModal';
import { uploadToS3 } from '../../service/S3UploadService';
import { v4 as uuidv4 } from 'uuid';
import RoundButton from '../UI/buttons/RoundButton';
import { Formik } from 'formik';
import CustomButton from '@/components/UI/buttons/CustomButton';

const Overview = ({ onPrevStep }) => {
    const fileInputRef = useRef(null);
    const { formData, updateBasicInfo } = useContext(FormContext);
    const userData = useSelector((state) => state.users.userData);
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [submitSingle, loading, error, response, submitStatus] = usePostSingle();

    const submissionSteps = [
        {
            type: 'CONTRACT_TEMPLATE',
            submit: EasyRentorService.submitContractTemplate,
            prepare: () => {
                const {
                    selectedContractTemplateID,
                    contractTemplateId,
                    creating,
                    ...restContractTemplate
                } = formData.contractTemplate;
                if (!formData.contractTemplate.creating) {
                    return {
                        ...restContractTemplate,
                        id: selectedContractTemplateID,
                    };
                }
                return {
                    ...restContractTemplate,
                    landlordId: userData.id,
                    securityDepositAmount: parseFloat(restContractTemplate.securityDepositAmount),
                    cleaningFee: parseFloat(restContractTemplate.cleaningFee),
                    paymentMethods: ['Bank card', 'Bank transfer'],
                    minRentalDays: parseInt(restContractTemplate.minRentalDays),
                    minMonthlyRentalNoticePeriodDays: parseInt(
                        restContractTemplate.minMonthlyRentalNoticePeriodDays
                    ),
                    templateStatus: 'ACTIVE',
                };
            },
            shouldSubmit: () => formData.contractTemplate?.creating,
        },
        {
            type: 'PROPERTY',
            submit: async (property) => {
                const { previewImage, ...restProperty } = property;
                const S3Key = `media/property_preview/property_preview_${uuidv4()}_${
                    previewImage.name
                }`;
                const url = await uploadToS3(previewImage.file, S3Key);
                const propertyDB = {
                    ...restProperty,
                    previewPhotoUrl: url,
                };

                return EasyRentorService.submitProperty(propertyDB);
            },
            prepare: (prevResults) => ({
                ...formData.basicInfo,
                area: parseInt(formData.basicInfo.area),
                maxGuestAmount: parseInt(formData.basicInfo.maxGuestAmount),
                roomAmount: parseInt(formData.basicInfo.roomAmount),
                landlordId: userData.id,
                contractTemplateId: prevResults.CONTRACT_TEMPLATE?.id,
                rentCostDaily: parseFloat(formData.basicInfo.rentCostDaily),
                rentCostMonthly: parseFloat(formData.basicInfo.rentCostMonthly),
                currentlyTaken: false,
                verified: false,
                notes: 'post v1',
                previewImage: formData.basicInfo.previewImage,
            }),
        },
        {
            type: 'ADDRESS',
            submit: EasyRentorService.submitAddress,
            prepare: (prevResults) => ({
                ...formData.basicInfo.address,
                id: prevResults.PROPERTY.id,
            }),
        },
        {
            type: 'ROOM',
            submit: (room) => {
                const roomForDB = {
                    name: room.name,
                    propertyId: room.propertyId,
                };

                return EasyRentorService.submitRoom(roomForDB);
            },
            prepare: (prevResults) =>
                formData.rooms.map((room) => ({
                    name: room.type,
                    internalID: room.internalID,
                    propertyId: prevResults.PROPERTY.id,
                })),
            isArray: true,
        },
        // Add more steps as needed
        {
            type: 'PHOTO',
            submit: async (photoData) => {
                const S3Key = `properties/landlords/landlord_upload_${uuidv4()}_${photoData.name}`;
                const url = await uploadToS3(photoData.file, S3Key);

                const photoForDB = {
                    propertyId: photoData.propertyId,
                    roomId: photoData.roomId,
                    stage: 'INITIAL',
                    url: url, // S3 url for uploaded file
                    original: true,
                    comment: 'saved from front',
                    annotated: false,
                    takenBy: userData.id,
                    takenAt: new Date().toISOString(),
                    qualityEval: {
                        kek: 'pek',
                        kekas: 'pekas',
                    },
                    mlPipelineConfig: 'ML PIPELINE CONFIG',
                    mlStatus: 'ML STATUS',
                };
                return EasyRentorService.submitPhoto(photoForDB);
            },
            prepare: (prevResults) => {
                const roomIDMap = new Map(
                    prevResults.ROOM.map((room) => [room.internalID, room.roomID])
                );

                const photos = formData.photos
                    .map((photo) => {
                        const roomID = roomIDMap.get(photo.internalRoomID);
                        if (roomID) {
                            return {
                                propertyId: prevResults.PROPERTY.id,
                                roomId: roomID,
                                file: photo.file,
                                name: photo.name,
                            };
                        }
                        console.log('NO ROOMID for photo', photo);
                        return null;
                    })
                    .filter((photo) => photo !== null);

                return photos;
            },
            isArray: true,
        },
    ];

    const submitSequentially = async (steps, initialResults = {}) => {
        let results = { ...initialResults };
        for (const step of steps) {
            const data = step.prepare(results);
            console.log(`prepared type: ${step.type}, data:`, data);
            if (step.shouldSubmit && !step.shouldSubmit()) {
                // So we still have access to field values of this object for next steps submissions.
                // See: contractTemplate
                results[step.type] = data;
                continue;
            }
            // console.log("SHOULD SUBMIT:", step.type, data)
            try {
                if (step.isArray) {
                    results[step.type] = await Promise.all(
                        data.map(async (item) => {
                            const results = await submitSingle(step.submit, item);
                            if (step.type === 'ROOM') {
                                return {
                                    roomID: results.id,
                                    internalID: item.internalID,
                                };
                            }
                            return results;
                        })
                    );
                } else {
                    results[step.type] = await submitSingle(step.submit, data);
                }
                console.log(`Submitted ${step.type}:`, results[step.type]);
            } catch (error) {
                console.error(`Error submitting ${step.type}:`, error);
                throw error;
            }
        }
        return results;
    };

    const handleSubmitData = useCallback(async () => {
        setShowModal(true);
        try {
            const results = await submitSequentially(submissionSteps);
            console.log('All data submitted successfully:', results);
        } catch (error) {
            console.error('Error in submission process:', error);
        } finally {
            setShowModal(false);
        }
    }, [formData, userData, submitSingle]);

    const handleModalExited = useCallback(() => {
        console.log('Modal exited, would navigate to /properties here');
        // Uncomment the next line to actually navigate
        navigate('/properties');
    }, [navigate]);

    const handleTestShowForm = () => {
        console.log('SUBMIT FORM DATA', formData);
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handlePropertyPreviewUpload = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const photoObject = {
                    url: URL.createObjectURL(file),
                    file: file,
                    name: file.name,
                    type: file.type,
                    fileSize: file.size,
                };
                updateBasicInfo({ previewImage: photoObject });
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <RoundButton onClick={onPrevStep}>
                    {/* Button to go back */}
                    <BackArrowIcon />
                </RoundButton>
                <RoundButton>
                    <NotificationsProfileIcon />
                </RoundButton>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '3vh 0 3vh 0',
                }}
            >
                <Header> {formData.basicInfo.name} </Header>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: '1.5rem',
                }}
            >
                <div
                    className="position-relative flex-col justify-content-center align-items-center mx-auto"
                    style={{ maxWidth: '500px', width: '100%' }}
                >
                    <img
                        src={formData.basicInfo?.previewImage.url || defaultPropertyPreview}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        alt="Preview of property"
                    />
                    <div
                        style={{
                            position: 'absolute',
                            top: '0.5rem',
                            right: '0.5rem',
                        }}
                    >
                        <RoundButton
                            type="button"
                            size={3}
                            style={{ opacity: 0.8 }}
                            onClick={triggerFileInput}
                        >
                            <EditProfileIcon />
                        </RoundButton>

                        <input
                            ref={fileInputRef}
                            id="propertyPreviewUpload"
                            name="propertyPreviewUpload"
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handlePropertyPreviewUpload}
                        />
                    </div>
                </div>
                <FormDataField>
                    <HouseIcon />
                    <span> {formData.basicInfo.name} </span>
                </FormDataField>
                <FormDataField>
                    <LocationIcon />
                    <span>
                        {' '}
                        {formData.basicInfo.address.city}, {formData.basicInfo.address.street}{' '}
                        {formData.basicInfo.address.streetNumber}{' '}
                    </span>
                </FormDataField>
                <FormDataField>
                    <RoomsBedIcon />
                    <span> {formData.rooms.length} room(s) </span>
                </FormDataField>
                <FormDataField>
                    <BadgeIcon />
                    <span> {formData.basicInfo.rentCostDaily}&#8364; </span>
                </FormDataField>
                <FormDataField>
                    <BadgeIcon />
                    <span> {formData.contractTemplate.name} </span>
                </FormDataField>
            </div>

            <CustomButton
                type="button"
                style={{ marginTop: 'auto' }}
                onClick={handleSubmitData}
                disabled={loading}
            >
                Submit
            </CustomButton>

            {/* <CustomButton type="button" style={{ marginTop: '2rem' }} onClick={handleTestShowForm}>
                TEST
            </CustomButton> */}

            <SubmitStatusModal show={showModal} onClose={handleModalExited} />
        </>
    );
};

export default Overview;
