import React, { useContext, useEffect, useState } from 'react';
import TenantRoomList from './TenantRoomList';
import TenantRoomPhotos from './TenantRoomPhotos';
import { TenantPhotosContext } from '../../context/TenantPhotosContext';
import TenantPhotosOverview from './TenantPhotosOverview';
import { useParams } from 'react-router-dom';

const TenantPhotosManager = ({ rooms, loadingRooms, propertyID }) => {
    const { contract_uuid } = useParams();
    const { data, updateData } = useContext(TenantPhotosContext);

    useEffect(() => {
        if (!loadingRooms) {
            // console.log("setting rooms")
            const roomStatus = rooms.map((room) => ({ ...room, completed: false }));
            updateData('roomStatus', roomStatus, 'set');
        }
    }, [loadingRooms]);

    const [activeRoomInfo, setActiveRoomInfo] = useState(null);
    const handleRoomSelect = (roomInfo) => {
        setActiveRoomInfo(roomInfo);
        handleShowCamera();
    };

    const [step, setStep] = useState(0);
    const handleShowCamera = () => {
        setStep(1);
    };
    const handleShowList = () => {
        // console.log("FINAL PHOTO FOR ROOM", activeRoomInfo)
        setStep(0);
    };
    const handleShowOverview = () => {
        setStep(2);
    };

    const steps = [
        <TenantRoomList onRoomSelect={handleRoomSelect} onContinue={handleShowOverview} />,
        <TenantRoomPhotos roomInfo={activeRoomInfo} onFinal={handleShowList} />,
        <TenantPhotosOverview photoData={{ contractID: contract_uuid, propertyID: propertyID }} />,
    ];

    // useEffect(() => {
    //     console.log("CURRENT DATA", data)
    // }, [data])

    return <>{steps[step]}</>;
};

export default TenantPhotosManager;
