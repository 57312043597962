import React, { useMemo } from 'react'
import { ReportContainer } from './ReportCard.styles'
import RoundWrapper from '../UI/wrappers/RoundWrapper/RoundWrapper'
import { Check2, Hourglass, Exclamation, ArrowClockwise } from 'react-bootstrap-icons'
import { formatDateString } from '../../utils/utils'
import { REPORT_STATUS } from '../../constants'
import { useNavigate } from 'react-router-dom'

const ReportCard = ({ report }) => {

    const navigate = useNavigate()

    const StatusIcon = useMemo(() => {
        switch (report.status) {
            case REPORT_STATUS.WAITING_FOR_ANNOTATING: {
                return <Hourglass size={26} color='grey' opacity={1} strokeWidth={1}/>
            }
            case REPORT_STATUS.WAITING_FOR_LANDLORD_CHECK: {
                return <Exclamation size={36} color='red' opacity={0.7} strokeWidth={1}/>
            }
            case REPORT_STATUS.WAITING_FOR_AGREEMENT: {
                return <ArrowClockwise size={32} color='grey' opacity={0.7} strokeWidth={1}/>
            }
            case REPORT_STATUS.FINISHED: {
                return <Check2 size={32} color='green' opacity={0.5} strokeWidth={1}/>
            }
            default:
                return null;
        }
    }, [report.status])

    const navigateReportSingle = () => {
        navigate(`/reports/${report.id}`)
    }

    return (
        <div onClick={navigateReportSingle} style={{cursor: 'pointer'}}>
            <ReportContainer>
                <div className='flex-col'>
                    <span className='font-onest fw-700'> {report.propertyName} </span>
                    <span className='font-onest'> {formatDateString(report.contractEndDate)} </span>
                </div>
                <RoundWrapper>
                    {StatusIcon}
                </RoundWrapper>
            </ReportContainer>
        </div>
        
    )
}

export default ReportCard
