import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
    DataRangeContainer,
    DataRangeRow,
    DisplayFormField,
    ListContainer,
} from './ContractCreateForm.styles';
import { Formik, Form } from 'formik';
import { useFetching } from '../../hooks/useFetching';
import EasyRentorService from '../../service/EasyRentorService';
import { usePostSingle } from '../../hooks/usePostSingle';
import { useSelector } from 'react-redux';
import { calcDiscountPrice, roundToTwoDecimals } from '../../utils/utils';
import SubmitStatusModal from '../SubmitStatusModal';
import CustomCheckbox from '@/components/UI/inputs/CustomCheckbox';
import CustomButton from '@/components/UI/buttons/CustomButton';
import CustomDatePicker from '@/components/UI/inputs/DatePicker';
import CustomRangeField from '@/components/UI/inputs/RangeField';
import TextInput from '@/components/UI/inputs/TextInput';

const initialValues = {
    date: {
        startDate: null,
        endDate: null,
    },
    termlessContract: false,
    guestAmount: 1,
    discountPercent: '',
    finalCostDaily: '',
    finalCostMonthly: '',
};

const ContractCreateForm = () => {
    const userData = useSelector((state) => state.users.userData);
    const params = useParams();

    const [property, setProperty] = useState();
    const [fetchProperty, loadingProperty, errorProperty] = useFetching(async (id) => {
        const response = await EasyRentorService.getPropertyByID(id);
        const data = response.data;
        setProperty(data);
    });

    useEffect(() => {
        fetchProperty(params.property_uuid);
    }, [params.property_uuid]);

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            date: Yup.object().shape({
                startDate: Yup.date()
                    .required('Start date is required')
                    .min(new Date(), 'Start datecannot be in the past'),
                endDate: Yup.date()
                    .required('End date is required')
                    .min(Yup.ref('startDate'), 'End date must be after start date'),
            }),
            termlessContract: Yup.boolean(),
            guestAmount: Yup.number()
                .required('guestAmount is required!')
                .min(1, 'Must be at least 1')
                .max(
                    property?.maxGuestAmount || 5,
                    `Cannot exceed ${property?.maxGuestAmount || 'loading...'}`
                )
                .integer('Must be a whole number!'),
            discountPercent: Yup.number()
                .typeError('Must be a number!')
                .required('discountPercent is required!')
                .max(99, 'You cannot be so generous!')
                .integer('Must be a whole number!'),
            finalCostDaily: Yup.number()
                .typeError('Must be a number!')
                .required('finalCostDaily is required!'),
            finalCostMonthly: Yup.number()
                .typeError('Must be a number!')
                .required('finalCostMonthly is required!'),
        });
    }, [property]);

    const handleTermlessChange = (e, setFieldValue) => {
        const isChecked = e.target.checked;
        setFieldValue('termlessContract', isChecked);
        if (isChecked) {
            const startDate = new Date();
            const endDate = new Date(startDate);
            endDate.setFullYear(endDate.getFullYear() + 50);
            setFieldValue('date.startDate', startDate);
            setFieldValue('date.endDate', endDate);
        } else {
            setFieldValue('date.startDate', null);
            setFieldValue('date.endDate', null);
        }
    };

    const handleCalcFinalPrice = (e, setFieldValue) => {
        const discount = e.target.value;
        const clampDiscount = Math.max(0, Math.min(discount, 99));
        setFieldValue('discountPercent', clampDiscount);
        if (discount) {
            const finalCostDaily = calcDiscountPrice(clampDiscount, property?.rentCostDaily || 0);
            setFieldValue('finalCostDaily', finalCostDaily);
            const finalCostMonthly = calcDiscountPrice(
                clampDiscount,
                property?.rentCostMonthly || 0
            );
            setFieldValue('finalCostMonthly', finalCostMonthly);
        } else {
            setFieldValue('finalCostDaily', null);
            setFieldValue('finalCostMonthly', null);
        }
    };

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [submissionResult, setSubmissionResult] = useState(null);
    const [submitSingle, loading, error, response, submitStatus] = usePostSingle();

    const submissionSteps = [
        {
            type: 'CONTRACT',
            submit: EasyRentorService.submitContract,
            prepare: (formData) => {
                return {
                    landlordId: userData.id,
                    tenantId: null,
                    propertyId: params.property_uuid,
                    contractTemplateId: property.contractTemplateId,
                    signedAt: null,
                    startDate: formData.date.startDate,
                    endDate: formData.date.endDate,
                    guestAmount: formData.guestAmount,
                    discountPercent: formData.discountPercent,
                    finalCostDaily: formData.finalCostDaily,
                    finalCostMonthly: formData.finalCostMonthly,
                    status: 'WAITING_FOR_SIGNATURES',
                    signedByLandlord: true,
                    signedByTenant: false,
                    paymentStatus: 'UNPAID',
                };
            },
        },
    ];

    const submitSequentially = async (steps, formData, initialResults = {}) => {
        let results = { ...initialResults };
        for (const step of steps) {
            const data = step.prepare(formData);
            console.log(`prepared data`);
            if (step.shouldSubmit && !step.shouldSubmit(data)) {
                // So we still have access to field values of this object for next steps submissions.
                // See: contractTemplate
                results[step.type] = data;
                continue;
            }
            try {
                if (step.isArray) {
                    results[step.type] = await Promise.all(
                        data.map((item) => submitSingle(step.submit, item))
                    );
                } else {
                    results[step.type] = await submitSingle(step.submit, data);
                }
                console.log(`Submitted ${step.type}:`, results[step.type]);
            } catch (error) {
                console.error(`Error submitting ${step.type}:`, error);
                throw error;
            }
        }
        return results;
    };

    const handleSubmitData = useCallback(
        async (formData) => {
            // console.log(formData)
            if (loadingProperty) {
                console.log('DATA IS STILL LOADING');
                return;
            }

            setShowModal(true);
            try {
                const results = await submitSequentially(submissionSteps, formData);
                // Modal doesnt have enought time to get rendered and then close and call onClose()
                // so we add 2 seconds of artificial time...
                // TODO: this is super pizdec
                await new Promise((resolve) => setTimeout(resolve, 2000));
                console.log('All data submitted successfully:', results);
                setSubmissionResult({ success: true, data: results.CONTRACT });
            } catch (error) {
                console.error('Error in submission process:', error);
                setSubmissionResult({ success: false, error: error });
            } finally {
                setShowModal(false);
            }
        },
        [userData, submitSingle, loadingProperty]
    );

    const handleModalExited = useCallback(() => {
        console.log('handleModalExited');
        navigate(`/properties/${params.property_uuid}`, {
            state: {
                success: submissionResult.success,
                createdContractID: submissionResult.data.id,
            },
        });
    }, [navigate, params.property_uuid, submissionResult]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '3vh 0 3vh 0',
                }}
            >
                <h1 className="font-onest fs-24 fw-700 fs-24 p-0 m-0"> Create contract</h1>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmitData(values)}
            >
                {({ values, setFieldValue }) => {
                    // console.log("values", values)
                    return (
                        <Form className="flex-col justify-content-between flex-grow-1">
                            <ListContainer>
                                <CustomCheckbox
                                    label={'Termless contract'}
                                    id="termlessContract"
                                    name="termlessContract"
                                    onChange={(e) => {
                                        handleTermlessChange(e, setFieldValue);
                                    }}
                                />
                                <DataRangeContainer>
                                    <label
                                        htmlFor="date"
                                        className="fw-500 fs-16 lh-26 text-color-black"
                                    >
                                        Select dates
                                    </label>
                                    <DataRangeRow>
                                        <CustomDatePicker
                                            id="date.startDate"
                                            name="date.startDate"
                                            placeholderText="Select start date"
                                            fullWidth={true}
                                            disabled={values.termlessContract}
                                            minDate={new Date()}
                                        />
                                        {!values.termlessContract && (
                                            <CustomDatePicker
                                                id="date.endDate"
                                                name="date.endDate"
                                                placeholderText="Select end date"
                                                fullWidth={true}
                                                minDate={values.date.startDate}
                                                disabled={
                                                    values.termlessContract ||
                                                    !values.date.startDate
                                                }
                                            />
                                        )}
                                    </DataRangeRow>
                                </DataRangeContainer>

                                <CustomRangeField
                                    label={`Guest amount (max ${
                                        property?.maxGuestAmount || 'loading...'
                                    })`}
                                    id="guestAmount"
                                    name="guestAmount"
                                    min={1}
                                    max={property?.maxGuestAmount || 5}
                                />
                                <div className="flex-col">
                                    <label className="fw-500 fs-16 lh-26 text-color-black">
                                        Rent cost daily
                                    </label>
                                    <DisplayFormField>
                                        {roundToTwoDecimals(property?.rentCostDaily) ||
                                            'loading...'}
                                    </DisplayFormField>
                                </div>
                                <div className="flex-col">
                                    <label className="fw-500 fs-16 lh-26 text-color-black">
                                        Rent cost monthly
                                    </label>
                                    <DisplayFormField>
                                        {roundToTwoDecimals(property?.rentCostMonthly) ||
                                            'loading...'}
                                    </DisplayFormField>
                                </div>

                                <CustomRangeField
                                    label={'Discount (% max 99)'}
                                    id="discountPercent"
                                    name="discountPercent"
                                    min={0}
                                    max={99}
                                    onChange={(e) => {
                                        handleCalcFinalPrice(e, setFieldValue);
                                    }}
                                />

                                <TextInput
                                    label={'Final cost daily'}
                                    id="finalCostDaily"
                                    name="finalCostDaily"
                                    disabled={true}
                                />
                                <TextInput
                                    label={'Final cost monthly'}
                                    id="finalCostMonthly"
                                    name="finalCostMonthly"
                                    disabled={true}
                                />
                            </ListContainer>
                            <CustomButton type="submit" style={{ margin: '2rem 0 2rem 0' }}>
                                Create
                            </CustomButton>
                        </Form>
                    );
                }}
            </Formik>

            <SubmitStatusModal show={showModal} onClose={handleModalExited} />
        </>
    );
};

export default ContractCreateForm;
