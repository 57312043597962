export const API_KEY = '-*I/H)wvMN7LaEUwh;fFb;~M\\7rb`8^|Ci~3HS+5SF=zP|f`';
export const BACKEND_URL = 'https://api.easyrentor.com';
export const API_URL = `${BACKEND_URL}/api/v1`;

// local backend
// export const API_KEY = 'owner-mock-key';
// export const BACKEND_URL = 'http://localhost:8080';
// export const API_URL = `${BACKEND_URL}/api/v1`;

// MOCK AUTH UUIDs
export const LANDLORD_ID = '12ab5478-b8ec-460e-996c-fa6c1981e6f6';
export const TENANT_ID = '564ddb7f-99db-48f5-9c38-12c6a04dcf54';

export const ROLE_LANDLORD = 'LANDLORD';
export const ROLE_TENANT = 'TENANT';

export const TENANT_UPLOAD_PHOTO_THRESH = 24 * 60 * 60 * 1000; //24 hours

export const DEFAULT_PROFILE_URL_IMG =
    'https://s3.eu-north-1.amazonaws.com/uploads.easyrentor.com/media/users/ProfileNullPic.png';

// USER ACTIONS
export const ACTION_USER_SET = 'ACTION_USER_SET';
export const ACTION_USER_CLEAR = 'ACTION_USER_CLEAR';
export const ACTION_USER_SET_ROLE = 'ACTION_USER_SET_ROLE';

// NOTIFICATION ACTIONS
export const ACTION_NOTIFICATION_ADD = 'ACTION_NOTIFICATION_ADD';
export const ACTION_NOTIFICATION_REMOVE = 'ACTION_NOTIFICATION_REMOVE';
export const ACTION_NOTIFICATION_CLEAR_ROLE = 'ACTION_NOTIFICATION_CLEAR_ROLE';
export const ACTION_NOTIFICATION_CLEAR_ALL = 'ACTION_NOTIFICATION_CLEAR_ALL';
export const ACTION_NOTIFICATION_CONNECT_WEBSOCKET = 'ACTION_NOTIFICATION_CONNECT_WEBSOCKET';
export const ACTION_NOTIFICATION_DISCONNECT_WEBSOCKET = 'ACTION_NOTIFICATION_DISCONNECT_WEBSOCKET';
export const ACTION_NOTIFICATION_SET_CONNECTION_STATUS =
    'ACTION_NOTIFICATION_SET_CONNECTION_STATUS';

// SOCKET STATUS
export const STATUS_SOCKET_CONNECTED = 'STATUS_SOCKET_CONNECTED';
export const STATUS_SOCKET_DISCONNECTED = 'STATUS_SOCKET_DISCONNECTED';
export const STATUS_SOCKET_ERROR = 'STATUS_SOCKET_ERROR';

// BACKEND RELATED
// TODO: those should be either typescript enums or fetched from backend
export class HEADER_TYPES {
    // Private Fields
    static #_PROFILE = 'PROFILE';
    static #_NAVIGATION = 'NAVIGATION';
    static #_EDIT = 'EDIT';

    // Accessors for "get" functions only (no "set" functions)
    static get PROFILE() {
        return this.#_PROFILE;
    }
    static get NAVIGATION() {
        return this.#_NAVIGATION;
    }
    static get EDIT() {
        return this.#_EDIT;
    }
}

export class USER_ROLES {
    static #_TENANT = 'TENANT';
    static #_LANDLORD = 'LANDLORD';

    static get TENANT() {
        return this.#_TENANT;
    }
    static get LANDLORD() {
        return this.#_LANDLORD;
    }

    static toArray() {
        return [this.TENANT, this.LANDLORD];
    }
}

export class CONTRACT_STATUS {
    // Private Fields
    static #_WAITING_FOR_SIGNATURES = 'WAITING_FOR_SIGNATURES';
    static #_WAITING_FOR_START_DATE = 'WAITING_FOR_START_DATE';
    static #_ACTIVE = 'ACTIVE';
    static #_ANNOTATED = 'ANNOTATED';
    static #_FINISHED = 'FINISHED';

    // Accessors for "get" functions only (no "set" functions)
    static get WAITING_FOR_SIGNATURES() {
        return this.#_WAITING_FOR_SIGNATURES;
    }
    static get WAITING_FOR_START_DATE() {
        return this.#_WAITING_FOR_START_DATE;
    }
    static get ACTIVE() {
        return this.#_ACTIVE;
    }
    static get ANNOTATED() {
        return this.#_ANNOTATED;
    }
    static get FINISHED() {
        return this.#_FINISHED;
    }
}

export class REPORT_STATUS {
    // Private Fields
    static #_EXISTS = 'EXISTS';
    static #_WAITING_FOR_ASSETS = 'WAITING_FOR_ASSETS';
    static #_WAITING_FOR_ANNOTATING = 'WAITING_FOR_ANNOTATING';
    static #_WAITING_FOR_LANDLORD_CHECK = 'WAITING_FOR_LANDLORD_CHECK';
    static #_WAITING_FOR_AGREEMENT = 'WAITING_FOR_AGREEMENT';
    static #_FINISHED = 'FINISHED';

    static get EXISTS() {
        return this.#_EXISTS;
    }
    static get WAITING_FOR_ASSETS() {
        return this.#_WAITING_FOR_ASSETS;
    }
    static get WAITING_FOR_ANNOTATING() {
        return this.#_WAITING_FOR_ANNOTATING;
    }
    static get WAITING_FOR_LANDLORD_CHECK() {
        return this.#_WAITING_FOR_LANDLORD_CHECK;
    }
    static get WAITING_FOR_AGREEMENT() {
        return this.#_WAITING_FOR_AGREEMENT;
    }
    static get FINISHED() {
        return this.#_FINISHED;
    }
}
