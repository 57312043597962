import CustomButton from '@/components/UI/buttons/CustomButton';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const PropertyDeleteModal = ({ property, onDelete, trigger, canDelete }) => {
    const [show, setShow] = useState(false);
    const [timeLeft, setTimeLeft] = useState(3);

    const handleShow = useCallback(() => setShow(true), []);
    const handleClose = useCallback(() => {
        setShow(false);
        setTimeLeft(3);
    }, []);

    const handleConfirm = useCallback(() => {
        onDelete();
        handleClose();
    }, [onDelete, handleClose]);

    useEffect(() => {
        if (show && timeLeft > 0) {
            const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timerId);
        }
    }, [timeLeft, show]);

    useEffect(() => {
        if (show) {
            setTimeLeft(3);
        }
    }, [show]);

    return (
        <>
            {React.cloneElement(trigger, { onClick: handleShow })}
            <Modal show={show} onHide={handleClose} backdrop={'static'} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {canDelete ? 'Confirm property deletion' : 'Property cannot be deleted '}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {canDelete ? (
                        <p>
                            Are you sure you want to delete the property "
                            {property?.name || 'loading'}
                            "? This will also delete all Rooms and Photos related to this property.{' '}
                            <br />
                            <b>
                                <u>This action cannot be undone.</u>
                            </b>
                        </p>
                    ) : (
                        <p>
                            This property cannot be deleted, as it has some contracts bound to it!
                            Please delete all related contracts and try again.
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div className="w-100 flex-col align-items-center">
                        <CustomButton
                            onClick={handleClose}
                            style={{
                                backgroundColor: 'rgba(170, 20, 20, 1)',
                            }}
                        >
                            {canDelete ? 'Cancel' : 'Close'}
                        </CustomButton>
                        {canDelete && (
                            <CustomButton
                                onClick={handleConfirm}
                                disabled={timeLeft > 0}
                                style={{ marginTop: '0.5rem' }}
                            >
                                Confirm {timeLeft > 0 ? `(${timeLeft})` : ''}
                            </CustomButton>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PropertyDeleteModal;
