import CustomButton from '@/components/UI/buttons/CustomButton';
import { setRole } from 'actions/userActions';
import { RoleButtonsContainer } from 'components/RoleSwitcher/RoleSwitcher.styles';
import { USER_ROLES } from 'constants';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RoleSwitcher = ({ header }) => {
    const userData = useSelector((state) => state.users.userData);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [visible, setVisible] = useState(false);
    const toggleOpen = () => {
        setVisible(true);
    };
    const toggleClose = () => {
        setVisible(false);
    };

    const handleChangeRole = (role) => {
        console.log('change role for', role);
        // changing currentRole for selected
        dispatch(setRole(role));
        toggleClose();
        navigate('/profile');
    };

    const handleCreateRoleProfile = (role) => {
        console.log('create role for', role);
        toggleClose();
        navigate('/profile/edit', {
            state: {
                role: USER_ROLES.LANDLORD,
                creating: true,
            },
        });
    };

    return (
        <>
            <div
                onClick={(e) => {
                    toggleOpen();
                    if (header.props.onClick) header.props.onClick(e);
                }}
            >
                {header}
            </div>

            <Modal show={visible} centered={true} onHide={toggleClose}>
                <Modal.Header closeButton>
                    <h3> Select a role </h3>
                </Modal.Header>
                <Modal.Body>
                    <RoleButtonsContainer>
                        {USER_ROLES.toArray().map((role) =>
                            userData.roles.includes(role) ? (
                                <CustomButton
                                    key={role}
                                    type="button"
                                    onClick={() => {
                                        handleChangeRole(role);
                                    }}
                                >
                                    Continue as {role}
                                </CustomButton>
                            ) : (
                                <CustomButton
                                    key={role}
                                    type="button"
                                    onClick={() => {
                                        handleCreateRoleProfile(role);
                                    }}
                                >
                                    Become a {role}
                                </CustomButton>
                            )
                        )}
                    </RoleButtonsContainer>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RoleSwitcher;
