import { ACTION_USER_CLEAR, ACTION_USER_SET, ACTION_USER_SET_ROLE } from '../constants';

export const setUser = (userData) => ({
    type: ACTION_USER_SET,
    payload: { userData },
});

export const clearUser = () => ({
    type: ACTION_USER_CLEAR,
});

export const setRole = (role) => ({
    type: ACTION_USER_SET_ROLE,
    payload: { role },
});
