import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditProfileIcon } from '../../components/UI/icons';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import RoundButton from '../../components/UI/buttons/RoundButton';
import * as Yup from 'yup';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { usePostSingle } from '../../hooks/usePostSingle';
import EasyRentorService from '../../service/EasyRentorService';
import { ROLE_TENANT } from 'constants';
import { ROLE_LANDLORD } from 'constants';
import SubmitStatusModal from 'components/SubmitStatusModal';
import { setRole, setUser } from 'actions/userActions';
import { DEFAULT_PROFILE_URL_IMG } from 'constants';
import { uploadToS3 } from 'service/S3UploadService';
import CustomButton from '@/components/UI/buttons/CustomButton';
import TextInput from '@/components/UI/inputs/TextInput';

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2vh;
`;

export const ProfileImageContainer = styled.div`
    position: relative;
    max-width: 200px;
    max-height: 200px;
    aspect-ratio: 1;
    border-radius: 50%;
    margin: 0 auto;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
    }

    .action-button {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
`;

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

const ProfileEditView = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { role, creating } = location?.state || {};
    const { userData } = useSelector((state) => state.users);
    const fileInputRef = useRef(null);

    const initialValues = {
        username: userData?.username || '',
        email: userData?.email || '',
        phoneNumber: userData?.phoneNumber || '',
        firstName: userData?.firstName || '',
        lastName: userData?.lastName || '',
        picUrl: userData?.picUrl || null,
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        phoneNumber: Yup.string().required('Phone number is required'),
    });

    const getPostCallback = useMemo(() => {
        switch (role) {
            case ROLE_TENANT: {
                return EasyRentorService.submitTenant;
            }
            case ROLE_LANDLORD: {
                return EasyRentorService.submitLandlord;
            }
            default: {
                return EasyRentorService.submitTenant;
            }
        }
    }, [role]);

    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [submitSingle, loading, error, response, submitStatus] = usePostSingle();

    const handleSubmit = async (formData) => {
        setShowModal(true);
        console.log('FORM DATA PROFILE PIC', formData.picUrl);
        let uploadedProfilePicURL = formData.picUrl;
        console.log('uploadedProfilePicURL', uploadedProfilePicURL, userData.picUrl);
        try {
            // New file so uploading
            if (formData.picUrl?.file) {
                console.log('New profile pic file. Uploading');
                const S3Key = `media/users/user_profile_pic_${userData.id}.png`;
                uploadedProfilePicURL = await uploadToS3(formData.picUrl.file, S3Key);
            }

            const preparedData = {
                id: userData.id,
                telegramId: null,
                roles: [role],
                username: formData.username,
                email: formData.email,
                phoneNumber: formData.phoneNumber,
                firstName: formData.firstName,
                lastName: formData.lastName,
                picUrl: uploadedProfilePicURL,
                verified: true,
            };
            const response = await submitSingle(getPostCallback, preparedData);

            dispatch(setUser(response));
            dispatch(setRole(role));

            await new Promise((resolve) => setTimeout(resolve, 2000));
        } catch (error) {
            console.error('Error while saving profile', error);
        } finally {
            setShowModal(false);
        }
    };

    const handleModalExited = useCallback(() => {
        console.log('Modal exited, would navigate to /profile here');
        // Uncomment the next line to actually navigate
        navigate('/profile');
    }, [navigate]);

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleProfilePicUpload = (event, setFieldValue, setFieldError) => {
        const file = event.target.files[0];
        console.log(file);

        if (!file) return;

        // is file too large
        if (file.size > MAX_FILE_SIZE) {
            setFieldError('picUrl', 'File size exceeds the limit (5MB)!');
            return;
        }

        // file type not supported

        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            setFieldError('picUrl', 'Unsupported file type!');
            return;
        }

        const photoObject = {
            url: URL.createObjectURL(file),
            file: file,
            name: file.name,
            type: file.type,
            fileSize: file.size,
        };
        console.log('PROFILE PIC', photoObject);
        setFieldValue('picUrl', photoObject);
    };

    if (role === undefined || creating === undefined) {
        console.warn('[ProfileEditView] Missing required state parameters (role, creating)', {
            role,
            creating,
        });
        return <Navigate to={'/login'} replace />;
    }

    if (![ROLE_LANDLORD, ROLE_TENANT].includes(role)) {
        console.warn('[ProfileEditView] Invalid role specified', role);
        return <Navigate to={'/login'} replace />;
    }

    return (
        <>
            <SubmitStatusModal show={showModal} onClose={handleModalExited} />

            <div className="flex-col align-items-center mt-3 mb-4">
                <h1 className="font-onest fw-700 fs-24 lh-28 text-center text-color-dark">
                    {creating ? 'You are almost there!' : 'Editing profile'} for {role}
                </h1>
                <p className="font-poppins fw-400 fs-16 lh-26 text-center">
                    {creating ? 'Tell us a bit about yourself' : 'Change any relevant info'}
                </p>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, setFieldError }) => (
                    <Form className="flex-col justify-content-between flex-grow-1">
                        <ListContainer>
                            <ProfileImageContainer>
                                <img
                                    src={
                                        values.picUrl?.url ||
                                        userData?.picUrl ||
                                        DEFAULT_PROFILE_URL_IMG
                                    }
                                    alt="Profile"
                                />
                                <RoundButton
                                    type="button"
                                    className="action-button"
                                    onClick={triggerFileInput}
                                >
                                    <EditProfileIcon />
                                </RoundButton>
                                <input
                                    ref={fileInputRef}
                                    id="picUrl"
                                    name="picUrl"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={(e) =>
                                        handleProfilePicUpload(e, setFieldValue, setFieldError)
                                    }
                                />
                            </ProfileImageContainer>
                            <TextInput
                                label={'Email'}
                                id="email"
                                name="email"
                                placeholder={'example@email.com'}
                                disabled={true}
                            />
                            <TextInput
                                label={'Name'}
                                id="firstName"
                                name="firstName"
                                placeholder={'Antonio'}
                            />
                            <TextInput
                                label={'Surname'}
                                id="lastName"
                                name="lastName"
                                placeholder={'Banderas'}
                            />
                            <TextInput
                                label={'Phone number'}
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder={'000 000000000000'}
                            />
                        </ListContainer>
                        <CustomButton type="submit">Save</CustomButton>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ProfileEditView;
