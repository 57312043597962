import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Search from 'components/Search/Search';
import { useFetching } from 'hooks/useFetching';
import EasyRentorService from 'service/EasyRentorService';
import ContractListSimple from 'components/ContractListSimple/ContractListSimple';

const ContractListView = () => {
    const userData = useSelector((state) => state.users.userData);

    const [contracts, setContracts] = useState([]);
    const [fetchContracts, loadingContracts, errorContracts] = useFetching(async (id) => {
        const response = await EasyRentorService.getLandlordContractsSimplified(id);
        // console.log(response);
        const data = response.data.content;
        const sortedNewest = data.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
        setContracts(sortedNewest);
        console.log(sortedNewest);
    });

    useEffect(() => {
        fetchContracts(userData.id);
    }, [userData.id]);

    const [searchString, setSearchString] = useState('');
    const handleSearch = (values) => {
        setSearchString(values);
    };

    const searchedContracts = useMemo(() => {
        const trimmedSearchString = searchString.trim().toLowerCase();
        if (trimmedSearchString === '') {
            return contracts;
        }

        return contracts.filter((contract) =>
            contract.property.name.toLowerCase().includes(trimmedSearchString)
        );
    }, [searchString, contracts]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '1rem',
                    margin: '3vh 0 6vh 0',
                }}
            >
                <Search onSearch={handleSearch} />
            </div>

            <ContractListSimple contracts={searchedContracts} loading={loadingContracts} />
        </>
    );
};

export default ContractListView;
