import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TenantPhotosContext } from '../../context/TenantPhotosContext';
import {
    PhotoPairsContainer,
    PhotoPairWrapper,
    PhotoWrapper,
    RoomHeader,
    RoomsContainer,
} from './TenantRoomPhotos.styles';
import { usePostSingle } from '../../hooks/usePostSingle';
import { useSelector } from 'react-redux';
import EasyRentorService from '../../service/EasyRentorService';
import { uploadToS3 } from '../../service/S3UploadService';
import SubmitStatusModal from '../SubmitStatusModal';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { REPORT_STATUS } from '../../constants';
import CustomDropdown from '@/components/UI/dropdowns/CustomDropdown';
import CustomButton from '@/components/UI/buttons/CustomButton';
import { useFetching } from '@/hooks/useFetching';
import { Spinner } from 'react-bootstrap';

const organizePhotoPairs = (rooms, original, newAssets) => {
    const roomPairs = new Map(rooms.map((room) => [room.id, []]));

    console.log(roomPairs);
    roomPairs.forEach((pairs, room) => {
        // TODO: Add conditions to check if pair exists, error handling...
        const roomOriginals = original.filter((photo) => photo.roomId === room);
        roomOriginals.forEach((origPair) => {
            const newAssetPair = newAssets.find((photo) => photo.originalID === origPair.id);
            pairs.push({ original: origPair, new: newAssetPair });
        });
    });

    return roomPairs;
};

const TenantPhotosOverview = ({ photoData }) => {
    const { data } = useContext(TenantPhotosContext);
    const { roomStatus, newAssets, original } = data;
    const userData = useSelector((state) => state.users.userData);
    const navigate = useNavigate();

    const [contractData, setContractData] = useState(null);
    const [fetchContractData, loadingContractData, errorContractData] = useFetching(async (id) => {
        const response = await EasyRentorService.getContractByID(id);
        setContractData(response.data);
    });

    useEffect(() => {
        fetchContractData(photoData.contractID);
    }, [photoData.contractID]);

    const roomPairs = useMemo(() => {
        return organizePhotoPairs(roomStatus, original, newAssets);
    }, [roomStatus, newAssets, original]);

    const [showModal, setShowModal] = useState(false);
    const [submitSingle, loading, error, response, submitStatus] = usePostSingle();

    const submissionSteps = useMemo(
        () => [
            {
                type: 'PHOTO',
                submit: async (photo) => {
                    const S3Key = `properties/tenants/tenant_upload_${uuidv4()}.png`;
                    const url = await uploadToS3(photo.blob, S3Key);

                    const photoForDB = {
                        contractId: photoData.contractID,
                        propertyId: photoData.propertyID,
                        roomId: photo.roomId,
                        stage: 'MOVE_OUT',
                        url: url,
                        original: false,
                        comment: 'saved from front tenant',
                        annotated: false,
                        takenBy: userData.id,
                        takenAt: new Date().toISOString(),
                        qualityEval: {},
                        mlPipelineConfig: 'ML PIPELINE CONFIG',
                        mlStatus: 'ML STATUS',
                    };
                    return EasyRentorService.submitPhoto(photoForDB);
                },
                prepare: () => {
                    // console.log(newAssets)
                    return newAssets;
                },
                isArray: true,
            },
            {
                type: 'REPORT',
                submit: EasyRentorService.submitReport,
                prepare: () => {
                    return {
                        id: photoData.contractID,
                        annotated: false,
                        status: REPORT_STATUS.WAITING_FOR_ANNOTATING,
                    };
                },
            },
            {
                type: 'REPORT_ASSET_PAIRS',
                submit: EasyRentorService.submitReportAssetPair,
                prepare: (prevData) => {
                    return prevData.PHOTO.map((pair) => ({
                        reportId: prevData.REPORT.id,
                        originalAssetId: pair.originalAssetID,
                        newAssetId: pair.newAssetId,
                    }));
                },
                isArray: true,
            },
            {
                type: 'UPDATE_CONTRACT_DATA',
                submit: EasyRentorService.submitContract,
                prepare: (prevData) => {
                    console.log('contractData', contractData);
                    return {
                        ...contractData,
                        tenantAssetsUploaded: true,
                    };
                },
            },
            {
                type: 'TELEGRAM_NOTIFICATION',
                submit: async (chat) => {
                    return EasyRentorService.sendTelegramMessage(chat.chatID, chat.message);
                },
                prepare: (prevData) => {
                    const CHATS = [
                        {
                            chatID: '152321559',
                            message: `'${prevData.REPORT.id}' --> https://annotator.easyrentor.com/annotations/${prevData.REPORT.id}`,
                        },
                        {
                            chatID: '1226856446',
                            message: `'${prevData.REPORT.id}' --> https://annotator.easyrentor.com/annotations/${prevData.REPORT.id}`,
                        },
                        {
                            chatID: '698537644',
                            message: `'${prevData.REPORT.id}' --> https://annotator.easyrentor.com/annotations/${prevData.REPORT.id}`,
                        },
                    ];
                    return CHATS;
                },
                isArray: true,
            },
        ],
        [contractData]
    );

    const submitSequentially = async (steps, initialResults = {}) => {
        let results = { ...initialResults };
        for (const step of steps) {
            const data = step.prepare(results);
            console.log(`prepared type: ${step.type}, data:`, data);
            try {
                if (step.isArray) {
                    results[step.type] = await Promise.all(
                        data.map(async (item) => {
                            const results = await submitSingle(step.submit, item);
                            if (step.type === 'PHOTO') {
                                return {
                                    originalAssetID: item.originalID,
                                    newAssetId: results.id,
                                };
                            }
                            return results;
                        })
                    );
                } else {
                    results[step.type] = await submitSingle(step.submit, data);
                }
                console.log(`Submitted ${step.type}:`, results[step.type]);
            } catch (error) {
                console.error(`Error submitting ${step.type}:`, error);
                throw error;
            }
        }
        return results;
    };

    const handleSubmitData = useCallback(async () => {
        if (!contractData) {
            console.error('DATA NOT READY YET!');
            return;
        }

        setShowModal(true);
        try {
            const results = await submitSequentially(submissionSteps);
            console.log('All data submitted successfully:', results);
        } catch (error) {
            console.error('Error in submission process:', error);
        } finally {
            setShowModal(false);
        }
    }, [submitSingle, submissionSteps]);

    const handleModalExited = useCallback(() => {
        console.log('Modal exited, would navigate to /contracts/:uuid here');
        // Uncomment the next line to actually navigate
        navigate(`/contracts/${photoData.contractID}`);
    }, [navigate]);

    if (loadingContractData) {
        return (
            <Spinner
                animation="border"
                role="status"
                style={{
                    width: '10rem',
                    height: '10rem',
                    margin: 'auto auto',
                }}
            />
        );
    }

    return (
        <>
            <h4 className="mb-3">Room photos overview</h4>
            <RoomsContainer>
                {roomStatus.map((room) => (
                    <CustomDropdown key={room.id} header={<RoomHeader>{room.name}</RoomHeader>}>
                        <PhotoPairsContainer>
                            {roomPairs.get(room.id)?.map((pair, index) => (
                                <PhotoPairWrapper key={index}>
                                    <PhotoWrapper>
                                        <img
                                            src={pair.original.url}
                                            className="img-fluid"
                                            alt="Original"
                                        />
                                    </PhotoWrapper>
                                    <PhotoWrapper>
                                        <img
                                            src={pair.new.src}
                                            className="img-fluid"
                                            alt="New asset"
                                        />
                                    </PhotoWrapper>
                                </PhotoPairWrapper>
                            ))}
                        </PhotoPairsContainer>
                    </CustomDropdown>
                ))}
            </RoomsContainer>
            <CustomButton type="button" style={{ marginTop: 'auto' }} onClick={handleSubmitData}>
                Save
            </CustomButton>

            <SubmitStatusModal show={showModal} onClose={handleModalExited} />
        </>
    );
};

export default TenantPhotosOverview;
