import React, { useCallback, useContext, useMemo } from 'react';
import ElevationWrapper from '../UI/wrappers/ElevationWrapper/ElevationWrapper';
import { Spinner } from 'react-bootstrap';
import './TenantRoomList.css';
import { TenantPhotosContext } from '../../context/TenantPhotosContext';
import CustomButton from '@/components/UI/buttons/CustomButton';

const TenantRoomList = ({ onRoomSelect, onContinue }) => {
    const { data } = useContext(TenantPhotosContext);

    const roomStatusMap = useMemo(() => {
        return data.roomStatus.reduce((acc, room) => {
            acc[room.id] = room.completed;
            return acc;
        }, {});
    }, [data.roomStatus]);

    const isAllRoomsFinished = useMemo(() => {
        return data.roomStatus.every((room) => room.completed);
    }, [data.roomStatus]);

    return (
        <>
            <h4 className="mb-3">Select room:</h4>
            <ElevationWrapper
                className="flex-col"
                fullWidth={true}
                style={{ gap: 'min(2vw, 1rem)' }}
            >
                {data.roomStatus.length === 0 ? (
                    <Spinner
                        animation="border"
                        role="status"
                        style={{ margin: '0 auto', width: '10rem', height: '10rem' }}
                    />
                ) : (
                    data.roomStatus.map((room) => (
                        <CustomButton
                            key={room.id}
                            onClick={() => onRoomSelect(room)}
                            className={roomStatusMap[room.id] ? 'room-finished' : ''}
                            disabled={roomStatusMap[room.id]}
                        >
                            {room.name}
                        </CustomButton>
                    ))
                )}
            </ElevationWrapper>
            {isAllRoomsFinished && data.roomStatus.length > 0 && (
                <CustomButton style={{ marginTop: 'auto' }} onClick={onContinue}>
                    Continue
                </CustomButton>
            )}
        </>
    );
};

export default TenantRoomList;
