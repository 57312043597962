import styled from "styled-components";


export const StyledDiv = styled.div.withConfig({
    shouldForwardProp: props => !['fullWidth'].includes(props)
})`
    position: relative;
    width: ${({fullWidth}) => fullWidth ? '100%' : 'auto'};
    padding: 1rem;
    background-color: rgba(255, 254, 254, 0.9);
    border: 1px solid rgba(250, 250, 250, 1);
    border-radius: 1vh;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
    transition: all 0.25s ease-in-out;
`