import CopyToClipboard from 'react-copy-to-clipboard';
import './CopyableContent.css';
import { useState } from 'react';
import { Clipboard, ClipboardCheckFill } from 'react-bootstrap-icons';
import { Badge } from 'react-bootstrap';

const CopyableContent = ({ content, minimized = false }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 5000); // Reset after 5 seconds
    };

    return (
        <div className="flex-row">
            {!minimized && <span className="me-2 text-truncate">{content}</span>}
            <CopyToClipboard text={content} onCopy={handleCopy}>
                <Badge bg="dark">
                    <span className="icon-container">
                        <Clipboard
                            size={16}
                            strokeWidth={2}
                            className={copied ? 'fade-out' : 'fade-in'}
                        />
                        <ClipboardCheckFill
                            size={16}
                            strokeWidth={2}
                            className={copied ? 'fade-in' : 'fade-out'}
                        />
                    </span>
                </Badge>
            </CopyToClipboard>
        </div>
    );
};

export default CopyableContent;
