import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import userReducer from './reducers/userReducer';
import persistReducer from 'redux-persist/es/persistReducer';
import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import createMigrate from 'redux-persist/es/createMigrate';
import persistStore from 'redux-persist/es/persistStore';
import notificationReducer from './reducers/notificationReducer';
import notificationMiddleware from './middleware/notificationMiddleware';

const migrations = {
    0: (state) => {
        return {
            ...state,
        };
    },
};

const persistConfig = {
    key: 'easyrentorMainApp',
    version: 1,
    storage,
    migrations: createMigrate(migrations),
};

const rootReducer = combineReducers({
    users: userReducer,
    notifications: notificationReducer,
    // more
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(notificationMiddleware),
});

export const persistor = persistStore(store);
