import { createContext, useState } from 'react';

export const FORM_ACTION_ADD = 'FORM_ACTION_ADD';
export const FORM_ACTION_REMOVE = 'FORM_ACTION_REMOVE';

export const FormContext = createContext();

export const FormProvider = ({ initialData, children }) => {
    const [formData, setFormData] = useState(initialData);

    const updateBasicInfo = (newData) => {
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                basicInfo: {
                    ...prevFormData.basicInfo,
                    ...newData,
                },
            };
        });
    };

    const addRoom = (room) => {
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                rooms: [...prevFormData.rooms, room],
            };
        });
    };

    const removeRoom = (room_id) => {
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                rooms: prevFormData.rooms.filter((room) => room.internalID !== room_id),
            };
        });
    };

    const addRoomPhotos = (photos) => {
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                photos: [...prevFormData.photos, ...photos],
            };
        });
    };

    const removeRoomPhotos = (room_id) => {
        console.log('remove roomPhotos', room_id);
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                photos: prevFormData.photos.filter((photo) => photo.internalRoomID !== room_id),
            };
        });
    };

    const updateContractTemplate = (newData) => {
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                contractTemplate: {
                    ...prevFormData.contractTemplate,
                    ...newData,
                },
            };
        });
    };

    return (
        <FormContext.Provider
            value={{
                formData,
                updateBasicInfo,
                addRoom,
                removeRoom,
                addRoomPhotos,
                removeRoomPhotos,
                updateContractTemplate,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};
