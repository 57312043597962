import { createContext, useState } from "react";


export const TenantPhotosContext = createContext()

export const TenantPhotoProvider = ({initialData, children}) => {
    const [data, setData] = useState(initialData)

    const updateData = (key, newData, action='add') => {
        setData((prevData) => {
            switch (action) {
                case 'add': {
                    if (Array.isArray(newData)) {
                        return {
                            ...prevData,
                            [key]: [
                                ...prevData[key],
                                ...newData
                            ]
                        }
                    } else {
                        return {
                            ...prevData,
                            [key]: [
                                ...prevData[key],
                                newData
                            ]
                        }
                    }
                    
                }
                case 'set': {
                    return {
                        ...prevData,
                        [key]: [
                            ...newData
                        ]
                    }
                }
            }
        })
        
    }

    return (
        <TenantPhotosContext.Provider value={{ data, updateData }}>
            {children}
        </TenantPhotosContext.Provider>
    )
}