import styled from 'styled-components';

export const FullscreenContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ReferenceImage = styled.img`
    position: absolute;
    width: min(20vw, 20vh);
    bottom: 5px;
    left: 5px;
    z-index: 10;
    cursor: pointer;
    border-radius: 10px;
    border: 5px solid white;
    transition: width 0.3s ease-in-out;

    &.open {
        width: min(50vw, 50vh);
    }
`;
export const PhotoPairsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
`;
export const PhotoPairWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
`;

export const PhotoWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
`;

export const RoomHeader = styled.div`
    position: relative;
    width: 100%;
    padding: 15px 8px 15px 8px;
    border-radius: 8px;
    border: none;
    background-color: green;
    color: #ffffff;
    font-family: Poppins;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
`;

export const RoomsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: min(2vw, 1.5rem);
    margin-bottom: 2rem;
`;

export const ValidationLoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3);
`;

export const ValidationStatus = styled.div`
    position: relative;
    width: 100%;
    text-align: center;
`;
