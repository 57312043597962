import React, { useEffect, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
    BottomNavContractsIcon,
    BottomNavProfileIcon,
    BottomNavPropertiesIcon,
    BottomNavReportsIcon,
} from '../UI/icons';
import { NavContainer, NavWrapper } from './BottomNav.styles';
import { useSelector } from 'react-redux';
import { ROLE_LANDLORD } from '../../constants';
import { useCurrentRoute } from '../../hooks/useCurrentRoute';

const NavLinkIcon = React.memo(({ to, children }) => {
    return (
        <NavLink to={to}>
            {({ isActive }) => {
                // console.log("isActive",isActive, to)
                return React.cloneElement(children, { active: isActive });
            }}
        </NavLink>
    );
});

const BottomNav = () => {
    const { currentRole, userData } = useSelector((state) => state.users);

    const isLandlord = useMemo(() => {
        return currentRole === ROLE_LANDLORD;
    }, [currentRole]);

    const currentRoute = useCurrentRoute();

    if (currentRoute?.bottom_navigation === false) {
        return null;
    }

    return (
        <NavContainer>
            <NavWrapper>
                <NavLinkIcon to="/profile">
                    <BottomNavProfileIcon />
                </NavLinkIcon>
                <NavLinkIcon to="/properties">
                    <BottomNavPropertiesIcon />
                </NavLinkIcon>
                {isLandlord && (
                    <NavLinkIcon to="/contracts">
                        <BottomNavContractsIcon />
                    </NavLinkIcon>
                )}

                <NavLinkIcon to="/reports">
                    <BottomNavReportsIcon />
                </NavLinkIcon>
            </NavWrapper>
        </NavContainer>
    );
};

export default React.memo(BottomNav);
