import styled from "styled-components";

export const ListContainer = styled.div`
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    row-gap: 1rem;
    margin-top: 2vh;
`;

export const ContractContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`;
