import PropertyCard from '../PropertyCard';
import { HeaderContainer, Header } from './PropertyList.styles';
import { Spinner } from 'react-bootstrap';
import RoundButton from '../UI/buttons/RoundButton';
import { PlusIcon } from '../UI/icons';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ListContainer = styled.div`
    display: grid;
    justify-items: center;
    gap: 24px;
    margin-top: 2vh;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

    grid-template-columns: 1fr;

    @media (min-width: 576px) {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    /* Desktop */
    @media (min-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
    }

    /* Extra large screens */
    @media (min-width: 1400px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const PropertyList = ({ properties, loading }) => {
    const navigate = useNavigate();
    const handleCreateNewProperty = () => {
        navigate('create');
    };

    return (
        <div style={{ marginBottom: '5rem' }}>
            <HeaderContainer>
                <Header> Properties </Header>
                <RoundButton size={1} onClick={handleCreateNewProperty}>
                    <PlusIcon />
                </RoundButton>
            </HeaderContainer>
            <ListContainer>
                {loading ? (
                    <Spinner
                        animation="border"
                        role="status"
                        style={{ width: '10rem', height: '10rem', color: 'var(--primary-color)' }}
                    />
                ) : (
                    <>
                        {properties.length > 0 ? (
                            properties.map((property) => (
                                <PropertyCard key={property.id} propertyInfo={property} />
                            ))
                        ) : (
                            <p className="no-items-list-text">
                                Currently, there are no objects here. To add a new object, press the
                                plus sign located next to the “properties” title.
                            </p>
                        )}
                    </>
                )}
            </ListContainer>
        </div>
    );
};

export default PropertyList;
