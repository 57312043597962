import React, { useCallback, useEffect, useState } from 'react';
import { StatisticsContainer, StatsName, StatsValue, Wrapper } from './ProfileStatistics.styles';
import { useFetching } from '../../hooks/useFetching';
import EasyRentorService from '../../service/EasyRentorService';
import { Placeholder } from 'react-bootstrap';
import { ROLE_LANDLORD } from 'constants';
import { ROLE_TENANT } from 'constants';
import { useNavigate } from 'react-router-dom';

const statisticsNames = {
    left: { LANDLORD: 'Contracts', TENANT: 'Contracts' },
    right: { LANDLORD: 'Properties', TENANT: 'Reports' },
};

const ProfileStatistics = ({ userId, userRole }) => {
    const navigate = useNavigate();

    const getLeftFetchCallback = useCallback(
        (...args) => {
            switch (userRole) {
                case ROLE_LANDLORD: {
                    return EasyRentorService.getLandlordContracts(...args);
                }
                case ROLE_TENANT: {
                    return EasyRentorService.getTenantContracts(...args);
                }
                default:
                    throw new Error('invalid role!');
            }
        },
        [userRole]
    );

    const getRightFetchCallback = useCallback(
        (...args) => {
            switch (userRole) {
                case ROLE_LANDLORD: {
                    return EasyRentorService.getLandlordProperties(...args);
                }
                case ROLE_TENANT: {
                    return EasyRentorService.getTenantReports(...args);
                }
                default:
                    throw new Error('invalid role!');
            }
        },
        [userRole]
    );

    const [statisticsValues, setStatisticsValues] = useState({
        left: 0,
        right: 0,
    });

    const [fetchLeft, loadingLeft, errorLeft] = useFetching(async (id) => {
        const response = await getLeftFetchCallback(id);
        const num = response.data.content.length;
        setStatisticsValues((prev) => ({ ...prev, left: num }));
    });

    const [fetchRight, loadingRight, errorRight] = useFetching(async (id) => {
        const response = await getRightFetchCallback(id);
        const num = response.data.content.length;
        // console.log(response, num_contracts)
        setStatisticsValues((prev) => ({ ...prev, right: num }));
    });

    useEffect(() => {
        fetchLeft(userId);
        fetchRight(userId);
    }, [userId, userRole]);

    const handleNavigateLeft = useCallback(() => {
        switch (userRole) {
            case ROLE_LANDLORD:
                return navigate('/contracts');
            case ROLE_TENANT:
                return navigate('/properties');
            default:
                return null;
        }
    }, [userRole, navigate]);

    const handleNavigateRight = useCallback(() => {
        switch (userRole) {
            case ROLE_LANDLORD:
                return navigate('/properties');
            case ROLE_TENANT:
                return navigate('/reports');
            default:
                return null;
        }
    }, [userRole, navigate]);

    return (
        <StatisticsContainer>
            {loadingLeft || loadingRight ? (
                <Wrapper>
                    <Placeholder as={StatsValue} className="w-100 text-center" animation="glow">
                        <Placeholder xs={9} />
                    </Placeholder>
                    <Placeholder as={StatsName} className="w-100 text-center" animation="glow">
                        <Placeholder xs={9} />
                    </Placeholder>
                </Wrapper>
            ) : (
                <>
                    <Wrapper onClick={handleNavigateLeft} side="left">
                        <StatsValue> {errorLeft ? 'Error!' : statisticsValues.left} </StatsValue>
                        <StatsName> {statisticsNames.left[userRole]} </StatsName>
                    </Wrapper>
                    <Wrapper onClick={handleNavigateRight} side="right">
                        <StatsValue> {errorRight ? 'Error!' : statisticsValues.right} </StatsValue>
                        <StatsName> {statisticsNames.right[userRole]} </StatsName>
                    </Wrapper>
                </>
            )}
        </StatisticsContainer>
    );
};

export default ProfileStatistics;
