import styled from "styled-components";

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2vh;
    overflow-x:auto;
`

export const DataRangeRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
`

export const DataRangeContainer = styled.div`
    display: flex;
    flex-direction: column;
`



export const DisplayFormField = styled.div`
    position: relative;
    width: 100%;
    padding: 0.5rem 2rem 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(250, 250, 250, 1);
    border-radius: 1vh;
    outline: none;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease-in-out;
`