import React, { useState } from 'react';
import BasicInfoForm from '../../components/PropertyForms/BasicInfoForm';
import { FormProvider } from '../../context/PropertyFormContext';
import RoomManager from '../../components/PropertyForms/RoomManager';
import { useNavigate } from 'react-router-dom';
import Overview from '../../components/PropertyForms/Overview';
import ContractTemplateManager from '../../components/PropertyForms/ContractTemplateManager';

const initialFormData = {
    basicInfo: {},
    rooms: [],
    photos: [],
    contractTemplate: {},
};

const AddPropertyMultiStepForm = () => {
    const navigate = useNavigate();

    const [formStep, setFormStep] = useState(0);
    const handleNextStep = () => {
        setFormStep((prev) => prev + 1);
    };
    const handlePrevStep = () => {
        if (formStep === 0) {
            navigate('/properties');
        } else {
            setFormStep((prev) => prev - 1);
        }
    };

    const formSteps = [
        <BasicInfoForm onNextStep={handleNextStep} onPrevStep={handlePrevStep} />,
        <RoomManager onNextStep={handleNextStep} onPrevStep={handlePrevStep} />,
        <ContractTemplateManager onNextStep={handleNextStep} onPrevStep={handlePrevStep} />,
        <Overview onPrevStep={handlePrevStep} />,
    ];

    return (
        <>
            {/* THIS IS HEADER */}

            {/* Render form step here */}
            <FormProvider initialData={initialFormData}>{formSteps[formStep]}</FormProvider>
        </>
    );
};

export default AddPropertyMultiStepForm;
