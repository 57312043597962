import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { REPORT_STATUS, ROLE_LANDLORD, ROLE_TENANT } from '../../constants';
import ReportListView from './ReportListView';
import EasyRentorService from '../../service/EasyRentorService';

const ReportsListManager = () => {
    const currentRole = useSelector((state) => state.users.currentRole);

    const getFetchingFunction = () => {
        switch (currentRole) {
            case ROLE_TENANT: {
                return EasyRentorService.getTenantReports;
            }
            case ROLE_LANDLORD: {
                return EasyRentorService.getLandlordReports;
            }
            default:
                throw new Error('invalid role');
        }
    };

    const reportValidStatuses = useMemo(() => {
        switch (currentRole) {
            case ROLE_LANDLORD: {
                return [
                    // REPORT_STATUS.WAITING_FOR_ANNOTATING,
                    REPORT_STATUS.WAITING_FOR_LANDLORD_CHECK,
                    REPORT_STATUS.WAITING_FOR_AGREEMENT,
                    REPORT_STATUS.FINISHED,
                ];
            }
            case ROLE_TENANT: {
                return [REPORT_STATUS.WAITING_FOR_AGREEMENT, REPORT_STATUS.FINISHED];
            }
            default:
                return [];
        }
    }, [currentRole]);

    const fetchingFunc = useMemo(() => {
        return getFetchingFunction();
    }, [currentRole]);

    return (
        <ReportListView fetchCallback={fetchingFunc} reportValidStatuses={reportValidStatuses} />
    );
};

export default ReportsListManager;
