import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HouseIcon, LocationIcon, RoomsBedIcon, BadgeIcon } from '../../components/UI/icons';
import defaultPropertyPreview from '../../assets/images/default-room.png';
import { useFetching } from '../../hooks/useFetching';
import EasyRentorService from '../../service/EasyRentorService';
import { Spinner } from 'react-bootstrap';
import ContractInviteModal from '../../components/ContractInviteModal/ContractInviteModal';
import { useValidateParamsUUID } from '../../hooks/useValidateParamsUUID';
import { CONTRACT_STATUS } from '../../constants';
import CopyableContent from '../../components/UI/text/CopyableContent/CopyableContent';
import { formatDateString, roundToTwoDecimals } from '../../utils/utils';
import ElevationWrapper from '../../components/UI/wrappers/ElevationWrapper/ElevationWrapper';
import Wrapper from '../../components/UI/wrappers/Wrapper/Wrapper';
import styled from 'styled-components';
import { ArrowRightCircleFill, Trash2Fill } from 'react-bootstrap-icons';
import PropertyDeleteModal from 'components/PropertyDeleteModal/PropertyDeleteModal';
import RoundButton from '@/components/UI/buttons/RoundButton';
import SubmitStatusModal from 'components/SubmitStatusModal';
import { deleteBulkFromS3 } from 'service/S3UploadService';
import CustomButton from '@/components/UI/buttons/CustomButton';
import CustomDropdown from '@/components/UI/dropdowns/CustomDropdown';
import { ContractStatus } from '@/types/domain/contract/constants';

export const ContractContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
`;

const PropertyInfoContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 700px;
    gap: 1rem;
    margin: 0 auto;
`;

const PropertySingleView = () => {
    const navigate = useNavigate();
    const { property_uuid } = useParams();
    useValidateParamsUUID(property_uuid, '/properties');

    const [property, setProperty] = useState(null);
    const [fetchProperty, loadingProperty, errorProperty] = useFetching(async (id) => {
        const response = await EasyRentorService.getPropertyDTOByID(id);
        setProperty(response.data);
    });
    const [recentContracts, setRecentContracts] = useState([]);
    const [numAllContracts, setNumAllContracts] = useState(null);
    const [fetchRecentContracts, loadingRecentContracts, errorRecentContracts] = useFetching(
        async (id) => {
            const response = await EasyRentorService.getPropertyContractsByID(id);
            const data = response.data;
            // count all contracts that are not finished
            const activeContracts = data.filter(
                (contract) => contract.status !== ContractStatus.FINISHED
            );
            setNumAllContracts(activeContracts.length);
            const recentContracts = data.filter(
                (contract) => contract.status === CONTRACT_STATUS.WAITING_FOR_SIGNATURES
            );
            setRecentContracts(recentContracts);
        }
    );

    useEffect(() => {
        fetchProperty(property_uuid);
        fetchRecentContracts(property_uuid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property_uuid]);

    const navigateCreateContract = () => {
        navigate('create-contract');
    };

    const location = useLocation();
    const [showRecentlyCreatedModal, setShowRecentlyCreatedModal] = useState({
        show: false,
        contractId: null,
    });
    useEffect(() => {
        if (location.state?.success) {
            console.log('location', location);
            setShowRecentlyCreatedModal({
                show: true,
                contractId: location.state.createdContractID,
            });
            window.history.replaceState({}, document.title);
        }
    }, [location]);

    const handleCloseModal = () => {
        setShowRecentlyCreatedModal({
            show: false,
            contractId: null,
        });
    };

    const [showModal, setShowModal] = useState(false);

    const handleDeleteProperty = async () => {
        setShowModal(true);
        try {
            const photosToDelete = [];

            // 1) get all photos to delete

            // property preview
            if (property?.previewPhotoUrl) {
                photosToDelete.push(property.previewPhotoUrl);
            }

            // room photos
            try {
                const roomsResponse = await EasyRentorService.getPropertyRoomsWithPhotosByID(
                    property_uuid
                );
                if (roomsResponse?.data?.content) {
                    const roomPhotosURLs = roomsResponse.data.content.flatMap((room) =>
                        room.photos.map((photo) => photo.url)
                    );
                    photosToDelete.push(...roomPhotosURLs);
                }
            } catch (error) {
                console.error('Failed to fetch room photos: ', error);
            }

            // 2) Delete all photos from S3
            // console.log('photosToDelete', photosToDelete);
            if (photosToDelete.length > 0) {
                try {
                    await deleteBulkFromS3(photosToDelete);
                } catch (error) {
                    console.error('Failed to delete some or all photos from S3: ', error);
                }
            }

            // 3) delete property itself and dependencies in cascade
            // console.log('DELETE PROPERTY STEP HERE');
            const propertyResponse = await EasyRentorService.deleteProperty(property_uuid);
            if (propertyResponse.status === 200 || propertyResponse.status === 204) {
                console.log('Property deleted successfully!:', propertyResponse);

                // Hello from this: src\components\ContractCreateForm\ContractCreateForm.jsx
                await new Promise((resolve) => setTimeout(resolve, 2000));
            } else {
                throw new Error('Property deletion failed with status: ', propertyResponse.status);
            }
        } catch (error) {
            console.error('Error in deletion process:', error);
        } finally {
            setShowModal(false);
        }
    };

    const handleModalExited = useCallback(() => {
        console.log('Modal exited, would navigate to /properties here');
        // Uncomment the next line to actually navigate
        navigate('/properties');
    }, [navigate]);

    if (loadingProperty || loadingRecentContracts) {
        return (
            <>
                <Spinner
                    animation="border"
                    role="status"
                    style={{ width: '10rem', height: '10rem', margin: 'auto auto' }}
                />
            </>
        );
    }

    if (errorProperty) {
        return (
            <div>
                <h2>Error Loading page </h2>
                <p>{errorProperty.message || 'An unknown error occured!'}</p>
                <CustomButton type="button" onClick={() => navigate('/properties')}>
                    Back to Properties List
                </CustomButton>
            </div>
        );
    }

    return (
        <>
            <SubmitStatusModal show={showModal} onClose={handleModalExited} />
            <ContractInviteModal
                show={showRecentlyCreatedModal.show}
                newContractID={showRecentlyCreatedModal.contractId}
                onClose={handleCloseModal}
            />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '3vh auto 3vh auto',
                    width: '100%',
                    maxWidth: '700px',
                }}
            >
                <div className="flex-row">
                    <h1 className="font-onest fw-700 fs-24 lh-28 text-color-dark mb-0 me-2">
                        {property?.name}
                    </h1>
                </div>

                <div className="flex-row">
                    <PropertyDeleteModal
                        property={property}
                        onDelete={handleDeleteProperty}
                        canDelete={numAllContracts === 0}
                        trigger={
                            <RoundButton size={2}>
                                <Trash2Fill size={20} color="rgba(170, 20, 20, 0.9)" />
                            </RoundButton>
                        }
                    />
                </div>
            </div>

            <PropertyInfoContainer>
                <div
                    className="flex-col justify-content-center align-items-center"
                    style={{ maxWidth: '500px' }}
                >
                    <img
                        src={property?.previewPhotoUrl || defaultPropertyPreview}
                        className="w-100"
                        alt="Preview of property"
                    />
                </div>
                <ElevationWrapper
                    fullWidth={true}
                    className="flex-col"
                    style={{ gap: 'min(2vw, 1rem)' }}
                >
                    <Wrapper className="flex-row">
                        <HouseIcon />
                        <span className="font-onest fw-500 fs-16 lh-26 text-color-black ms-2">
                            {property?.name}
                        </span>
                    </Wrapper>
                    <Wrapper className="flex-row">
                        <LocationIcon />
                        <span className="font-onest fw-500 fs-16 lh-26 text-color-black ms-2">
                            {property?.address.city}, {property?.address?.street}{' '}
                            {property?.address?.streetNumber}
                        </span>
                    </Wrapper>
                    <Wrapper
                        className="flex-row"
                        onClick={() => {
                            navigate('rooms');
                        }}
                    >
                        <RoomsBedIcon />
                        <span className="font-onest fw-500 fs-16 lh-26 text-color-black ms-2">
                            {property?.roomAmount} rooms
                        </span>
                        <div className="ms-auto">
                            <ArrowRightCircleFill size={24} strokeWidth={2} />
                        </div>
                    </Wrapper>
                    <Wrapper className="flex-row">
                        <BadgeIcon />
                        <span className="font-onest fw-500 fs-16 lh-26 text-color-black ms-2">
                            {roundToTwoDecimals(property?.rentCostDaily)}&#8364;
                        </span>
                    </Wrapper>
                    <Wrapper className="flex-row">
                        <BadgeIcon />
                        <span className="font-onest fw-500 fs-16 lh-26 text-color-black ms-2">
                            {property?.contractTemplateSimplified?.name}
                        </span>
                    </Wrapper>
                </ElevationWrapper>

                <ElevationWrapper fullWidth={true} className="flex-col mb-3">
                    <div className="flex-col">
                        {errorRecentContracts ? (
                            <span className="font-onest fw-500 fs-16 lh-26 text-color-error">
                                {errorRecentContracts?.message ||
                                    'Unknown error while loading contracts!'}
                            </span>
                        ) : (
                            <CustomDropdown
                                header={
                                    <span className="font-onest fw-500 fs-16 lh-26 text-color-black">
                                        Recently created contracts ({recentContracts.length})
                                    </span>
                                }
                            >
                                <div>
                                    {recentContracts.map((contract, index) => (
                                        <ContractCardSimple
                                            key={contract.id}
                                            contractID={contract.id}
                                            propertyName={property.name}
                                            endDate={contract.endDate}
                                        />
                                    ))}
                                </div>
                            </CustomDropdown>
                        )}
                    </div>
                </ElevationWrapper>
            </PropertyInfoContainer>

            <CustomButton onClick={navigateCreateContract} style={{ marginTop: 'auto' }}>
                Create contract
            </CustomButton>
        </>
    );
};

export default PropertySingleView;

const ContractCardSimple = React.memo(({ contractID, propertyName, endDate }) => {
    const navigate = useNavigate();

    return (
        <ContractContainer>
            <div
                className="flex-col flex-grow-1"
                onClick={() => {
                    navigate(`/contracts/${contractID}`);
                }}
            >
                <span className="font-onest fw-700"> {propertyName} </span>
                <span className="font-onest"> {formatDateString(endDate)} </span>
            </div>
            <CopyableContent content={contractID} minimized={true} />
        </ContractContainer>
    );
});
