import React, { useContext, useEffect, useState } from 'react';
import { useFetching } from '../../hooks/useFetching';
import EasyRentorService from '../../service/EasyRentorService';
import { Spinner } from 'react-bootstrap';
import Camera from '../Camera/Camera';
import { TenantPhotosContext } from '../../context/TenantPhotosContext';
import {
    FullscreenContainer,
    PhotoPairWrapper,
    PhotoWrapper,
    ReferenceImage,
    ValidationLoadingContainer,
    ValidationStatus,
} from './TenantRoomPhotos.styles';
import { useParams } from 'react-router-dom';
import { CameraHeader } from '../Camera/Camera.styles';
import { blobToBase64, s3UrlToBase64, stripBase64Prefix } from 'utils/utils';
import { readFromS3Base64 } from 'service/S3UploadService';

const TenantRoomPhotos = ({ roomInfo, onFinal }) => {
    const { data, updateData } = useContext(TenantPhotosContext);

    const [photos, setPhotos] = useState([]);
    const [fetchPhotos, loadingPhotos, errorPhotos] = useFetching(async (id) => {
        const response = await EasyRentorService.getRoomPhotosByID(id);
        const data = response.data.content;
        const fromLandLord = data.filter((photo) => photo.original === true);
        setPhotos(fromLandLord);
    });
    useEffect(() => {
        if (roomInfo) {
            fetchPhotos(roomInfo.id);
        }
    }, [roomInfo]);
    useEffect(() => {
        if (!loadingPhotos) {
            updateData('original', photos);
        }
    }, [loadingPhotos]);

    const [showValidation, setShowValidation] = useState(false);
    const [takenPhotoValidation, setTakenPhotoValidation] = useState(null);

    const [step, setStep] = useState(0);
    const handleNext = (blob) => {
        const objectURL = URL.createObjectURL(blob);
        setTakenPhotoValidation(blob);
        const newPhoto = {
            roomId: roomInfo.id,
            src: objectURL,
            originalID: photos[step].id,
            blob: blob,
        };
        updateData('newAssets', newPhoto);
        setShowValidation(true);
    };

    const handleValidationComplete = () => {
        if (step === photos.length - 1) {
            const prevRoomStatus = data.roomStatus;
            const newRoomStatus = prevRoomStatus.map((status) => {
                if (status.id === roomInfo.id) {
                    return { ...status, completed: true };
                }
                return status;
            });
            updateData('roomStatus', newRoomStatus, 'set');
            onFinal();
        } else {
            setShowValidation(false);
            setStep((prev) => prev + 1);
        }
    };

    const handlePrev = () => {
        setStep((prev) => prev - 1);
    };

    const [referenceOpen, setReferenceOpen] = useState(false);
    const toggleReferece = () => {
        setReferenceOpen(!referenceOpen);
    };

    // console.log('showValidation', showValidation);

    if (loadingPhotos) {
        return (
            <div>
                <Spinner
                    animation="border"
                    role="status"
                    style={{ width: '10rem', height: '10rem' }}
                />
            </div>
        );
    }

    return (
        <div>
            {showValidation ? (
                <PhotoValidation
                    referenceAssetURL={photos[step]?.url}
                    takenAssetBlob={takenPhotoValidation}
                    onValidationComplete={handleValidationComplete}
                />
            ) : (
                <>
                    <CameraHeader>{`${step + 1}/${photos.length} - ${roomInfo.name}`}</CameraHeader>
                    <Camera onClose={handleNext} />
                    <ReferenceImage
                        src={photos[step]?.url}
                        alt="referece"
                        className={referenceOpen ? 'open' : ''}
                        onClick={toggleReferece}
                    />
                </>
            )}
        </div>
    );
};

export default TenantRoomPhotos;

const PhotoValidation = ({ referenceAssetURL, takenAssetBlob, onValidationComplete }) => {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState({ success: false, message: null });

    const takenAssetURL = URL.createObjectURL(takenAssetBlob);

    // console.log('VALIDATEION TEST', referenceAssetURL, takenAssetBlob);

    const validatePhoto = async () => {
        try {
            // await new Promise((resolve) => setTimeout(resolve, 2000));
            console.log('STARTING CONVERSION to base 64');

            console.log('Converting taken image to base64...');
            const takenBase64 = await blobToBase64(takenAssetBlob);
            const strippedTakenBase64 = stripBase64Prefix(takenBase64);
            console.log('taken base64 (100)', takenBase64.slice(0, 100));

            console.log('Converting reference image to base64...');
            const refereceBase64 = await readFromS3Base64(referenceAssetURL);
            const strippedrefereceBase64 = stripBase64Prefix(refereceBase64);
            console.log('reference base64 (100)', strippedrefereceBase64.slice(0, 100));

            if (!takenBase64 || !refereceBase64) {
                throw new Error('Failed to convert one or both images to base64!');
            }

            console.log('calling validation...');
            const response = await EasyRentorService.validatePhotoPair(
                strippedTakenBase64,
                strippedrefereceBase64
            );
            console.log('validatePhotoPair response', response);
            const responseDataStatus =
                response.data.status === 'good'
                    ? true
                    : response.data.status === 'bad'
                    ? false
                    : false;
            setResponse({
                success: responseDataStatus,
                message: response.data.message || 'Success!',
            });
        } catch (error) {
            setResponse({
                success: false,
                message: 'Validation service is not available',
            });
            // console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        validatePhoto();
    }, []);

    useEffect(() => {
        if (!loading) {
            const timer = setTimeout(() => {
                onValidationComplete();
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [loading, onValidationComplete]);

    return (
        <div className="flex-col position-relative">
            {loading && (
                <ValidationLoadingContainer>
                    <Spinner
                        animation="border"
                        role="status"
                        style={{
                            width: '5rem',
                            height: '5rem',
                            color: 'white',
                            margin: 'auto auto',
                        }}
                    />
                </ValidationLoadingContainer>
            )}
            <PhotoPairWrapper>
                <PhotoWrapper>
                    <img src={referenceAssetURL} className="img-fluid" alt="Reference" />
                </PhotoWrapper>
                <PhotoWrapper>
                    <img src={takenAssetURL} className="img-fluid" alt="Taken from camera" />
                </PhotoWrapper>
            </PhotoPairWrapper>
            <span
                className={`font-onest fs-16 lh-28 fw-500 text-center ${
                    response.success ? 'text-color-success' : 'text-color-error'
                }`}
            >
                {response.message}
            </span>
        </div>
    );
};
