import React, { FC, useState } from 'react';
import { useField } from 'formik';
import { TextInputProps } from '@/components/UI/inputs/TextInput/types';
import styles from './TextInput.module.css';
import clsx from 'clsx';

const TextInput: FC<TextInputProps> = ({
    label,
    icon = null,
    fullWidth = false,
    rounded = false,
    className,
    ...props
}) => {
    const [field, meta] = useField(props.name);

    const [focus, setFocus] = useState(false);

    const handleFocus = () => {
        setFocus(true);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocus(false);
        field.onBlur(e);
    };

    return (
        <div className={clsx(styles.container, fullWidth && styles.fullWidth)}>
            {icon && (
                <div className={clsx(styles.iconWrapper, focus && styles.focused)}>{icon}</div>
            )}
            {label && (
                <label htmlFor={props.id || props.name} className={styles.label}>
                    {label}
                </label>
            )}
            <input
                type="text"
                {...field}
                {...props}
                value={field.value || ''}
                className={clsx(
                    styles.textinput,
                    rounded && styles.rounded,
                    icon && styles.iconPadding,
                    props.disabled && styles.disabled,
                    className
                )}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
        </div>
    );
};

export default TextInput;
