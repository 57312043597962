import React, { useContext, useEffect } from 'react';
import { Header, ListContainer } from './PropertyForms.styles';
import { FormContext } from '../../context/PropertyFormContext';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import AddressField from './AddressField';
import RoundButton from '../UI/buttons/RoundButton';
import { BackArrowIcon, NotificationsProfileIcon } from '../UI/icons';
import CustomCheckbox from '@/components/UI/inputs/CustomCheckbox';
import CustomSelect from '@/components/UI/inputs/CustomSelect/CustomSelect';
import CustomButton from '@/components/UI/buttons/CustomButton';
import TextInput from '@/components/UI/inputs/TextInput';

const optionCurrencies = [
    { name: 'EUR', value: 'EUR' },
    { name: 'USD', value: 'USD' },
];

const initialValues = {
    name: '',
    address: {
        country: '',
        state: '',
        city: '',
        zipCode: '',
        street: '',
        streetNumber: '',
        flat: '',
    },
    area: '',
    roomAmount: 0,
    maxGuestAmount: '',
    rentCostDaily: '',
    rentCostMonthly: '',
    paymentCurrency: '',
    smokingAllowed: false,
    animalsWithFurAllowed: false,
    animalsOtherAllowed: false,
    previewImage: null,
};

// const initialValues = {
//     name: 'DEBUG',
//     address: {
//         country: '3',
//         state: '3',
//         city: '3',
//         zipCode: '3',
//         street: '3',
//         streetNumber: '3',
//         flat: '3',
//     },
//     area: '33',
//     roomAmount: 0,
//     maxGuestAmount: '3',
//     rentCostDaily: '33',
//     rentCostMonthly: '333',
//     paymentCurrency: 'EUR',
//     smokingAllowed: false,
//     animalsWithFurAllowed: false,
//     animalsOtherAllowed: false,
//     previewImage: null,
// };

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name Required'),
    address: Yup.object({
        country: Yup.string().required('Country required'),
        state: Yup.string().required('State required'),
        city: Yup.string().required('City required'),
        zipCode: Yup.string().required('Zip code required'),
        street: Yup.string().required('Street required'),
        streetNumber: Yup.string().required('Street number required'),
        flat: Yup.string().required('Flat number required'),
    }),
    area: Yup.number()
        .typeError('Must be a number')
        .required('Room area Required')
        .positive()
        .integer(),
    maxGuestAmount: Yup.number()
        .typeError('Must be a number')
        .required('Max guests Required')
        .positive()
        .integer(),
    rentCostDaily: Yup.number()
        .typeError('Must be a number')
        .required('rentCostDaily Required')
        .positive()
        .integer(),
    rentCostMonthly: Yup.number()
        .typeError('Must be a number')
        .required('rentCostMonthly Required')
        .positive()
        .integer(),
    paymentCurrency: Yup.string().required('paymentCurrency Required'),
    smokingAllowed: Yup.boolean().required('Smoking allowed Required'),
    animalsWithFurAllowed: Yup.boolean().required('Animals with fur Required'),
    animalsOtherAllowed: Yup.boolean().required('Other animals Required'),
});

const BasicInfoForm = ({ onNextStep, onPrevStep }) => {
    const { formData, updateBasicInfo } = useContext(FormContext);

    const onSubmit = (values) => {
        updateBasicInfo(values);
        onNextStep();
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <RoundButton onClick={onPrevStep}>
                    <BackArrowIcon />
                </RoundButton>
                <RoundButton>
                    <NotificationsProfileIcon />
                </RoundButton>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '3vh 0 3vh 0',
                }}
            >
                <Header> Add property </Header>
            </div>

            <Formik
                initialValues={{ ...initialValues, ...formData.basicInfo }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form className="flex-col justify-content-between flex-grow-1">
                        <ListContainer>
                            <TextInput
                                label={'Name of the property'}
                                id="name"
                                name="name"
                                placeholder={'My Property...'}
                            />

                            {/* <TextInput label={"Address"} id="address" name="address" placeholder={'Baker street...'}/> */}
                            <AddressField label={'Address'} name="address" id="address" />

                            {/* <TextInput
                                label={'Rooms'}
                                id="roomAmount"
                                name="roomAmount"
                                placeholder={'2'}
                            /> */}

                            <TextInput
                                label={'Property area'}
                                id="area"
                                name="area"
                                placeholder={'40..'}
                            />

                            <TextInput
                                label={'Maximum amount of guests'}
                                id="maxGuestAmount"
                                name="maxGuestAmount"
                                placeholder={'3'}
                            />

                            <CustomSelect
                                label={'Payment currency'}
                                id="paymentCurrency"
                                name="paymentCurrency"
                                options={optionCurrencies}
                                defaultValue={'Select payment currency'}
                            />

                            <TextInput
                                label={'Rent prices daily'}
                                id="rentCostDaily"
                                name="rentCostDaily"
                                placeholder={'30'}
                            />

                            <TextInput
                                label={'Rent prices monthly'}
                                id="rentCostMonthly"
                                name="rentCostMonthly"
                                placeholder={'400'}
                            />

                            <CustomCheckbox
                                label={'Smoking allowed'}
                                id="smokingAllowed"
                                name="smokingAllowed"
                            />
                            <CustomCheckbox
                                label={'Animals with fur allowed'}
                                id="animalsWithFurAllowed"
                                name="animalsWithFurAllowed"
                            />
                            <CustomCheckbox
                                label={'Other animals allowed'}
                                id="animalsOtherAllowed"
                                name="animalsOtherAllowed"
                            />
                        </ListContainer>

                        <CustomButton
                            type="submit"
                            style={{ marginTop: '2rem' }}
                            disabled={isSubmitting}
                        >
                            Continue
                        </CustomButton>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default BasicInfoForm;
