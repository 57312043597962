import React from 'react';
import { HeaderContainer, UserWelcomeWrapper } from './Header.styles';
import RoundButton from '../UI/buttons/RoundButton';
import { NotificationsProfileIcon, BackArrowIcon, EditProfileIcon } from '../UI/icons';
import defaultProfileImage from '../../assets/images/default-user.jpg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Notifications from 'components/Notifications/Notifications';

export const ProfileHeader = React.memo(() => {
    const userData = useSelector((state) => state.users.userData);
    const navigate = useNavigate();
    return (
        <HeaderContainer>
            <UserWelcomeWrapper>
                <RoundButton type="button" onClick={(e) => navigate('/profile')}>
                    <img
                        src={userData.picUrl || defaultProfileImage}
                        alt="Small profile icon"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '50%',
                        }}
                    />
                </RoundButton>
                <div className="flex-col align-items-start justify-content-center">
                    <span className="font-onest fw-600 fs-12 lh-22 text-color-grey"> Hello, </span>
                    <span className="font-onest fw-600 fs-12 lh-22 text-color-dark">
                        {userData.firstName}
                    </span>
                </div>
            </UserWelcomeWrapper>
            {/* <Notifications /> */}
        </HeaderContainer>
    );
});

export const NavigationHeader = React.memo(({ fallbackRoute }) => {
    const navigate = useNavigate();
    return (
        <HeaderContainer>
            <RoundButton onClick={() => navigate(fallbackRoute)}>
                <BackArrowIcon />
            </RoundButton>
            {/* <Notifications /> */}
        </HeaderContainer>
    );
});

export const EditHeader = React.memo(() => {
    const navigate = useNavigate();
    const currentRole = useSelector((state) => state.users.currentRole);

    const handleNavigate = () => {
        navigate('/profile/edit', {
            state: {
                role: currentRole,
                creating: false,
            },
        });
    };

    return (
        <HeaderContainer>
            <RoundButton onClick={handleNavigate}>
                <EditProfileIcon />
            </RoundButton>
            {/* <Notifications /> */}
        </HeaderContainer>
    );
});
