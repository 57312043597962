import React, { useEffect, useMemo, useState } from 'react';
import { useFetching } from '../../hooks/useFetching';
import { useSelector } from 'react-redux';
import RoundButton from '../../components/UI/buttons/RoundButton';
import { FiltersIcon } from '../../components/UI/icons';
import Search from '../../components/Search/Search';
import ReportList from '../../components/ReportList/ReportList';
import { REPORT_STATUS } from '../../constants';

const ReportListView = ({ fetchCallback, reportValidStatuses }) => {
    const userData = useSelector((state) => state.users.userData);

    const [reports, setReports] = useState([]);
    const [fetchReports, loadingReports, errorReports] = useFetching(async (id) => {
        const response = await fetchCallback(id);
        // console.log(response)
        // TODO: filter reports for status WAITING_FOR_ANNOTATING, WAITING_FOR_LANDLORD_CHECK, WAITING_FOR_AGREEMENT, FINISHED
        const data = response.data.content;
        const filtered = data.filter((report) => reportValidStatuses.includes(report.status));
        setReports(filtered);
    });

    useEffect(() => {
        if (userData.id) {
            fetchReports(userData.id);
        }
    }, [userData]);

    const [searchString, setSearchString] = useState('');
    const handleSearch = (values) => {
        setSearchString(values);
    };

    const searchedReports = useMemo(() => {
        const trimmedSearchString = searchString.trim().toLowerCase();
        if (trimmedSearchString === '') {
            return reports;
        }

        return reports.filter((report) =>
            report.propertyName.toLowerCase().includes(trimmedSearchString)
        );
    }, [searchString, reports]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '1rem',
                    margin: '3vh 0 6vh 0',
                }}
            >
                <Search onSearch={handleSearch} placeholder={'Search for reports'} />
            </div>
            <ReportList reports={searchedReports} loading={loadingReports} />
        </>
    );
};

export default ReportListView;
