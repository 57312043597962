import React, { FC, useMemo } from 'react';
import { RoundButtonProps } from '@/components/UI/buttons/RoundButton/types';
import styles from './RoundButton.module.css';
import clsx from 'clsx';

const RoundButton: FC<RoundButtonProps> = ({
    children,
    onClick,
    size = 3,
    className,
    ...props
}) => {
    const buttonSize = useMemo(() => {
        return {
            width: `${size}rem`,
            height: `${size}rem`,
            minWidth: `${size}rem`,
            minHeight: `${size}rem`,
        };
    }, [size]);

    return (
        <button
            {...props}
            onClick={onClick}
            className={clsx(styles.button, className)}
            style={buttonSize}
        >
            {children}
        </button>
    );
};

export default React.memo(RoundButton);
