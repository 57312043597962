import React, { useEffect, useMemo, useState } from 'react';
import { useValidateParamsUUID } from '../../hooks/useValidateParamsUUID';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetching } from '../../hooks/useFetching';
import EasyRentorService from '../../service/EasyRentorService';
import { Badge, Spinner } from 'react-bootstrap';
import Wrapper from 'components/UI/wrappers/Wrapper/Wrapper';
import {
    BadgeIcon,
    EditProfileIcon,
    HouseIcon,
    LocationIcon,
    PersonIcon,
} from 'components/UI/icons';
import { useSelector } from 'react-redux';
import { formatDateString, formatDateStringISO, roundToTwoDecimals } from 'utils/utils';
import { ROLE_TENANT } from 'constants';
import CopyableContent from 'components/UI/text/CopyableContent/CopyableContent';
import RoundButton from '@/components/UI/buttons/RoundButton';
import { ROLE_LANDLORD } from 'constants';
import { differenceInYears } from 'date-fns';
import CustomButton from '@/components/UI/buttons/CustomButton';
import { TENANT_UPLOAD_PHOTO_THRESH } from 'constants';
import { CheckLg, InfoLg } from 'react-bootstrap-icons';
import TriggerableModal from '@/components/UI/modals/TriggerableModal';

const ContractSingleView = () => {
    const navigate = useNavigate();
    const { contract_uuid } = useParams();
    useValidateParamsUUID(contract_uuid, '/contracts');

    const currentRole = useSelector((state) => state.users.currentRole);
    const [contract, setContract] = useState(null);
    const [fetchContract, loadingContract, errorContract] = useFetching(async (id, showFor) => {
        const response = await EasyRentorService.getContractByIDandRole(id, showFor);
        console.log(response.data);
        setContract(response.data);
    });

    useEffect(() => {
        fetchContract(contract_uuid, currentRole);
    }, [contract_uuid, currentRole]);

    const navigateTenantRoomPhotos = () => {
        navigate('add-photos', {
            state: { propertyID: contract.property.id },
        });
    };

    const isTermless = useMemo(() => {
        return differenceInYears(contract?.endDate, contract?.startDate) === 50;
    }, [contract]);

    // true if CANNOT upload photos
    // false if CAN upload photos
    const photoUploadState = useMemo(() => {
        if (contract?.tenantAssetsUploaded) {
            return {
                canUpload: false,
                modalHeader: 'Photos already uploaded',
                modalBody:
                    'You have already uploaded photos for this contract. No futher action required.',
                icon: <CheckLg size={24} color="green" />,
                done: true,
            };
        }

        const currentDate = new Date();
        const contractEndDate = new Date(contract?.endDate);
        const timeDiff = contractEndDate - currentDate;
        const isWithinThreshold = timeDiff <= TENANT_UPLOAD_PHOTO_THRESH;
        const canUpload = isWithinThreshold || contract?.allowEarlyAssetUpload;

        if (!canUpload) {
            return {
                canUpload: false,
                modalHeader: 'Why is it disabled?',
                modalBody:
                    'Tenant can upload photos 24 hours before checkout. If you need to upload photos earlier, you can ask Landlord to enable early upload for this contract.',
                icon: <InfoLg size={24} color="#7C7C7C" />,
                done: false,
            };
        }

        return {
            canUpload: true,
            modalHeader: null,
            modalBody: null,
            icon: null,
            done: null,
        };
    }, [contract?.endDate, contract?.allowEarlyAssetUpload, contract?.tenantAssetsUploaded]);

    if (loadingContract) {
        return (
            <Spinner
                animation="border"
                role="status"
                style={{
                    width: '10rem',
                    height: '10rem',
                    margin: 'auto auto',
                }}
            />
        );
    }

    if (errorContract) {
        return (
            <div>
                <h2>Error Loading page </h2>
                <p>{errorContract.message || 'An unknown error occured!'}</p>
                <CustomButton onClick={() => navigate('/contracts')}>
                    Back to Contracts List
                </CustomButton>
            </div>
        );
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '3vh 0 3vh 0',
                    width: '100%',
                    maxWidth: '700px',
                    alignSelf: 'center',
                }}
            >
                <h1 className="font-onest fw-700 fs-24 lh-28 text-color-dark mb-0 me-2">
                    Contract information
                </h1>
                {currentRole === ROLE_LANDLORD && (
                    <RoundButton
                        type="button"
                        size={2}
                        onClick={() => {
                            navigate(`/contracts/${contract_uuid}/edit`);
                        }}
                    >
                        <EditProfileIcon />
                    </RoundButton>
                )}
            </div>

            <div
                className="flex-col"
                style={{
                    gap: 'min(2vw, 1rem)',
                    width: '100%',
                    maxWidth: '700px',
                    alignSelf: 'center',
                }}
            >
                <Wrapper className="flex-col p-0">
                    <div className="flex-row justify-content-between">
                        <div className="flex-col w-100 py-2 px-3">
                            <span className="fw-600"> CHECK-IN </span>
                            <span> {contract && formatDateString(contract?.startDate)} </span>
                            {/* <span>{contract?.startDate} </span> */}
                        </div>
                        <div
                            className="flex-col w-100 py-2 px-3"
                            style={{ borderLeft: '1px solid rgba(230, 230, 230, 0.7' }}
                        >
                            <span className="fw-600"> CHECK-OUT </span>
                            {isTermless ? (
                                <span> TERMLESS </span>
                            ) : (
                                <span>{contract && formatDateString(contract?.endDate)} </span>
                                // <span>{contract?.endDate} </span>
                            )}
                        </div>
                    </div>
                </Wrapper>
                <Wrapper className="flex-row">
                    <HouseIcon />
                    <span className="font-onest fw-500 fs-16 lh-26 text-color-black ms-2">
                        {contract?.property.name}
                    </span>
                </Wrapper>
                <Wrapper className="flex-row">
                    <LocationIcon />
                    <span className="font-onest fw-500 fs-16 lh-26 text-color-black ms-2">
                        {contract?.address.city}, {contract?.address.street}{' '}
                        {contract?.address.streetNumber}
                    </span>
                </Wrapper>
                <Wrapper className="flex-row">
                    <PersonIcon />
                    {contract?.user ? (
                        <span className="font-onest fw-500 fs-16 lh-26 text-color-black ms-2">
                            {contract?.user.firstName} {contract?.user.lastName}
                        </span>
                    ) : (
                        <>
                            <span className="font-onest fw-500 fs-16 lh-26 text-color-black ms-2 me-auto">
                                Contract not signed yet
                            </span>
                            <CopyableContent content={contract?.id} minimized={true} />
                        </>
                    )}
                </Wrapper>
                <Wrapper className="flex-row align-items-center">
                    <BadgeIcon />

                    <span className="font-onest fw-500 fs-16 lh-26 text-color-black ms-2 me-1">
                        {roundToTwoDecimals(contract?.finalCostMonthly)}
                        &#8364;
                    </span>
                    {contract?.discountPercent > 0 && (
                        <>
                            <span className="font-onest fw-500 fs-16 lh-26 text-color-grey">
                                <s>
                                    {roundToTwoDecimals(contract?.property.rentCostMonthly)}
                                    &#8364;
                                </s>
                            </span>
                            <Badge bg="dark" className="ms-auto">
                                discount {contract?.discountPercent}%
                            </Badge>
                        </>
                    )}
                </Wrapper>
            </div>

            <div
                className="flex-col"
                style={{
                    gap: '0.4rem',
                    marginTop: 'auto',
                }}
            >
                <a
                    href="https://s3.eu-north-1.amazonaws.com/legal.easyrentor.com/EasyRentor_agreement_draft_v3.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-onest fw-500 fs-16 lh-26 text-color-black text-center text-decoration-none"
                >
                    Full contract
                </a>

                <CustomButton
                    type="button"
                    onClick={() => {
                        navigate(`/reports/${contract_uuid}`);
                    }}
                >
                    View report
                </CustomButton>
                {currentRole === ROLE_TENANT && (
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            maxWidth: '350px',
                            alignSelf: 'center',
                        }}
                    >
                        <CustomButton
                            type="button"
                            onClick={navigateTenantRoomPhotos}
                            disabled={!photoUploadState.canUpload}
                            style={photoUploadState.done ? { backgroundColor: 'green' } : {}}
                        >
                            Add photos
                        </CustomButton>
                        {!photoUploadState.canUpload && photoUploadState.modalHeader && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '10px',
                                    transform: 'translate(-10px, -50%)',
                                }}
                            >
                                <TriggerableModal
                                    trigger={
                                        <RoundButton type="button" size={2}>
                                            {photoUploadState.icon}
                                        </RoundButton>
                                    }
                                    header={
                                        <h1 className="font-onest fw-700 fs-24 lh-28 text-color-dark mb-0">
                                            {photoUploadState.modalHeader}
                                        </h1>
                                    }
                                    body={<p>{photoUploadState.modalBody}</p>}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default ContractSingleView;
