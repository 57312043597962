import styled from 'styled-components';

export const CardWrapper = styled.div`
    position: relative;
    max-width: 300px;
    background-color: #ffffff;
    border-radius: 1vh;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.07);
`;

export const CardInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2vh;
    gap: 1vh;
`;

export const CardInfoRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
`;

export const ImageWrapper = styled.div`
    position: relative;
    max-width: 300px;
    max-height: 300px;
    aspect-ratio: 1;
    border-radius: 1vh;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
    }
`;
