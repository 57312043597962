import styled from 'styled-components';

export const StyledRoundWrapper = styled.div.withConfig({
    shouldForwardProp: (props) => !['size'].includes(props),
})`
    position: relative;
    width: ${({ size }) => size}rem;
    height: ${({ size }) => size}rem;
    min-width: ${({ size }) => size}rem;
    min-height: ${({ size }) => size}rem;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fffefe;
    border: 1px solid #fafafa;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px #2f2f2f1f;
`;
