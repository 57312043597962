import { CardImageContainer } from 'components/PropertyForms/PropertyForms.styles';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { capitalizeString, formatDateStringISO } from 'utils/utils';
import { useFetching } from 'hooks/useFetching';
import EasyRentorService from 'service/EasyRentorService';
import { Modal, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-template-rows: auto;
    justify-items: center;
    row-gap: 1rem;
    column-gap: 0.5rem;
`;

const RoomSingleView = () => {
    const { room_uuid } = useParams();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const roomName = decodeURIComponent(queryParams.get('name'));

    const [photos, setPhotos] = useState([]);
    const [fetchPhotos, loadingPhotos, errorPhotos] = useFetching(async (uuid) => {
        const response = await EasyRentorService.getRoomPhotosByID(uuid);
        const data = response.data.content;
        const filteredOriginals = data.filter((photo) => photo.original === true);
        setPhotos(filteredOriginals);
    });

    const [selectedPhoto, setSelectedPhoto] = useState(null);

    // Function to open the modal
    const openFullSize = (photo) => {
        setSelectedPhoto(photo);
    };

    // Function to close the modal
    const closeFullSize = () => {
        setSelectedPhoto(null);
    };

    useEffect(() => {
        fetchPhotos(room_uuid);
    }, [room_uuid]);

    if (loadingPhotos) {
        return (
            <Spinner
                animation="border"
                role="status"
                style={{
                    width: '15rem',
                    height: '15rem',
                    margin: 'auto auto',
                    color: 'var(--primary-color)',
                }}
            />
        );
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '3vh 0 3vh 0',
                }}
            >
                <h1 className="font-onest fw-700 fs-24 lh-28 text-color-dark ">
                    {capitalizeString(roomName)} photos
                </h1>
            </div>

            <GridContainer>
                {photos.map((photo) => (
                    <div
                        className="flex-col"
                        key={photo.id}
                        onClick={() => {
                            openFullSize(photo);
                        }}
                    >
                        <CardImageContainer>
                            <img src={photo.url} alt="Room" />
                        </CardImageContainer>
                        <div className="font-onest fw-500 fs-16 lh-26 text-center">
                            {formatDateStringISO(photo.takenAt)}
                        </div>
                    </div>
                ))}
            </GridContainer>

            <FullSizeImageModal
                show={selectedPhoto !== null}
                onClose={closeFullSize}
                photo={selectedPhoto}
            />
        </>
    );
};

export default RoomSingleView;

const FullSizeImageModal = ({ show, onClose, photo }) => {
    if (!photo) return null;

    return (
        <Modal show={show} onHide={onClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Full Size Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={photo.url} alt="Full size" style={{ width: '100%', height: 'auto' }} />
            </Modal.Body>
            <Modal.Footer>
                <div className="font-onest fw-500 fs-16 lh-26">
                    Taken on: {formatDateStringISO(photo.takenAt)}
                </div>
            </Modal.Footer>
        </Modal>
    );
};
