import React from 'react';
import BottomNav from '../components/BottomNav';
import { Outlet } from 'react-router-dom';

const containerStyles = {
    minHeight: '100vh',
    minHeight: '100svh',
    flexDirection: 'column',
    display: 'flex',
    padding: '3vh 8vw',
};

const Layout = () => {
    return (
        <div className="container" style={containerStyles}>
            <Outlet />

            <BottomNav />
        </div>
    );
};

export default Layout;
