import { ACTION_USER_CLEAR, ACTION_USER_SET, ACTION_USER_SET_ROLE } from '../constants';

const initialUserState = {
    currentRole: null,
    userData: {},
    isAuthenticated: false,
};

const userReducer = (state = initialUserState, action) => {
    switch (action.type) {
        case ACTION_USER_SET: {
            // console.log(action.payload)
            return {
                ...state,
                userData: action.payload.userData,
                isAuthenticated: true,
            };
        }
        case ACTION_USER_CLEAR: {
            return {
                ...state,
                userData: {},
                isAuthenticated: false,
            };
        }
        case ACTION_USER_SET_ROLE: {
            const role = action.payload.role;
            return {
                ...state,
                currentRole: role,
            };
        }
        default:
            return state;
    }
};

export default userReducer;
