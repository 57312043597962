import { useDispatch, useSelector } from 'react-redux';
import EasyRentorService from '../service/EasyRentorService';
import { clearUser, setUser } from '../actions/userActions';
import { useNavigate } from 'react-router-dom';
import { disconnectWebSocket } from '../actions/notificationActions';

export const useAuthAction = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const login = async () => {
        console.log('login hook');
        try {
            // get data from backend response
            const response = await EasyRentorService.getCurrentUser();
            console.log('[useAuthAction.login] Response', response);

            if (!response?.data) {
                throw new Error('[useAuthAction.login] No user data recieved');
            }

            const userData = response.data;
            console.log('[useAuthAction.login] User data', userData);

            // tokens are httpOnly so they are readonly values so we cannot access them

            dispatch(setUser(userData.user));
            console.log('[useAuthAction] userData set redux', userData);
            return userData;
        } catch (error) {
            console.error('Auth callback error', error);
            localStorage.removeItem('refreshToken');
            dispatch(clearUser());
            navigate('/login');
            throw error;
        }
    };

    const logout = async () => {
        console.log('logout hook');
        // Wait for the WebSocket to close
        // fuck it
        // await new Promise((resolve) => setTimeout(resolve, 1000));
        try {
            dispatch(disconnectWebSocket());
            await EasyRentorService.logoutUser();
            dispatch(clearUser());
            navigate('/login');
        } catch (error) {
            console.error('[LOGOUT] Error while logouting user', error);
            dispatch(clearUser());
            navigate('/login');
        }
    };

    return {
        login,
        logout,
        isAuthenticated: () => {
            const state = dispatch((state) => state.user);
            return !!state?.user;
        },
    };
};
