import styled from "styled-components";


export const StaticIcon = styled.i.withConfig({
    shouldForwardProp: props => !['size'].includes(props)
})`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({size}) => size ? `${size}rem` : 'auto'};
    height: ${({size}) => size ? `${size}rem` : 'auto'};
    margin: 0;
    padding: 0;

`