import Login from '../pages/Login';
import AddPropertyMultiStepForm from '../pages/properties/AddPropertyMultiStepForm';
import { HEADER_TYPES, ROLE_LANDLORD, ROLE_TENANT } from '../constants';
import PrivateRoute from '../pages/PrivateRoute';
import Layout from '../pages/Layout';
import ProfileView from '../pages/profile/ProfileView';
import PropertyListView from '../pages/properties/PropertyListView';
import PropertySingleView from '../pages/properties/PropertySingleView';
import ContractCreateForm from '../components/ContractCreateForm/ContractCreateForm';
import ContractListView from '../pages/contracts/ContractListView';
import ContractSingleView from '../pages/contracts/ContractSingleView';
import ContractSignView from '../pages/contracts/ContractSignView';
import ContractAddPhotosView from '../pages/contracts/ContractAddPhotosView';
import { Navigate } from 'react-router-dom';
import ReportsListManager from '../pages/reports/ReportsListManager';
import ReportSingleView from '../pages/reports/ReportSingleView';
import ProfileEditView from '../pages/profile/ProfileEditView';
import MainLayout from '../pages/MainLayout';
import ContractEditView from '../pages/contracts/ContractEditView';
import RoomList from 'pages/properties/RoomList';
import RoomSingleView from 'pages/properties/RoomSingleView';
import AuthCallback from 'pages/AuthCallback';
import NotFound from '@/pages/NotFound';

export const routes = [
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/auth/callback',
        element: <AuthCallback />,
    },
    {
        path: '/',
        element: <PrivateRoute element={<Navigate to="/profile" replace />} />,
    },
    {
        path: '',
        element: <Layout />,
        children: [
            {
                path: 'profile',
                element: <PrivateRoute element={<MainLayout />} />,
                children: [
                    {
                        path: '',
                        element: <ProfileView />,
                        header: {
                            visible: true,
                            type: HEADER_TYPES.EDIT,
                        },
                    },
                    {
                        path: 'edit',
                        element: <ProfileEditView />,
                        header: {
                            visible: true,
                            type: HEADER_TYPES.NAVIGATION,
                            fallbackRoute: -1,
                        },
                        bottom_navigation: false,
                    },
                ],
            },
            {
                path: 'properties',
                element: <MainLayout />,
                children: [
                    {
                        path: '',
                        element: (
                            <PrivateRoute
                                element={<PropertyListView />}
                                allowedRoles={[ROLE_LANDLORD, ROLE_TENANT]}
                            />
                        ),
                        header: { visible: true, type: HEADER_TYPES.PROFILE },
                    },
                    {
                        path: ':property_uuid',
                        element: (
                            <PrivateRoute
                                element={<PropertySingleView />}
                                allowedRoles={[ROLE_LANDLORD]}
                            />
                        ),
                        header: {
                            visible: true,
                            type: HEADER_TYPES.NAVIGATION,
                            fallbackRoute: '/properties',
                        },
                        bottom_navigation: false,
                    },
                    {
                        path: ':property_uuid/rooms',
                        element: (
                            <PrivateRoute element={<RoomList />} allowedRoles={[ROLE_LANDLORD]} />
                        ),
                        header: {
                            visible: true,
                            type: HEADER_TYPES.NAVIGATION,
                            fallbackRoute: -1,
                        },
                        bottom_navigation: false,
                    },
                    {
                        path: ':property_uuid/rooms/:room_uuid',
                        element: (
                            <PrivateRoute
                                element={<RoomSingleView />}
                                allowedRoles={[ROLE_LANDLORD]}
                            />
                        ),
                        header: {
                            visible: true,
                            type: HEADER_TYPES.NAVIGATION,
                            fallbackRoute: -1,
                        },
                        bottom_navigation: false,
                    },
                    {
                        path: ':property_uuid/create-contract',
                        element: (
                            <PrivateRoute
                                element={<ContractCreateForm />}
                                allowedRoles={[ROLE_LANDLORD]}
                            />
                        ),
                        header: {
                            visible: true,
                            type: HEADER_TYPES.NAVIGATION,
                            fallbackRoute: -1,
                        },
                        bottom_navigation: false,
                    },
                    {
                        path: 'create',
                        element: (
                            <PrivateRoute
                                element={<AddPropertyMultiStepForm />}
                                allowedRoles={[ROLE_LANDLORD]}
                            />
                        ),
                        header: { visible: false },
                        bottom_navigation: false,
                    },
                ],
            },
            {
                path: 'contracts',
                element: <MainLayout />,
                children: [
                    {
                        path: '',
                        element: (
                            <PrivateRoute
                                element={<ContractListView />}
                                allowedRoles={[ROLE_LANDLORD]}
                            />
                        ),
                        header: { visible: true, type: HEADER_TYPES.PROFILE },
                    },
                    {
                        path: ':contract_uuid',
                        element: (
                            <PrivateRoute
                                element={<ContractSingleView />}
                                allowedRoles={[ROLE_TENANT, ROLE_LANDLORD]}
                            />
                        ),
                        header: {
                            visible: true,
                            type: HEADER_TYPES.NAVIGATION,
                            fallbackRoute: -1,
                        },
                        bottom_navigation: false,
                    },
                    {
                        path: ':contract_uuid/edit',
                        element: (
                            <PrivateRoute
                                element={<ContractEditView />}
                                allowedRoles={[ROLE_LANDLORD]}
                            />
                        ),
                        header: {
                            visible: true,
                            type: HEADER_TYPES.NAVIGATION,
                            fallbackRoute: -1,
                        },
                        bottom_navigation: false,
                    },
                    {
                        path: 'sign',
                        element: (
                            <PrivateRoute
                                element={<ContractSignView />}
                                allowedRoles={[ROLE_TENANT]}
                            />
                        ),
                        header: {
                            visible: true,
                            type: HEADER_TYPES.NAVIGATION,
                            fallbackRoute: '/contracts',
                        },
                        bottom_navigation: false,
                    },
                    {
                        path: ':contract_uuid/add-photos',
                        element: (
                            <PrivateRoute
                                element={<ContractAddPhotosView />}
                                allowedRoles={[ROLE_TENANT]}
                            />
                        ),
                        header: {
                            visible: true,
                            type: HEADER_TYPES.NAVIGATION,
                            fallbackRoute: -1,
                        },
                        bottom_navigation: false,
                    },
                ],
            },
            {
                path: 'reports',
                element: (
                    <PrivateRoute
                        element={<MainLayout />}
                        allowedRoles={[ROLE_TENANT, ROLE_LANDLORD]}
                    />
                ),
                children: [
                    {
                        path: '',
                        element: <ReportsListManager />,
                        header: { visible: true, type: HEADER_TYPES.PROFILE },
                    },
                    {
                        path: ':report_uuid',
                        element: <ReportSingleView />,
                        header: {
                            visible: true,
                            type: HEADER_TYPES.NAVIGATION,
                            fallbackRoute: -1,
                        },
                        bottom_navigation: false,
                    },
                ],
            },
            {
                path: '*',
                element: <MainLayout />,
                children: [
                    {
                        path: '*',
                        element: <NotFound />,
                        header: {
                            visible: true,
                            type: HEADER_TYPES.NAVIGATION,
                            fallbackRoute: -1,
                        },
                        bottom_navigation: false,
                    },
                ],
            },
        ],
    },
];
