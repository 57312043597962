import React from 'react'
import { StyledDiv } from './ElevationWrapper.styles'

const ElevationWrapper = ({fullWidth, children, style, ...props}) => {
    return (
        <StyledDiv {...props} style={{...style}} fullWidth={fullWidth}>
            {children}
        </StyledDiv>
    )
}

export default ElevationWrapper
