import React, { ButtonHTMLAttributes } from 'react';

export interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    fullWidth: boolean;
    children: React.ReactNode;
    variant: CustomButtonVariants;
}

export enum CustomButtonVariants {
    DARK = 'dark',
    LIGHT = 'light',
}
