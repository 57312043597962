import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ element, allowedRoles }) => {
    const { currentRole, isAuthenticated } = useSelector((state) => state.users);
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (allowedRoles && !allowedRoles.includes(currentRole)) {
        return <Navigate to={'/profile'} state={{ from: location }} replace />;
    }

    return element;
};

export default PrivateRoute;
