// S3UploadService.js
import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY_SECRET,
    region: process.env.REACT_APP_AWS_REGION,
});

const s3 = new AWS.S3();

const BUCKET_NAME = 'uploads.easyrentor.com';

const isValidS3URL = (url) => {
    try {
        const parsedUrl = new URL(url);
        return (
            parsedUrl.hostname.includes('s3') ||
            parsedUrl.hostname.includes('amazonaws.com') ||
            parsedUrl.hostname.includes(BUCKET_NAME)
        );
    } catch {
        return false;
    }
};

export const getKeyFromUrl = (url) => {
    try {
        const parsedUrl = new URL(url);
        // Remove the leading slash and the bucket name from the pathname
        const fullPath = parsedUrl.pathname.substr(1);
        const pathParts = fullPath.split('/');
        // Remove the bucket name (first part) and join the rest
        console.log(`getKetFromUrl (${url}) --> ${pathParts.slice(1).join('/')}`);
        return pathParts.slice(1).join('/');
    } catch (error) {
        console.error('Error parsing S3 URL:', error);
        throw error;
    }
};

export const uploadToS3 = async (file, key) => {
    const params = {
        Bucket: BUCKET_NAME,
        Key: key,
        Body: file,
    };

    try {
        const data = await s3.upload(params).promise();
        return data.Location; // This is the URL of the uploaded file
    } catch (error) {
        console.error('Error uploading to S3:', error);
        throw error;
    }
};

export const readFromS3Base64 = async (url) => {
    try {
        const key = getKeyFromUrl(url);
        const params = {
            Bucket: BUCKET_NAME,
            Key: key,
            Expires: 3600,
        };

        const signedUrl = await s3.getSignedUrlPromise('getObject', params);
        // console.log('SIGNED URL', signedUrl);

        const response = await fetch(signedUrl);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
        });
    } catch (error) {
        console.error('Error reading from S3:', error);
        throw error;
    }
};

export const deleteFromS3 = async (url) => {
    if (!url || !isValidS3URL(url)) {
        console.warn('Invalid or emty S3 url: ', url);
        return;
    }

    try {
        const key = getKeyFromUrl(url);
        const params = {
            Bucket: BUCKET_NAME,
            Key: key,
        };

        try {
            await s3.headObject(params).promise();
        } catch (error) {
            if (error.code === 'NotFound') {
                console.warn(`File ${key} does not exist!`);
                return;
            }
            throw error;
        }

        await s3.deleteObject(params).promise();
        console.log(`File ${key} deleted successfully!`);
    } catch (error) {
        console.error('Error deleting file from S3:', error);
        throw error;
    }
};

export const deleteBulkFromS3 = async (urls) => {
    if (!Array.isArray(urls)) {
        throw new Error('URLs must be provided as an array!');
    }

    const validURLs = urls.filter((url) => url && isValidS3URL(url));
    if (validURLs.length === 0) {
        console.warn('No valid S3 URLs to delete');
        return;
    }

    const deleteObjects = validURLs.map((url) => ({
        Key: getKeyFromUrl(url),
    }));

    const params = {
        Bucket: BUCKET_NAME,
        Delete: {
            Objects: deleteObjects,
            Quiet: false,
        },
    };

    try {
        const response = await s3.deleteObjects(params).promise();
        // const response = { Deleted: true };

        // Log successful deletions
        if (response.Deleted) {
            console.log(`Successfully deleted ${response.Deleted.length} files from S3`);
        }

        // Log any errors
        if (response.Errors && response.Errors.length > 0) {
            console.error('Some files failed to delete from S3:', response.Errors);
        }

        return response;
    } catch (error) {
        console.error('Error in S3 bulk delete operation:', error);
        throw error;
    }
};
