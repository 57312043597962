import { setRole } from 'actions/userActions';
import { ROLE_TENANT, USER_ROLES } from '../constants';
import { useAuthAction } from 'hooks/useAuthAction';
import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const isValidRole = (role) => {
    return USER_ROLES.toArray().includes(role);
};

const determineUserRoles = (userRoles = []) => {
    if (!Array.isArray(userRoles) || userRoles.length === 0) {
        console.error('User has no roles!');
        return null;
    }

    // Filter only valid roles (TENANT, LANDLORd)
    const validRoles = userRoles.filter(isValidRole);

    // No valid roles
    if (validRoles.length === 0) {
        console.error('User has no valid roles!');
        return null;
    }

    // One role
    if (validRoles.length === 1) {
        return validRoles[0];
    }

    // Multiple roles (prioritize TENANT)
    return validRoles.includes(USER_ROLES.TENANT) ? USER_ROLES.TENANT : USER_ROLES.LANDLORD;
};

const AuthCallback = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { login } = useAuthAction();

    useEffect(() => {
        const handleCallback = async () => {
            try {
                const { profileComplete, user } = await login();

                // Case 1: New user (not in database) or imcomplete profile (no records for TENANT or LANDLORD)
                if (!profileComplete) {
                    return navigate('/profile/edit', {
                        state: {
                            role: ROLE_TENANT,
                            creating: true,
                        },
                    });
                }

                // Case 2: User is existing, validating them
                const primaryRole = determineUserRoles(user.roles);

                if (primaryRole === null) {
                    console.error('[AuthCallback] Roles not valid or empty: ', {
                        recievedRoles: user.roles,
                        allowedRoles: USER_ROLES.toArray(),
                    });
                    return navigate('/profile/edit', {
                        state: {
                            role: ROLE_TENANT,
                            creating: true,
                        },
                    });
                }

                dispatch(setRole(primaryRole));
                navigate('/profile');
            } catch (error) {
                console.error('[AuthCallback] Auth callback error:', error);
                navigate('/login');
            }
        };
        handleCallback();
    }, []);

    return (
        <div
            style={{
                position: 'relative',
                width: '10rem',
                height: '10rem',
                margin: 'auto auto',
            }}
        >
            <Spinner
                animation="border"
                role="status"
                style={{
                    color: 'var(--primary-color)',
                }}
            />
        </div>
    );
};

export default AuthCallback;
