import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Header } from '../../components/PropertyForms/PropertyForms.styles';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ElevationWrapper from '../../components/UI/wrappers/ElevationWrapper/ElevationWrapper';
import Wrapper from '../../components/UI/wrappers/Wrapper/Wrapper';
import { useFetching } from '../../hooks/useFetching';
import EasyRentorService from '../../service/EasyRentorService';
import { formatDateString, roundToTwoDecimals } from '../../utils/utils';
import { Badge, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Form, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { CONTRACT_STATUS } from '../../constants';
import { usePostSingle } from '../../hooks/usePostSingle';
import SubmitStatusModal from '../../components/SubmitStatusModal';
import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays';
import { differenceInYears } from 'date-fns';
import CustomButton from '@/components/UI/buttons/CustomButton';
import CustomCheckbox from '@/components/UI/inputs/CustomCheckbox';

const initialValues = {
    agreedTerms: false,
};

const ContractSignView = () => {
    const userData = useSelector((state) => state.users.userData);

    const location = useLocation();
    const [signContract, setSignContract] = useState(null);
    useEffect(() => {
        if (location.state && location.state.invitationContract) {
            setSignContract(location.state.invitationContract);
        }
    }, [location.state]);

    const [property, setProperty] = useState(null);
    const [landlord, setLandlord] = useState(null);
    const [fetchProperty, loadingProperty, errorProperty] = useFetching(async (id) => {
        const response = await EasyRentorService.getPropertyDTOByID(id);
        setProperty(response.data);
    });
    const [fetchLandlord, loadingLandlord, errorLandlord] = useFetching(async (id) => {
        const response = await EasyRentorService.getUserByID(id);
        setLandlord(response.data);
    });
    useEffect(() => {
        if (signContract?.propertyId && signContract?.landlordId) {
            fetchProperty(signContract.propertyId);
            fetchLandlord(signContract.landlordId);
        }
    }, [signContract]);

    const isTermless = useMemo(() => {
        return differenceInYears(signContract?.endDate, signContract?.startDate) === 50;
    }, [signContract]);

    const daysStaying = useMemo(() => {
        return differenceInCalendarDays(signContract?.endDate, signContract?.startDate);
    }, [signContract]);

    const daysStayingTotal = useMemo(() => {
        return isTermless
            ? roundToTwoDecimals(30 * signContract?.finalCostDaily)
            : roundToTwoDecimals(daysStaying * signContract?.finalCostDaily);
    }, [daysStaying, signContract, isTermless]);

    const totalPrice = useMemo(() => {
        const total =
            daysStayingTotal +
            property?.contractTemplateSimplified?.cleaningFee +
            property?.contractTemplateSimplified?.securityDepositAmount;
        return roundToTwoDecimals(total);
    }, [daysStayingTotal, property]);

    // FORM
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            agreedTerms: Yup.boolean(),
        });
    }, []);

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [submitSingle, loading, error, response, submitStatus] = usePostSingle();

    const getSubmissionSteps = () => {
        console.log('getSubmissionSteps', signContract);
        return [
            {
                type: 'CONTRACT',
                submit: EasyRentorService.submitContract,
                prepare: () => {
                    return {
                        ...signContract,
                        tenantId: userData.id,
                        signedByTenant: true,
                        signedAt: new Date(),
                        status: CONTRACT_STATUS.ACTIVE,
                    };
                },
            },
        ];
    };

    const submitSequentially = async (steps, initialResults = {}) => {
        let results = { ...initialResults };
        for (const step of steps) {
            const data = step.prepare();
            console.log(`prepared data`);
            if (step.shouldSubmit && !step.shouldSubmit(data)) {
                // So we still have access to field values of this object for next steps submissions.
                // See: contractTemplate
                results[step.type] = data;
                continue;
            }
            try {
                if (step.isArray) {
                    results[step.type] = await Promise.all(
                        data.map((item) => submitSingle(step.submit, item))
                    );
                } else {
                    results[step.type] = await submitSingle(step.submit, data);
                }
                console.log(`Submitted ${step.type}:`, results[step.type]);
            } catch (error) {
                console.error(`Error submitting ${step.type}:`, error);
                throw error;
            }
        }
        return results;
    };

    const onSignContract = useCallback(
        async (values, { setFieldError, setSubmitting }) => {
            // console.log(values)
            if (values.agreedTerms) {
                console.log('start submission');
                setShowModal(true);
                try {
                    const results = await submitSequentially(getSubmissionSteps());
                    // Modal doesnt have enought time to get rendered and then close and call onClose()
                    // so we add 2 seconds of artificial time...
                    // TODO: this is super pizdec
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                    console.log('All data submitted successfully:', results);
                } catch (error) {
                    console.error('Error in submission process:', error);
                } finally {
                    setShowModal(false);
                }
            } else {
                setFieldError('agreedTerms', 'You must agree to terms!');
                setSubmitting(false);
            }
            setSubmitting(false);
        },
        [submitSingle, signContract]
    );

    const handleModalExited = useCallback(() => {
        console.log('handleModalExited');
        navigate('/properties');
    }, [navigate]);

    if (!location.state || !location.state.invitationContract) {
        return <Navigate to={'/properties'} replace />;
    }

    return (
        <>
            <SubmitStatusModal show={showModal} onClose={handleModalExited} />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '3vh 0 3vh 0',
                }}
            >
                <Header> You have been invited to property!</Header>
            </div>
            <h4 className="mb-3">Contract details:</h4>
            <ElevationWrapper
                fullWidth={true}
                className="flex-col"
                style={{ gap: 'min(2vw, 1rem)', minHeight: '430px' }}
            >
                {loadingProperty ? (
                    <Spinner
                        animation="border"
                        role="status"
                        style={{ height: '15rem', width: '15rem', margin: 'auto auto' }}
                    />
                ) : (
                    <>
                        <div className="flex-row align-items-baseline">
                            <span className="font-onest fw-400 fs-16 lh-24">
                                {signContract?.discountPercent > 0 && (
                                    <span className="fs-18 text-color-grey">
                                        <s>{roundToTwoDecimals(property?.rentCostDaily)}&#8364; </s>
                                    </span>
                                )}
                                <span className="fw-700 fs-18">
                                    {roundToTwoDecimals(signContract?.finalCostDaily)}&#8364;
                                </span>
                                <span>/night </span>
                                {signContract?.discountPercent > 0 && (
                                    <Badge bg="dark">
                                        discount {signContract?.discountPercent}%
                                    </Badge>
                                )}
                            </span>
                        </div>

                        <Wrapper className="flex-col p-0">
                            <div className="flex-row justify-content-between">
                                <div className="flex-col w-100 py-2 px-3">
                                    <span className="fw-600"> CHECK-IN </span>
                                    <span>
                                        {signContract && formatDateString(signContract.startDate)}{' '}
                                    </span>
                                </div>
                                <div
                                    className="flex-col w-100 py-2 px-3"
                                    style={{ borderLeft: '1px solid rgba(230, 230, 230, 0.7' }}
                                >
                                    <span className="fw-600"> CHECK-OUT </span>
                                    {isTermless ? (
                                        <span> TERMLESS </span>
                                    ) : (
                                        <span>
                                            {signContract && formatDateString(signContract.endDate)}{' '}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Wrapper>
                        <Wrapper className="flex-col">
                            <div className="flex-row align-items-start">
                                <span className="fw-600 pe-2"> Property name:</span>
                                <span> {property?.name} </span>
                            </div>
                            <div className="flex-row align-items-start">
                                <span className="fw-600 pe-2"> Landlord:</span>
                                <span>
                                    {' '}
                                    {landlord?.firstName} {landlord?.lastName}
                                </span>
                            </div>
                            <div className="flex-row align-items-start">
                                <span className="fw-600 pe-2"> Address:</span>
                                <span>
                                    {' '}
                                    {property?.address?.city}, {property?.address?.street}{' '}
                                    {property?.address?.streetNumber}
                                </span>
                            </div>
                            <div className="flex-row align-items-start">
                                <span className="fw-600 pe-2"> Max guests amount: </span>
                                <span> {signContract?.guestAmount} guests </span>
                            </div>
                        </Wrapper>

                        <div className="flex-col py-2 px-3">
                            <div className="flex-row justify-content-between">
                                {isTermless ? (
                                    <span>
                                        Price per month (30 days &#10005;{' '}
                                        {roundToTwoDecimals(signContract?.finalCostDaily)}&#8364;)
                                    </span>
                                ) : (
                                    <span>
                                        {roundToTwoDecimals(signContract?.finalCostDaily)}
                                        &#8364; &#10005; {daysStaying} night(s){' '}
                                    </span>
                                )}
                                <span> {daysStayingTotal}&#8364; </span>
                            </div>
                            <div className="flex-row justify-content-between">
                                <span> Cleaning fee </span>
                                <span>
                                    {' '}
                                    {property?.contractTemplateSimplified?.cleaningFee}&#8364;{' '}
                                </span>
                            </div>
                            <div className="flex-row justify-content-between">
                                <span> Security deposit </span>
                                <span>
                                    {' '}
                                    {property?.contractTemplateSimplified?.securityDepositAmount}
                                    &#8364;{' '}
                                </span>
                            </div>
                            <hr />
                            <div className="flex-row justify-content-between">
                                <span> Total </span>
                                <span> {totalPrice}&#8364; </span>
                            </div>
                        </div>
                    </>
                )}
            </ElevationWrapper>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSignContract}
            >
                {({ isSubmitting }) => {
                    // console.log("isSubmitting", isSubmitting)
                    return (
                        <Form className="flex-col justify-content-center p-3 mt-5">
                            <div className="flex-row justify-content-center mb-4">
                                <CustomCheckbox
                                    id="agreedTerms"
                                    name="agreedTerms"
                                    label={
                                        <>
                                            <span className="pe-3">
                                                I have read{' '}
                                                <a
                                                    href="https://s3.eu-north-1.amazonaws.com/legal.easyrentor.com/EasyRentor_agreement_draft_v3.pdf"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    full contract details
                                                </a>
                                            </span>
                                        </>
                                    }
                                />
                            </div>
                            <CustomButton
                                type="submit"
                                fullWidth={false}
                                disabled={isSubmitting || !signContract}
                            >
                                Sign
                            </CustomButton>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default ContractSignView;
