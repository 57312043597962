import React, { FC } from 'react';
import {
    CustomButtonProps,
    CustomButtonVariants,
} from '@/components/UI/buttons/CustomButton/types';
import styles from './CustomButton.module.css';
import clsx from 'clsx';

const CustomButton: FC<CustomButtonProps> = ({
    children,
    onClick,
    fullWidth = true,
    variant = CustomButtonVariants.DARK,
    className,
    ...props
}) => {
    return (
        <button
            className={clsx(
                styles.button,
                styles[variant],
                fullWidth && styles.fullWidth,
                className
            )}
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    );
};

export default React.memo(CustomButton);
