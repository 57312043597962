import { useField } from 'formik';
import React, { FC, useState } from 'react';
import styles from './TextArea.module.css';
import { TextAreaProps } from '@/components/UI/inputs/TextArea/types';
import clsx from 'clsx';

const TextArea: FC<TextAreaProps> = ({ label, fullWidth = false, className, ...props }) => {
    const [field, meta] = useField(props.name);

    const [focus, setFocus] = useState(false);

    const handleFocus = () => {
        setFocus(true);
    };

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        setFocus(false);
        field.onBlur(e);
    };

    return (
        <div className={clsx(styles.container, fullWidth && styles.fullWidth)}>
            {label && (
                <label htmlFor={props.id || props.name} className={styles.label}>
                    {label}
                </label>
            )}
            <textarea
                {...field}
                {...props}
                value={field.value || ''}
                className={clsx(styles.textarea, props.disabled && styles.disabled, className)}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
        </div>
    );
};

export default TextArea;
