import SubmitStatusModal from 'components/SubmitStatusModal';
import CustomButton from '@/components/UI/buttons/CustomButton';
import TextInput from '@/components/UI/inputs/TextInput/TextInput';
import { CONTRACT_STATUS } from '../../constants';
import { differenceInYears, format, parseISO } from 'date-fns';
import { Form, Formik } from 'formik';
import { useFetching } from 'hooks/useFetching';
import { usePostSingle } from 'hooks/usePostSingle';
import { useValidateParamsUUID } from 'hooks/useValidateParamsUUID';
import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import EasyRentorService from 'service/EasyRentorService';
import { calcDiscountPrice } from 'utils/utils';
import * as Yup from 'yup';
import RoundButton from '@/components/UI/buttons/RoundButton';
import { InfoLg } from 'react-bootstrap-icons';
import TriggerableModal from 'components/UI/modals/TriggerableModal';
import CustomCheckbox from '@/components/UI/inputs/CustomCheckbox';
import CustomDatePicker from '@/components/UI/inputs/DatePicker';
import CustomRangeField from '@/components/UI/inputs/RangeField';

const ContractEditView = () => {
    const navigate = useNavigate();
    const { contract_uuid } = useParams();
    useValidateParamsUUID(contract_uuid, '/contracts');

    const currentRole = useSelector((state) => state.users.currentRole);
    const [contract, setContract] = useState(null);
    const [fetchContract, loadingContract, errorContract] = useFetching(async (id, showFor) => {
        const response = await EasyRentorService.getContractByIDandRole(id, showFor);
        // console.log(response);
        setContract(response.data);
    });

    useEffect(() => {
        fetchContract(contract_uuid, currentRole);
    }, [contract_uuid, currentRole]);

    const initialValues = useMemo(
        () => ({
            date: {
                startDate: contract?.startDate || null,
                endDate: contract?.endDate || null,
            },
            termlessContract: differenceInYears(contract?.endDate, contract?.startDate) === 50,
            guestAmount: contract?.guestAmount || 1,
            discountPercent: contract?.discountPercent || '',
            finalCostDaily: contract?.finalCostDaily || '',
            finalCostMonthly: contract?.finalCostMonthly || '',
            allowEarlyAssetUpload: contract?.allowEarlyAssetUpload,
        }),
        [
            contract?.startDate,
            contract?.endDate,
            contract?.guestAmount,
            contract?.discountPercent,
            contract?.finalCostDaily,
            contract?.finalCostMonthly,
            contract?.allowEarlyAssetUpload,
        ]
    );

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            date: Yup.object().shape({
                startDate: Yup.date().required('Start date is required'),
                endDate: Yup.date()
                    .required('End date is required')
                    .min(Yup.ref('startDate'), 'End date must be after start date'),
            }),
            termlessContract: Yup.boolean(),
            guestAmount: Yup.number()
                .required('guestAmount is required!')
                .min(1, 'Must be at least 1')
                .max(
                    contract?.property.maxGuestAmount || 5,
                    `Cannot exceed ${contract?.property.maxGuestAmount || 'loading...'}`
                )
                .integer('Must be a whole number!'),
            discountPercent: Yup.number()
                .typeError('Must be a number!')
                .required('discountPercent is required!')
                .min(0, 'Must be greater or equal to 0!')
                .max(99, 'You cannot be so generous!')
                .integer('Must be number!'),
            finalCostDaily: Yup.number()
                .typeError('Must be a number!')
                .required('finalCostDaily is required!'),
            finalCostMonthly: Yup.number()
                .typeError('Must be a number!')
                .required('finalCostMonthly is required!'),
            allowEarlyAssetUpload: Yup.boolean(),
        });
    }, [contract?.property.maxGuestAmount]);

    const handleTermlessChange = (e, setFieldValue) => {
        const isChecked = e.target.checked;
        setFieldValue('termlessContract', isChecked);
        if (isChecked) {
            const startDate = new Date();
            const endDate = new Date(startDate);
            endDate.setFullYear(endDate.getFullYear() + 50);
            setFieldValue('date.startDate', startDate);
            setFieldValue('date.endDate', endDate);
        } else {
            setFieldValue('date.startDate', null);
            setFieldValue('date.endDate', null);
        }
    };

    const handleCalcFinalPrice = (e, values, setFieldValue) => {
        const discount = e.target.value;
        // const clampDiscount = Math.max(0, Math.min(discount, 99));
        setFieldValue('discountPercent', discount);
        if (discount) {
            const finalCostDaily = calcDiscountPrice(discount, contract.property.rentCostDaily);
            setFieldValue('finalCostDaily', finalCostDaily);
            const finalCostMonthly = calcDiscountPrice(discount, contract.property.rentCostMonthly);
            setFieldValue('finalCostMonthly', finalCostMonthly);
        } else {
            setFieldValue('finalCostDaily', values.finalCostDaily);
            setFieldValue('finalCostMonthly', values.finalCostMonthly);
        }
    };

    const [showModal, setShowModal] = useState(false);
    const [submitSingle, loading, error, response, submitStatus] = usePostSingle();

    const submissionSteps = [
        {
            type: 'CONTRACT',
            submit: EasyRentorService.submitContract,
            prepare: (prevData) => {
                const { property, address, user, ...contractData } = contract;
                return {
                    ...contractData,
                    discountPercent: parseFloat(prevData.INIT_FORM.discountPercent),
                    finalCostDaily: parseFloat(prevData.INIT_FORM.finalCostDaily),
                    finalCostMonthly: parseFloat(prevData.INIT_FORM.finalCostMonthly),
                    guestAmount: parseInt(prevData.INIT_FORM.guestAmount),
                    allowEarlyAssetUpload: prevData.INIT_FORM.allowEarlyAssetUpload,
                };
            },
        },
    ];

    const submitSequentially = async (steps, initialResults = {}) => {
        let results = { ...initialResults };
        for (const step of steps) {
            const data = step.prepare(results);
            console.log(`prepared type: ${step.type}, data:`, data);
            try {
                if (step.isArray) {
                    results[step.type] = await Promise.all(
                        data.map((item) => submitSingle(step.submit, item))
                    );
                } else {
                    results[step.type] = await submitSingle(step.submit, data);
                }
                console.log(`Submitted ${step.type}:`, results[step.type]);
            } catch (error) {
                console.error(`Error submitting ${step.type}:`, error);
                throw error;
            }
        }
        return results;
    };

    const onSubmitContract = async (values) => {
        setShowModal(true);
        try {
            const results = await submitSequentially(submissionSteps, { INIT_FORM: values });
            // Hello from this: src\components\ContractCreateForm\ContractCreateForm.jsx
            await new Promise((resolve) => setTimeout(resolve, 2000));
            console.log('All data submitted successfully:', results);
        } catch (error) {
            console.error('Error in submission process:', error);
        } finally {
            setShowModal(false);
        }
    };

    const handleModalExited = () => {
        console.log('handleModalExited');
        navigate(`/contracts/${contract_uuid}`);
    };

    const isReadOnly = useMemo(() => {
        if (!contract) return false;
        return !(
            contract.status === CONTRACT_STATUS.WAITING_FOR_SIGNATURES ||
            contract.status === CONTRACT_STATUS.WAITING_FOR_START_DATE
        );
    }, [contract?.status]);

    if (errorContract) {
        return (
            <>
                <h1 className="font-onest fw-700 fs-24 lh-28 text-color-dark mb-0">
                    Error loading page
                </h1>
                <p className="p-3 text-center">
                    {errorContract.message || 'An unknown error occured!'}
                </p>
                <CustomButton className="mt-auto" onClick={() => navigate('/contracts')}>
                    Back to Contracts List
                </CustomButton>
            </>
        );
    }

    if (loadingContract) {
        return (
            <Spinner
                animation="border"
                role="status"
                style={{
                    width: '10rem',
                    height: '10rem',
                    margin: 'auto auto',
                }}
            />
        );
    }

    return (
        <>
            <SubmitStatusModal show={showModal} onClose={handleModalExited} />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    margin: '3vh 0 3vh 0',
                }}
            >
                <h1 className="font-onest fw-700 fs-24 lh-28 text-color-dark mb-0 me-2">
                    Contract edit
                </h1>
                {isReadOnly && (
                    <TriggerableModal
                        trigger={
                            <div className="flex-row align-items-stretch font-onest fw-500 fs-16 text-color-dark mb-0">
                                (Read only
                                <RoundButton type="button" size={1.2}>
                                    <InfoLg size={24} color="#7C7C7C" />
                                </RoundButton>
                                )
                            </div>
                        }
                        header={
                            <h1 className="font-onest fw-700 fs-24 lh-28 text-color-dark mb-0">
                                What does <i>Read only</i> mean?
                            </h1>
                        }
                        body={
                            <p>
                                This contract involves <b>signature from a Tenant</b>, and/or is{' '}
                                <b>Active</b>, therefore <u>edit is limited</u>. You can allow early
                                photo upload by Tenant regardless. Cancel contract to make be able
                                to make more changes.
                            </p>
                        }
                    />
                )}
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitContract}
            >
                {({ isSubmitting, setFieldValue, values, resetForm }) => {
                    return (
                        <Form className="flex-col justify-content-between flex-grow-1">
                            <div className="flex-col" style={{ gap: 'min(2vw, 1rem)' }}>
                                <CustomCheckbox
                                    label={'Termless contract'}
                                    id="termlessContract"
                                    name="termlessContract"
                                    onChange={(e) => {
                                        handleTermlessChange(e, setFieldValue);
                                    }}
                                    disabled={isReadOnly}
                                />
                                <div className="flex-col">
                                    <label
                                        htmlFor="date"
                                        className="fw-500 fs-16 lh-26 text-color-black"
                                    >
                                        Select dates
                                    </label>
                                    <div
                                        className="flex-row align-items-stretch justify-content-between"
                                        style={{ gap: '1rem' }}
                                    >
                                        <CustomDatePicker
                                            id="date.startDate"
                                            name="date.startDate"
                                            placeholderText="Select start date"
                                            fullWidth={true}
                                            disabled={isReadOnly || values.termlessContract}
                                            minDate={new Date()}
                                        />
                                        {!values.termlessContract && (
                                            <CustomDatePicker
                                                id="date.endDate"
                                                name="date.endDate"
                                                placeholderText="Select end date"
                                                fullWidth={true}
                                                minDate={values.date.startDate}
                                                disabled={
                                                    isReadOnly ||
                                                    values.termlessContract ||
                                                    !values.date.startDate
                                                }
                                            />
                                        )}
                                    </div>
                                </div>

                                <CustomRangeField
                                    label={`Guest amount (max ${
                                        contract?.property.maxGuestAmount || 'loading...'
                                    })`}
                                    id="guestAmount"
                                    name="guestAmount"
                                    min={1}
                                    max={contract?.property.maxGuestAmount || 5}
                                    disabled={isReadOnly}
                                />

                                <TextInput
                                    label={'Discount (% max 99)'}
                                    id="discountPercent"
                                    name="discountPercent"
                                    onChange={(e) => {
                                        handleCalcFinalPrice(e, values, setFieldValue);
                                    }}
                                    disabled={isReadOnly}
                                />

                                <TextInput
                                    label={'Final cost daily'}
                                    id="finalCostDaily"
                                    name="finalCostDaily"
                                    disabled={true}
                                />
                                <TextInput
                                    label={'Final cost monthly'}
                                    id="finalCostMonthly"
                                    name="finalCostMonthly"
                                    disabled={true}
                                />
                                <CustomCheckbox
                                    label={'Allow early asset upload'}
                                    id="allowEarlyAssetUpload"
                                    name="allowEarlyAssetUpload"
                                />
                            </div>
                            <div className="flex-col">
                                <CustomButton
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={resetForm}
                                >
                                    Reset
                                </CustomButton>
                                <CustomButton
                                    type="submit"
                                    style={{ marginTop: '0.5rem' }}
                                    disabled={isSubmitting}
                                >
                                    Save
                                </CustomButton>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default ContractEditView;
