import { useField, useFormikContext } from 'formik';
import { FC } from 'react';
import styles from './ButtonCheckbox.module.css';
import { ButtonCheckboxProps } from '@/components/UI/inputs/ButtonCheckbox/types';
import clsx from 'clsx';

const ButtonCheckbox: FC<ButtonCheckboxProps> = ({
    name,
    fullWidth = false,
    className,
    ...props
}) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(name);

    return (
        <button
            type="button"
            onClick={() => setFieldValue(name, !field.value)}
            className={clsx(
                styles.button,
                field.value ? styles.checked : styles.unchecked,
                fullWidth && styles.fullWidth,
                className
            )}
            {...props}
        >
            {field.value ? 'Approved' : 'Not approved'}
        </button>
    );
};

export default ButtonCheckbox;
