import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import { debounce } from 'lodash';
import { SearchIcon } from '../UI/icons';
import TextInput from '@/components/UI/inputs/TextInput';

const Search = ({ onSearch, placeholder }) => {
    const debouncedSearch = useCallback(
        debounce((searchTerm) => {
            onSearch(searchTerm);
        }, 300),
        [onSearch]
    );

    const handleSearchChange = useCallback(
        (e) => {
            const searchTerm = e.target.value;
            debouncedSearch(searchTerm);
        },
        [debouncedSearch]
    );

    return (
        <Formik initialValues={{ search: '' }} onSubmit={() => {}}>
            {({ setFieldValue }) => (
                <Form className="w-100">
                    <TextInput
                        id="search"
                        name="search"
                        placeholder={placeholder || 'Search placeholder'}
                        icon={<SearchIcon />}
                        rounded={true}
                        fullWidth={true}
                        onChange={(e) => {
                            setFieldValue('search', e.target.value);
                            handleSearchChange(e);
                        }}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default Search;
