import React, { useMemo } from 'react';
import { CardWrapper, CardInfoContainer, CardInfoRow } from '../PropertyCard/PropertyCard.styles';
import { LocationIcon, RoomsBedIcon } from '../UI/icons';
import defaultRoomImageSrc from '../../assets/images/default-room.png';
import { useNavigate } from 'react-router-dom';
import { formatDateString, roundToTwoDecimals } from '../../utils/utils';
import { Calendar3, GeoAltFill } from 'react-bootstrap-icons';
import { differenceInYears } from 'date-fns';

const ContractCardExtended = ({ contractInfo }) => {
    const navigate = useNavigate();

    const handleSingleView = () => {
        console.log('SINGLE CONTRACT VIEW', contractInfo.id);
        navigate(`/contracts/${contractInfo.id}`);
    };

    const isTermless = useMemo(() => {
        return differenceInYears(contractInfo?.endDate, contractInfo?.startDate) === 50;
    }, [contractInfo]);

    return (
        <div onClick={handleSingleView} style={{ cursor: 'pointer' }}>
            <div
                className="card border-0 h-100"
                style={{
                    borderRadius: '1vh',
                    boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.07)',
                    transition: 'box-shadow 0.3s ease',
                }}
            >
                <div className="row g-0 h-100">
                    <div
                        className="col-4 position-relative"
                        style={{
                            aspectRatio: '1/1',
                        }}
                    >
                        <img
                            src={contractInfo.property?.previewPhotoUrl || defaultRoomImageSrc}
                            alt="Property preview"
                            className="h-100 w-100"
                            style={{
                                objectFit: 'cover',
                                borderTopLeftRadius: '1vh',
                                borderBottomLeftRadius: '1vh',
                            }}
                        />
                    </div>
                    <div className="col-8">
                        <div className="card-body d-flex flex-column justify-content-between h-100">
                            <h4 className="fw-700">{contractInfo.property.name}</h4>
                            <div className="mb-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div style={{ width: '20px' }}>
                                        <GeoAltFill size={15} color="#838383" />
                                    </div>
                                    <span className="font-poppins fw-400 fs-14 lh-24 text-color-grey">
                                        {contractInfo.address.city}, {contractInfo.address.street}{' '}
                                        {contractInfo.address.streetNumber}
                                    </span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div style={{ width: '20px' }}>
                                        <Calendar3 size={15} color="#838383" />
                                    </div>
                                    <span className="font-poppins fw-400 fs-14 lh-24 text-color-grey">
                                        {isTermless ? (
                                            'TERMLESS'
                                        ) : (
                                            <>
                                                {formatDateString(contractInfo.startDate)}
                                                <span> &mdash; </span>
                                                {formatDateString(contractInfo.endDate)}
                                            </>
                                        )}
                                    </span>
                                </div>
                            </div>

                            <div className="font-poppins fw-400 fs-14 lh-24 text-color-grey">
                                <span className="fw-600 fs-20 lh-28 text-color-blue">
                                    {roundToTwoDecimals(contractInfo.finalCostDaily)}&#8364;
                                </span>
                                /per day
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContractCardExtended;
