import React from 'react';
import { Modal } from 'react-bootstrap';
import CopyableContent from '../UI/text/CopyableContent/CopyableContent';
import CustomButton from '@/components/UI/buttons/CustomButton';

const ContractInviteModal = ({ show, newContractID, onClose }) => {
    return (
        <Modal show={show} centered={true} backdrop="static" onHide={onClose}>
            <Modal.Header>
                <h3> Your contract has been created successfully! </h3>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center align-items-center">
                <div className="flex-col justify-content-center align-items-center">
                    <div style={{ padding: '3rem' }}>
                        <p className="text-center">
                            To invite tenants, copy the contract code below and share it with
                            tenant, you want to invite.
                        </p>
                    </div>
                    <CopyableContent content={newContractID} />
                </div>
            </Modal.Body>
            <Modal.Footer style={{ alignItems: 'center' }}>
                <CustomButton onClick={onClose}>Close</CustomButton>
            </Modal.Footer>
        </Modal>
    );
};

export default ContractInviteModal;
