import { ContractList, ContractListFinished } from 'components/ContractList/ContractList';
import Search from '../../components/Search/Search';
import { useFetching } from '../../hooks/useFetching';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import EasyRentorService from 'service/EasyRentorService';
import ContractCodeModal from 'components/ContractCodeModal/ContractCodeModal';
import { ContractStatus } from '@/types/domain/contract/constants';

const TenantPropertyList = ({}) => {
    const userData = useSelector((state) => state.users.userData);
    const [activeContracts, setActiveContracts] = useState([]);
    const [finishedContracts, setFinishedContracts] = useState([]);
    const [fetchContracts, loadingContracts, errorContracts] = useFetching(async (id) => {
        const response = await EasyRentorService.getTenantContractsDTO(id);
        const data = response.data.content;
        // TODO: this could be done with single pass through an array, but im not sure if other STATUSES are even used so hardlimiting to those two for now.
        const active = data.filter((item) => item.status === ContractStatus.ACTIVE);
        const activeSorted = active.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
        setActiveContracts(activeSorted);
        const finished = data.filter((item) => item.status === ContractStatus.FINISHED);
        const finishedSorted = finished.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
        setFinishedContracts(finishedSorted);
    });
    useEffect(() => {
        fetchContracts(userData.id);
    }, [userData.id]);

    const [showModal, setShowModal] = useState(false);
    const handleToggleModal = () => {
        setShowModal(!showModal);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const [searchString, setSearchString] = useState('');
    const handleSearch = (values) => {
        setSearchString(values);
    };

    const searchedActiveContracts = useMemo(() => {
        const trimmedSearchString = searchString.trim().toLowerCase();
        if (trimmedSearchString === '') {
            return activeContracts;
        }

        return activeContracts.filter((contract) =>
            contract.property.name.toLowerCase().includes(trimmedSearchString)
        );
    }, [searchString, activeContracts]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '1rem',
                    margin: '3vh 0 6vh 0',
                }}
            >
                <Search onSearch={handleSearch} />
            </div>

            <ContractList
                contracts={searchedActiveContracts}
                loading={loadingContracts}
                openContractCodeInvite={handleToggleModal}
            />

            <ContractListFinished contracts={finishedContracts} loading={loadingContracts} />

            <ContractCodeModal show={showModal} onClose={closeModal} />
        </>
    );
};

export default TenantPropertyList;
