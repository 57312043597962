import styled from 'styled-components';

export const Header = styled.h1`
    font-family: 'Onest', 'sans-serif';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    padding: 0;
    margin: 0;
    color: var(--text-color-dark);
`;

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2vh;
    overflow-x: auto;
`;

export const RoomListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    row-gap: 1rem;
    column-gap: 0.5rem;
`;

export const PhotosGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-template-rows: auto;
    justify-items: center;
    row-gap: 1rem;
    column-gap: 0.5rem;
    margin-bottom: 0.5rem;
    max-height: 510px;
    overflow-y: auto;
`;

export const CardImageContainer = styled.div`
    position: relative;
    height: auto;
    width: auto;
    max-width: 160px;
    max-height: 160px;
    min-width: 150px;
    min-height: 150px;
    aspect-ratio: 1;
    border-radius: 1vh;
    border: 1px solid #efeded;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
    }
`;

export const PreviewImageContainer = styled.div`
    position: relative;
    height: auto;
    width: auto;
    max-width: 80px;
    max-height: 80px;
    min-width: 60px;
    min-height: 60px;
    aspect-ratio: 1;
    border-radius: 1vh;
    border: 1px solid #efeded;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
    img {
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }
`;

export const SquareCardContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
`;

export const PlusButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 80px;
    min-height: 60px;
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 1vh;
    background-color: #c4c4c4;
    border: 1px solid #efeded;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
`;

export const SquarePlusButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    max-width: 160px;
    max-height: 160px;
    min-width: 160px;
    min-height: 160px;
    aspect-ratio: 1;
    border-radius: 1vh;
    background-color: #c4c4c4;
    border: 1px solid #efeded;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
`;

export const FormDataField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);

    span {
        font-family: 'Onest';
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #000000;
    }
`;
