import React, { useEffect, useState } from 'react';
import { Header } from '../../components/PropertyList/PropertyList.styles';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useFetching } from '../../hooks/useFetching';
import EasyRentorService from '../../service/EasyRentorService';
import { TenantPhotoProvider } from '../../context/TenantPhotosContext';
import TenantPhotosManager from '../../components/TenantUploadRoomPhotos/TenantPhotosManager';
import { useValidateParamsUUID } from '../../hooks/useValidateParamsUUID';

const initialData = {
    roomStatus: [],
    newAssets: [],
    original: [],
};

const ContractAddPhotosView = () => {
    const location = useLocation();
    const propertyID = location.state?.propertyID;
    const { contract_uuid } = useParams();
    useValidateParamsUUID(contract_uuid, '/properties');

    const [rooms, setRooms] = useState([]);
    const [fetchRooms, loadingRooms, errorRooms] = useFetching(async (id) => {
        const response = await EasyRentorService.getPropertyRoomsByID(id);
        setRooms(response.data.content);
    });
    useEffect(() => {
        if (propertyID) {
            fetchRooms(propertyID);
        }
    }, [propertyID]);

    if (!propertyID) {
        return <Navigate to={'/contracts'} replace />;
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '3vh 0 3vh 0',
                }}
            >
                <h1 className="font-onest fs-24 fw-700 lh-28 text-color-dark">
                    Tenant room photo upload
                </h1>
            </div>
            <TenantPhotoProvider initialData={initialData}>
                <TenantPhotosManager
                    rooms={rooms}
                    loadingRooms={loadingRooms}
                    propertyID={propertyID}
                />
            </TenantPhotoProvider>
        </>
    );
};

export default ContractAddPhotosView;
