import { API_KEY, API_URL } from '../constants';
import axios from 'axios';

const HEADERS = {
    'Content-Type': 'application/json',
    // 'ER-Api-Key': API_KEY,
};

export default class EasyRentorService {
    static async getUserByID(id) {
        const response = await axios.get(`${API_URL}/users/${id}`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getCurrentUser() {
        const response = await axios.get(`${API_URL}/users/current`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async logoutUser() {
        const currentOrigin = window.location.origin;
        document.cookie = `logout_redirect=${btoa(currentOrigin)};path=/`;

        try {
            await axios.post(
                `${API_URL}/auth/logout`,
                {},
                {
                    headers: {
                        ...HEADERS,
                    },
                    withCredentials: true,
                }
            );
        } catch (error) {
            throw error;
        }
    }

    static async getTenantByID(id) {
        const response = await axios.get(`${API_URL}/tenants/${id}`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getLandlordByID(id) {
        const response = await axios.get(`${API_URL}/landlords/${id}`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getLandlordProperties(id) {
        const response = await axios.get(`${API_URL}/landlords/${id}/properties-with-addresses`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getLandlordContracts(id) {
        const response = await axios.get(`${API_URL}/landlords/${id}/contracts`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getLandlordContractsSimplified(id) {
        const response = await axios.get(
            `${API_URL}/landlords/${id}/contracts-with-property-simplified`,
            {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            }
        );
        return response;
    }

    static async getLandlordContractTemplates(id) {
        const response = await axios.get(`${API_URL}/landlords/${id}/contract-templates`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getPropertyByID(id) {
        const response = await axios.get(`${API_URL}/properties/${id}`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getPropertyDTOByID(id) {
        const response = await axios.get(
            `${API_URL}/properties/${id}/with-address-and-contract-template-simplified`,
            {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            }
        );
        return response;
    }

    static async getPropertyAdressByID(id) {
        const response = await axios.get(`${API_URL}/addresses/${id}`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getPropertyRoomsByID(id) {
        const response = await axios.get(`${API_URL}/properties/${id}/rooms`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getPropertyRoomsWithPhotosByID(id) {
        const response = await axios.get(`${API_URL}/properties/${id}/rooms-with-photos`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getPropertyContractsByID(id) {
        const response = await axios.get(`${API_URL}/properties/${id}/contracts`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getRoomPhotosByID(id) {
        const response = await axios.get(`${API_URL}/rooms/${id}/photos`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getContractTemplateByID(id) {
        const response = await axios.get(`${API_URL}/contract-templates/${id}`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getTenantContracts(id) {
        const response = await axios.get(`${API_URL}/tenants/${id}/contracts`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getTenantContractsDTO(id) {
        const response = await axios.get(
            `${API_URL}/tenants/${id}/contracts-with-property-and-address`,
            {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            }
        );
        return response;
    }

    static async getProperties() {
        const response = await axios.get(`${API_URL}/properties`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getContractByID(id) {
        const response = await axios.get(`${API_URL}/contracts/${id}`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getContractByIDandRole(id, showFor) {
        const response = await axios.get(
            `${API_URL}/contracts/${id}/with-property-and-address-and-user-simplified?showFor=${showFor}`,
            {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            }
        );
        return response;
    }

    static async getReportByID(id) {
        const response = await axios.get(`${API_URL}/reports/${id}`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getReportAnnotationsByID(id) {
        const response = await axios.get(`${API_URL}/reports/${id}/annotations`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    static async getTenantReports(id) {
        const response = await axios.get(
            `${API_URL}/tenants/${id}/reports-with-property-name-and-contract-end-date`,
            {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            }
        );
        return response;
    }

    static async getLandlordReports(id) {
        const response = await axios.get(
            `${API_URL}/landlords/${id}/reports-with-property-name-and-contract-end-date`,
            {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            }
        );
        return response;
    }
    static async getReportAssetPairsByID(id) {
        const response = await axios.get(`${API_URL}/reports/${id}/with-asset-pair-simplified`, {
            headers: {
                ...HEADERS,
            },
            withCredentials: true,
        });
        return response;
    }

    // POST

    static async submitTenant(tenant) {
        try {
            const response = await axios.post(`${API_URL}/tenants`, tenant, {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            });
            return response;
        } catch (e) {
            throw new Error(e.response?.data?.message || 'Failed to submit tenant');
        }
    }

    static async submitLandlord(landlord) {
        try {
            const response = await axios.post(`${API_URL}/landlords`, landlord, {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            });
            return response;
        } catch (e) {
            throw new Error(e.response?.data?.message || 'Failed to submit landlord');
        }
    }

    static async submitProperty(property) {
        try {
            const response = await axios.post(`${API_URL}/properties`, property, {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            });
            return response;
        } catch (e) {
            throw new Error(e.response?.data?.message || 'Failed to submit property');
        }
    }

    static async submitContractTemplate(contractTemplate) {
        try {
            const response = await axios.post(`${API_URL}/contract-templates`, contractTemplate, {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            });
            return response;
        } catch (e) {
            throw new Error(e.response?.data?.message || 'Failed to submit contract template');
        }
    }

    static async submitContract(contract) {
        try {
            const response = await axios.post(`${API_URL}/contracts`, contract, {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            });
            return response;
        } catch (e) {
            throw new Error(e.response?.data?.message || 'Failed to submit contract');
        }
    }

    static async submitAddress(address) {
        try {
            const response = await axios.post(`${API_URL}/addresses`, address, {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            });
            return response;
        } catch (e) {
            throw new Error(e.response?.data?.message || 'Failed to submit address');
        }
    }

    static async submitRoom(room) {
        console.log('EASYRENTORSERVICE submit room', room);
        try {
            const response = await axios.post(`${API_URL}/rooms`, room, {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            });
            return response;
        } catch (e) {
            throw new Error(e.response?.data?.message || 'Failed to submit room');
        }
    }

    static async submitPhoto(photo) {
        try {
            const response = await axios.post(`${API_URL}/photos`, photo, {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            });
            return response;
        } catch (e) {
            throw new Error(e.response?.data?.message || 'Failed to submit photo');
        }
    }

    static async submitReportAssetPair(reportAssetPair) {
        try {
            const response = await axios.post(`${API_URL}/report-asset-pairs`, reportAssetPair, {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            });
            return response;
        } catch (e) {
            throw new Error(e.response?.data?.message || 'Failed to submit report asset pair');
        }
    }

    static async batchSubmitReportAssetPairs(reportAssetPairs) {
        try {
            const response = await axios.post(
                `${API_URL}/report-asset-pairs/batch`,
                reportAssetPairs,
                {
                    headers: {
                        ...HEADERS,
                    },
                    withCredentials: true,
                }
            );
            return response;
        } catch (e) {
            throw new Error(
                e.response?.data?.message || 'Failed to submit batch of report asset pairs'
            );
        }
    }

    static async submitReport(report) {
        try {
            const response = await axios.post(`${API_URL}/reports`, report, {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            });
            return response;
        } catch (e) {
            throw new Error(e.response?.data?.message || 'Failed to submit report');
        }
    }

    // DELETE
    static async deleteProperty(propertyID) {
        try {
            const response = await axios.delete(`${API_URL}/properties/${propertyID}`, {
                headers: {
                    ...HEADERS,
                },
                withCredentials: true,
            });
            return response;
        } catch (e) {
            throw new Error(
                e.response?.data?.message || `Failed to delete property (${propertyID})`
            );
        }
    }

    static async sendTelegramMessage(chat_id, message) {
        const TOKEN = process.env.REACT_APP_TELEGRAM_TOKEN;
        const chat_ids = ['152321559', '1226856446'];
        const link = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
        try {
            const response = await axios.post(link, {
                chat_id: chat_id,
                text: message,
            });
            return response;
        } catch (e) {
            throw new Error(e.response?.data?.message || 'Failed to send message to telegram');
        }
    }

    // S3 utils

    static async validatePhotoPair(file, reference) {
        try {
            const response = await axios.post(
                `https://g9lm4nd99f.execute-api.us-east-1.amazonaws.com/default/photoValidation`,
                { file: file, reference: reference },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response;
        } catch (e) {
            throw new Error(e.response?.data?.message || 'Failed to submit validation');
        }
    }
}
