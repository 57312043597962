import CustomButton from '@/components/UI/buttons/CustomButton';
import TextArea from '@/components/UI/inputs/TextArea/TextArea';
import { ChatIcon } from 'components/UI/icons';
import Wrapper from 'components/UI/wrappers/Wrapper/Wrapper';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const CommentModal = ({ name, editable = true, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    const handleSave = () => {
        handleClose();
    };

    return (
        <>
            <CustomButton
                type="button"
                fullWidth={!editable}
                style={{ backgroundColor: 'rgba(124, 124, 124, 1)' }}
                onClick={handleOpen}
            >
                <ChatIcon />
            </CustomButton>
            <Modal
                show={isOpen}
                centered={true}
                animation={true}
                onHide={handleSave}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="justify-content-center font-poppins fw-400 fs-16 lh-26 text-color-black">
                        {editable ? 'Write your comment here' : 'Landlord comments'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editable ? (
                        <TextArea
                            id={name}
                            name={name}
                            placeholder={'Write here...'}
                            maxLength={96}
                            autoFocus
                        />
                    ) : (
                        <Wrapper>{props.data}</Wrapper>
                    )}
                </Modal.Body>
                {editable && (
                    <Modal.Footer>
                        <div className="w-100 flex-row justify-content-center">
                            <span
                                className="font-poppins fw-600 fs-16 lh-24 text-color-light-blue"
                                onClick={handleSave}
                            >
                                Submit
                            </span>
                        </div>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
};

export default CommentModal;
