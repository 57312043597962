import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    user-select: none;
`;

export const ControlWrapper = styled.div.withConfig({
    shouldForwardProp: (props) => !['align'].includes(props),
})`
    position: absolute;
    height: 100%;
    ${({ align }) => (align === 'left' ? 'left: 0' : align === 'right' ? 'right:0' : '')};
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    z-index: 10;
`;
