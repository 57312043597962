import { CardImageContainer } from 'components/PropertyForms/PropertyForms.styles';
import { useFetching } from 'hooks/useFetching';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import EasyRentorService from 'service/EasyRentorService';
import defaultRoomImage from '../../assets/images/default-room.png';
import { capitalizeString } from 'utils/utils';
import styled from 'styled-components';

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-template-rows: auto;
    justify-items: center;
    row-gap: 1rem;
    column-gap: 0.5rem;
`;

export default function RoomList() {
    const { property_uuid } = useParams();
    const [rooms, setRooms] = useState([]);
    const [fetchRooms, loadingRooms, errorRooms] = useFetching(async (uuid) => {
        const response = await EasyRentorService.getPropertyRoomsByID(uuid);
        setRooms(response.data.content);
    });

    useEffect(() => {
        fetchRooms(property_uuid);
    }, [property_uuid]);

    if (loadingRooms) {
        return (
            <Spinner
                animation="border"
                role="status"
                style={{
                    width: '15rem',
                    height: '15rem',
                    margin: 'auto auto',
                    color: 'var(--primary-color)',
                }}
            />
        );
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '3vh 0 3vh 0',
                }}
            >
                <h1 className="font-onest fw-700 fs-24 lh-28 text-color-dark "> Rooms </h1>
            </div>

            <GridContainer>
                {rooms.map((room) => (
                    <RoomCard key={room.id} roomID={room.id} roomName={room.name} />
                ))}
            </GridContainer>
        </>
    );
}

const RoomCard = ({ roomID, roomName }) => {
    const navigate = useNavigate();
    const [photos, setPhotos] = useState([]);
    const [fetchPhotos, loadingPhotos, errorPhotos] = useFetching(async (uuid) => {
        const response = await EasyRentorService.getRoomPhotosByID(uuid);
        const data = response.data.content;
        const filteredOriginals = data.filter((photo) => photo.original === true);
        setPhotos(filteredOriginals);
    });

    useEffect(() => {
        fetchPhotos(roomID);
    }, [roomID]);

    return (
        <div
            className="flex-col"
            onClick={() => {
                navigate(`${roomID}?name=${encodeURIComponent(roomName)}`);
            }}
        >
            <CardImageContainer>
                <img src={photos[0]?.url || defaultRoomImage} alt="Room preview" />
            </CardImageContainer>
            <div className="font-onest fw-500 fs-16 lh-26 text-center">
                {capitalizeString(roomName)} ({`${photos?.length} photos` || 'loading...'})
            </div>
        </div>
    );
};
