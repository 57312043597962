import React, { useRef, useState, useEffect } from 'react';
import { FullscreenContainer, VideoPreview, CaptureButton, Overlay } from './Camera.styles';
import { useHorizontalOrientation } from '../../hooks/useHorizontalOrientation';
import { useCamera } from '../../hooks/useCamera';

const Camera = ({ onClose }) => {
    const videoRef = useRef(null);
    const [stream, setStream] = useState(null);
    const isHorizontal = useHorizontalOrientation();
    const { getCamera, frontCamera } = useCamera();

    useEffect(() => {
        startCamera();
        return () => {
            stopCamera();
        };
    }, []);

    const startCamera = async () => {
        try {
            const mediaStream = await getCamera();
            videoRef.current.srcObject = mediaStream;
            setStream(mediaStream);
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };

    const stopCamera = () => {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
        }
        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
    };

    const capturePhoto = () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
        canvas.toBlob((blob) => {
            onClose(blob);
        });
    };

    return (
        <FullscreenContainer>
            <VideoPreview ref={videoRef} autoPlay playsInline frontCamera={frontCamera} />
            <CaptureButton onClick={capturePhoto} horizontal={isHorizontal} />
            {!isHorizontal && (
                <Overlay>Please rotate your device to landscape mode to take a photo.</Overlay>
            )}
        </FullscreenContainer>
    );
};

export default Camera;
