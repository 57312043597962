import { useEffect, useState } from "react"


export const useHorizontalOrientation = () => {
    const [horizontal, setHorizontal] = useState(false)

    useEffect(() => {
        const checkOrientation = () => {
            setHorizontal(window.innerWidth > window.innerHeight)
        }

        checkOrientation()

        window.addEventListener('resize', checkOrientation)

        return () => {
            window.removeEventListener('resize', checkOrientation)
        }
    }, [])

    return horizontal
}