import React from 'react';
import { StyledRoundWrapper } from './RoundWrapper.style';

const RoundWrapper = ({ children, size = 3, style, ...props }) => {
    return (
        <StyledRoundWrapper {...props} style={{ ...style }} size={size}>
            {children}
        </StyledRoundWrapper>
    );
};

export default RoundWrapper;
