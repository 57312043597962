import React from 'react';
import { Container, ControlWrapper } from './ImageCarousel.styles';
import AnnotationCanvas from '../AnnotationCanvas/AnnotationCanvas';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

const ImageCarousel = ({
    currentPair,
    dataSize,
    currentStep,
    annotations,
    onStepChange,
    ...props
}) => {
    const { originalAsset, newAsset } = currentPair;

    const handleNext = () => {
        if (currentStep < dataSize) {
            onStepChange((prev) => prev + 1);
        }
    };
    const handlePrev = () => {
        if (currentStep > 0) {
            onStepChange((prev) => prev - 1);
        }
    };

    return (
        <Container>
            <ControlWrapper onClick={handlePrev} align={'left'}>
                <ChevronLeft size={32} strokeWidth={2} color="blue" />
            </ControlWrapper>

            <AnnotationCanvas
                originalAsset={originalAsset.url}
                newAsset={newAsset.url}
                annotations={annotations}
                onToggleAsset={props.onToggleOriginal}
            />

            <ControlWrapper onClick={handleNext} align={'right'}>
                <ChevronRight size={32} strokeWidth={2} color="blue" />
            </ControlWrapper>
        </Container>
    );
};

export default ImageCarousel;
