import React from 'react';

export const EditProfileIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.6 4.30164C12.9785 5.6801 14.32 7.03394 15.6985 8.41241C15.6124 8.51087 15.5016 8.63394 15.3785 8.75702C12.4616 11.6739 9.55697 14.6032 6.61543 17.4955C6.2462 17.8524 5.74158 18.1232 5.26158 18.2955C4.11697 18.677 2.94774 18.9847 1.79081 19.3047C1.07697 19.5016 0.498506 18.9601 0.695429 18.2339C1.05235 16.917 1.4462 15.6001 1.85235 14.2832C1.93851 14.0124 2.12312 13.7539 2.32004 13.5447C5.36004 10.4924 8.40004 7.4524 11.4524 4.4001C11.4893 4.38779 11.5385 4.35087 11.6 4.30164Z"
            fill="#7C7C7C"
        />
        <path
            d="M16.6462 7.46467C15.2677 6.0739 13.9138 4.72006 12.5969 3.37852C13.2738 2.67698 13.9631 1.87698 14.7385 1.18775C15.5754 0.436979 16.8431 0.48621 17.7046 1.23698C18.0862 1.56929 18.4431 1.92621 18.7754 2.30775C19.5385 3.18159 19.5508 4.49852 18.7754 5.36006C18.0985 6.08621 17.36 6.75083 16.6462 7.46467Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const NotificationsProfileIcon = () => (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.2997 14.359H2.64353V15.8974H15.2997V14.359ZM14.6896 6.79842L15.0434 9.92092L16.5721 9.7478L16.2184 6.62524L14.6896 6.79842ZM2.89979 9.92092L3.25351 6.79842L1.72482 6.62524L1.37111 9.7478L2.89979 9.92092ZM1.81986 12.4264C2.41951 11.7205 2.79492 10.8467 2.89979 9.92092L1.37111 9.7478C1.30019 10.3738 1.04667 10.9603 0.647416 11.4302L1.81986 12.4264ZM15.0434 9.92092C15.1483 10.8467 15.5237 11.7205 16.1233 12.4264L17.2958 11.4302C16.8965 10.9603 16.643 10.3738 16.5721 9.7478L15.0434 9.92092ZM2.64353 14.359C1.73399 14.359 1.15545 13.2083 1.81986 12.4264L0.647416 11.4302C-0.807989 13.1433 0.333929 15.8974 2.64353 15.8974V14.359ZM15.2997 15.8974C17.6092 15.8974 18.7512 13.1433 17.2958 11.4302L16.1233 12.4264C16.7877 13.2083 16.2092 14.359 15.2997 14.359V15.8974ZM16.2184 6.62524C15.7925 2.86639 12.6925 0 8.97159 0V1.53846C11.8744 1.53846 14.3479 3.78152 14.6896 6.79842L16.2184 6.62524ZM3.25351 6.79842C3.59526 3.78152 6.06879 1.53846 8.97159 1.53846V0C5.25069 0 2.15063 2.86639 1.72482 6.62524L3.25351 6.79842Z"
            fill="#7C7C7C"
        />
        <path
            d="M12.7688 17.4494C12.918 17.0516 12.7163 16.6083 12.3185 16.4592C11.9206 16.3101 11.4773 16.5117 11.3282 16.9095L12.7688 17.4494ZM6.61499 16.9095C6.4659 16.5117 6.02255 16.3101 5.62473 16.4592C5.22692 16.6083 5.02529 17.0516 5.17437 17.4494L6.61499 16.9095ZM11.3282 16.9095C10.9972 17.7927 10.084 18.4615 8.97161 18.4615V20C10.7003 20 12.204 18.9564 12.7688 17.4494L11.3282 16.9095ZM8.97161 18.4615C7.8592 18.4615 6.94596 17.7927 6.61499 16.9095L5.17437 17.4494C5.73914 18.9564 7.24289 20 8.97161 20V18.4615Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const ProfileEmailDataIcon = () => (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.5785 9.89378C10.3896 10.0118 10.1771 10.0591 9.98819 10.0591C9.79929 10.0591 9.58678 10.0118 9.39787 9.89378L0 4.15588V11.7828C0 13.4121 1.32231 14.7344 2.95159 14.7344H17.0484C18.6777 14.7344 20 13.4121 20 11.7828V4.15588L10.5785 9.89378Z"
            fill="#7C7C7C"
        />
        <path
            d="M17.0484 0H2.95163C1.55847 0 0.377835 0.991736 0.0944824 2.31405L10.0118 8.35891L19.9056 2.31405C19.6222 0.991736 18.4416 0 17.0484 0Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const ProfilePhoneDataIcon = () => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.59627 12.3261C7.87772 10.913 6.73022 9.16923 6.12695 8.17704L5.67695 7.32869C5.83429 7.15987 7.03417 5.87502 7.55414 5.1779C8.20758 4.30251 7.26019 3.51191 7.26019 3.51191C7.26019 3.51191 4.59443 0.845775 3.9869 0.316917C3.37936 -0.212682 2.68002 0.0814575 2.68002 0.0814575C1.40313 0.906491 0.0794147 1.62379 2.68061e-06 5.0735C-0.00295907 8.30329 2.44882 11.6345 5.10014 14.2135C7.75573 17.126 11.4018 20.0452 14.9269 20.0418C18.3762 19.9632 19.0933 18.6396 19.9184 17.3627C19.9184 17.3627 20.2127 16.664 19.6836 16.0559C19.1542 15.448 16.4877 12.7817 16.4877 12.7817C16.4877 12.7817 15.6977 11.8341 14.8221 12.4881C14.1696 12.9758 12.9979 14.0608 12.7156 14.3238C12.7161 14.3247 10.756 13.2798 9.59627 12.3261Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const BottomNavProfileIcon = React.memo(({ active }) => {
    return (
        <svg
            width="20"
            height="24"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.59909 11.7551C12.8618 11.7551 15.5068 9.12361 15.5068 5.87754C15.5068 2.63146 12.8618 0 9.59909 0C6.33637 0 3.69141 2.63146 3.69141 5.87754C3.69141 9.12361 6.33637 11.7551 9.59909 11.7551Z"
                fill={active ? '#1E93FF' : '#9FA5BF'}
            />
            <path
                d="M12.6769 14.2042H6.52306C2.95384 14.2042 0 17.143 0 20.6939C0 21.5511 0.369227 22.2858 1.10769 22.6532C2.21537 23.2654 4.67692 24 9.59998 24C14.5231 24 16.9845 23.2654 18.0922 22.6532C18.7076 22.2858 19.2 21.5511 19.2 20.6939C19.2 17.0205 16.2462 14.2042 12.6769 14.2042Z"
                fill={active ? '#1E93FF' : '#9FA5BF'}
            />
        </svg>
    );
});

export const BottomNavPropertiesIcon = React.memo(({ active }) => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.5545 10.4399C23.554 10.4394 23.5534 10.4388 23.5529 10.4383L13.7618 0.647527C13.3444 0.230004 12.7896 0 12.1994 0C11.6092 0 11.0543 0.229821 10.6368 0.647344L0.850799 10.4331C0.847503 10.4364 0.844207 10.4399 0.84091 10.4432C-0.0161106 11.3052 -0.0146456 12.7037 0.845122 13.5635C1.23792 13.9564 1.75671 14.1841 2.3114 14.2079C2.33392 14.2101 2.35663 14.2112 2.37952 14.2112H2.76976V21.4166C2.76976 22.8424 3.92985 24.0024 5.35602 24.0024H9.18661C9.57483 24.0024 9.88981 23.6877 9.88981 23.2993V17.6502C9.88981 16.9996 10.419 16.4704 11.0697 16.4704H13.3291C13.9797 16.4704 14.5089 16.9996 14.5089 17.6502V23.2993C14.5089 23.6877 14.8237 24.0024 15.2121 24.0024H19.0427C20.4689 24.0024 21.629 22.8424 21.629 21.4166V14.2112H21.9908C22.5809 14.2112 23.1357 13.9813 23.5534 13.5638C24.4141 12.7026 24.4145 11.3017 23.5545 10.4399Z"
                fill={active ? '#1E93FF' : '#9FA5BF'}
            />
        </svg>
    );
});

export const BottomNavReportsIcon = React.memo(({ active }) => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.9906 3.01257H8.43285C7.21504 3.01257 6.22424 4.00337 6.22424 5.22268C6.22424 6.4405 7.21504 7.43129 8.43285 7.43129H21.9906C23.2084 7.43129 24.1992 6.4405 24.1992 5.22268C24.1993 4.00332 23.2085 3.01257 21.9906 3.01257Z"
                fill={active ? '#1E93FF' : '#9FA5BF'}
            />
            <path
                d="M2.40862 3.01257C1.19039 3.01257 0.199219 4.0037 0.199219 5.22193C0.199219 6.44017 1.19039 7.43129 2.40862 7.43129C3.62686 7.43129 4.61798 6.44017 4.61798 5.22193C4.61798 4.0037 3.62686 3.01257 2.40862 3.01257Z"
                fill={active ? '#1E93FF' : '#9FA5BF'}
            />
            <path
                d="M2.40862 9.79065C1.19039 9.79065 0.199219 10.7818 0.199219 12C0.199219 13.2182 1.19039 14.2094 2.40862 14.2094C3.62686 14.2094 4.61798 13.2182 4.61798 12C4.61798 10.7818 3.62686 9.79065 2.40862 9.79065Z"
                fill={active ? '#1E93FF' : '#9FA5BF'}
            />
            <path
                d="M2.40862 16.5687C1.19039 16.5687 0.199219 17.5599 0.199219 18.7781C0.199219 19.9963 1.19039 20.9874 2.40862 20.9874C3.62686 20.9874 4.61798 19.9963 4.61798 18.7781C4.61798 17.5599 3.62686 16.5687 2.40862 16.5687Z"
                fill={active ? '#1E93FF' : '#9FA5BF'}
            />
            <path
                d="M21.9906 9.79065H8.43285C7.21504 9.79065 6.22424 10.7814 6.22424 12.0008C6.22424 13.2186 7.21504 14.2094 8.43285 14.2094H21.9906C23.2084 14.2094 24.1992 13.2186 24.1992 12.0008C24.1993 10.7814 23.2085 9.79065 21.9906 9.79065Z"
                fill={active ? '#1E93FF' : '#9FA5BF'}
            />
            <path
                d="M21.9906 16.5687H8.43285C7.21504 16.5687 6.22424 17.5595 6.22424 18.7788C6.22424 19.9966 7.21504 20.9874 8.43285 20.9874H21.9906C23.2084 20.9874 24.1992 19.9966 24.1992 18.7788C24.1993 17.5595 23.2085 16.5687 21.9906 16.5687Z"
                fill={active ? '#1E93FF' : '#9FA5BF'}
            />
        </svg>
    );
});

export const BottomNavContractsIcon = React.memo(({ active }) => {
    return (
        <svg
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.4741 8.80098H14.7682C13.4232 8.80098 12.3228 7.72098 12.3228 6.40098V0.800977C12.3228 0.360977 11.956 0.000976562 11.5076 0.000976562H3.35629C2.01132 0.000976562 0.910889 1.08098 0.910889 2.40098V21.601C0.910889 22.921 2.01132 24.001 3.35629 24.001H18.8439C20.1888 24.001 21.2893 22.921 21.2893 21.601V9.60098C21.2893 9.16098 20.9224 8.80098 20.4741 8.80098ZM4.82354 19.241C4.13067 19.241 3.56008 18.681 3.56008 18.001C3.56008 17.321 4.13067 16.761 4.82354 16.761C5.5164 16.761 6.087 17.321 6.087 18.001C6.087 18.681 5.5164 19.241 4.82354 19.241ZM4.82354 14.441C4.13067 14.441 3.56008 13.881 3.56008 13.201C3.56008 12.521 4.13067 11.961 4.82354 11.961C5.5164 11.961 6.087 12.521 6.087 13.201C6.087 13.921 5.5164 14.441 4.82354 14.441ZM6.77243 2.90667C6.7749 2.89814 6.77737 2.88957 6.77986 2.88098L6.76706 2.90667H6.77243ZM18.0287 18.401C18.0287 18.841 17.6619 19.201 17.2136 19.201H8.93997C8.49164 19.201 8.12483 18.841 8.12483 18.401V17.601C8.12483 17.161 8.49164 16.801 8.93997 16.801H17.2136C17.6619 16.801 18.0287 17.161 18.0287 17.601V18.401ZM18.0287 13.601C18.0287 14.041 17.6619 14.401 17.2136 14.401H8.93997C8.49164 14.401 8.12483 14.041 8.12483 13.601V12.801C8.12483 12.361 8.49164 12.001 8.93997 12.001H17.2136C17.6619 12.001 18.0287 12.361 18.0287 12.801V13.601ZM16.3984 6.40098H20.7186C21.0447 6.40098 21.2892 6.16098 21.2892 5.84098C21.2892 5.68098 21.2485 5.56098 21.1262 5.44098L15.7463 0.160976C15.624 0.0409764 15.5018 0.000976562 15.3387 0.000976562C15.0127 0.000976562 14.7681 0.240976 14.7681 0.560976V4.80098C14.7681 5.68098 15.5018 6.40098 16.3984 6.40098Z"
                fill={active ? '#1E93FF' : '#9FA5BF'}
            />
        </svg>
    );
});

export const FiltersIcon = () => (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 8C18.2091 8 20 6.2091 20 4C20 1.79086 18.2091 0 16 0C14.1362 0 12.5701 1.27477 12.126 3H1C0.44772 3 0 3.44772 0 4C0 4.55229 0.44772 5 1 5H12.126C12.5701 6.7252 14.1362 8 16 8ZM14 4C14 5.10457 14.8954 6 16 6C17.1046 6 18 5.10457 18 4C18 2.89543 17.1046 2 16 2C14.8954 2 14 2.89543 14 4ZM0 12C0 9.7909 1.79086 8 4 8C5.86384 8 7.42994 9.2748 7.87398 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H7.87398C7.42994 14.7252 5.86384 16 4 16C1.79086 16 0 14.2091 0 12ZM6 12C6 10.8954 5.10457 10 4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14C5.10457 14 6 13.1046 6 12Z"
            fill="#7C7C7C"
            fillOpacity="0.784314"
        />
    </svg>
);

export const SearchIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.8771 19.587L17.8821 16.592C19.3299 14.8135 20.0393 12.5468 19.8637 10.2603C19.6882 7.97372 18.6411 5.84192 16.9389 4.30523C15.2366 2.76854 13.0091 1.94436 10.7166 2.00292C8.42401 2.06149 6.24153 2.99833 4.61993 4.61993C2.99833 6.24153 2.06149 8.42401 2.00292 10.7166C1.94436 13.0091 2.76854 15.2366 4.30523 16.9389C5.84192 18.6411 7.97372 19.6882 10.2603 19.8637C12.5468 20.0393 14.8135 19.3299 16.592 17.8821L19.587 20.8771C19.7593 21.046 19.9909 21.1406 20.2321 21.1406C20.4733 21.1406 20.7049 21.046 20.8771 20.8771C21.0473 20.7056 21.1429 20.4737 21.1429 20.2321C21.1429 19.9904 21.0473 19.7586 20.8771 19.587ZM3.82722 10.9501C3.82722 9.5413 4.24497 8.16417 5.02764 6.99283C5.81031 5.82148 6.92274 4.90853 8.22427 4.36942C9.5258 3.83031 10.958 3.68925 12.3397 3.96409C13.7214 4.23892 14.9905 4.91731 15.9867 5.91346C16.9828 6.90961 17.6612 8.17877 17.9361 9.56047C18.2109 10.9422 18.0698 12.3743 17.5307 13.6759C16.9916 14.9774 16.0787 16.0898 14.9073 16.8725C13.736 17.6552 12.3588 18.0729 10.9501 18.0729C9.06191 18.0699 7.25196 17.3184 5.91683 15.9833C4.58169 14.6482 3.83027 12.8382 3.82722 10.9501Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const PlusIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_287)">
            <path
                d="M14.3997 7.2889H8.71091V1.59998C8.71091 1.20753 8.39229 0.888916 7.99972 0.888916C7.60727 0.888916 7.28865 1.20753 7.28865 1.59998V7.2889H1.59974C1.20729 7.2889 0.888672 7.60752 0.888672 7.99996C0.888672 8.39254 1.20729 8.71116 1.59974 8.71116H7.28865V14.3999C7.28865 14.7925 7.60727 15.1111 7.99972 15.1111C8.39229 15.1111 8.71091 14.7925 8.71091 14.3999V8.71116H14.3997C14.7923 8.71116 15.1109 8.39254 15.1109 7.99996C15.1109 7.60752 14.7923 7.2889 14.3997 7.2889Z"
                fill="#989898"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_287">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const PlusIconBig = () => (
    <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M52.2497 24.7499H30.2501V2.74982C30.2501 1.23216 29.0179 0 27.4998 0C25.9821 0 24.7499 1.23216 24.7499 2.74982V24.7499H2.74982C1.23216 24.7499 0 25.9821 0 27.4998C0 29.0179 1.23216 30.2501 2.74982 30.2501H24.7499V52.2497C24.7499 53.7678 25.9821 55 27.4998 55C29.0179 55 30.2501 53.7678 30.2501 52.2497V30.2501H52.2497C53.7678 30.2501 55 29.0179 55 27.4998C55 25.9821 53.7678 24.7499 52.2497 24.7499Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const LocationIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.85218 1.33337C6.47698 1.33337 5.15811 1.87967 4.1857 2.85208C3.21329 3.82449 2.66699 5.14336 2.66699 6.51856C2.66699 10.7161 7.85218 14.6667 7.85218 14.6667C7.85218 14.6667 13.0374 10.7161 13.0374 6.51856C13.0374 5.14336 12.4911 3.82449 11.5187 2.85208C10.5462 1.87967 9.22737 1.33337 7.85218 1.33337ZM7.85218 8.98769C7.36383 8.98769 6.88645 8.84288 6.4804 8.57157C6.07435 8.30026 5.75788 7.91463 5.57099 7.46346C5.38411 7.01228 5.33521 6.51582 5.43049 6.03685C5.52576 5.55789 5.76092 5.11793 6.10623 4.77262C6.45155 4.4273 6.89151 4.19214 7.37047 4.09687C7.84944 4.00159 8.3459 4.05049 8.79707 4.23737C9.24825 4.42426 9.63388 4.74073 9.90519 5.14678C10.1765 5.55283 10.3213 6.03021 10.3213 6.51856C10.3213 7.17341 10.0612 7.80145 9.59812 8.2645C9.13507 8.72755 8.50703 8.98769 7.85218 8.98769Z"
            fill="#838383"
        />
    </svg>
);

export const PersonIcon = () => (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.99924 9.79593C10.7181 9.79593 12.9223 7.60301 12.9223 4.89795C12.9223 2.19288 10.7181 0 7.99924 0C5.28031 0 3.07617 2.19288 3.07617 4.89795C3.07617 7.60301 5.28031 9.79593 7.99924 9.79593Z"
            fill="#7C7C7C"
        />
        <path
            d="M10.5641 11.8369H5.43589C2.46153 11.8369 0 14.2859 0 17.245C0 17.9593 0.307689 18.5716 0.923078 18.8777C1.84615 19.3879 3.89743 20.0001 7.99998 20.0001C12.1026 20.0001 14.1538 19.3879 15.0769 18.8777C15.5897 18.5716 16 17.9593 16 17.245C16 14.1838 13.5385 11.8369 10.5641 11.8369Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const RoomsBedIcon = () => (
    <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.63379 7.3346V0.816916C1.63379 0.365743 1.26805 0 0.816916 0C0.365743 0 0 0.365743 0 0.816916V11.4886C0 11.9398 0.365743 12.3055 0.816916 12.3055C1.26805 12.3055 1.63379 11.9398 1.63379 11.4886V9.95816L18.3663 9.91883V11.4493C18.3663 11.9004 18.732 12.2662 19.1831 12.2662C19.6343 12.2662 20 11.9004 20 11.4493V9.91883V7.78197V7.29526L1.63379 7.3346Z"
            fill="#838383"
        />
        <path
            d="M20.0004 6.63478H7.58203V3.55841C7.58203 2.6657 8.30572 1.94202 9.19843 1.94202H16.7373C18.5395 1.94202 20.0004 3.40299 20.0004 5.20522V6.63478Z"
            fill="#838383"
        />
        <path
            d="M4.67498 6.28295C5.77641 6.28295 6.6693 5.39006 6.6693 4.28863C6.6693 3.1872 5.77641 2.29431 4.67498 2.29431C3.57355 2.29431 2.68066 3.1872 2.68066 4.28863C2.68066 5.39006 3.57355 6.28295 4.67498 6.28295Z"
            fill="#838383"
        />
    </svg>
);

export const BackArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.9091 10.6498H3.72417L9.4984 4.8756C9.6026 4.77497 9.68571 4.65459 9.74288 4.5215C9.80006 4.3884 9.83015 4.24525 9.83141 4.1004C9.83267 3.95555 9.80507 3.8119 9.75021 3.67783C9.69536 3.54376 9.61436 3.42195 9.51193 3.31952C9.4095 3.21709 9.28769 3.13609 9.15362 3.08124C9.01955 3.02638 8.8759 2.99878 8.73105 3.00004C8.5862 3.0013 8.44305 3.03139 8.30995 3.08857C8.17686 3.14574 8.05648 3.22885 7.95584 3.33305L0.319407 10.9695C0.114891 11.1741 0 11.4515 0 11.7408C0 12.03 0.114891 12.3075 0.319407 12.512L7.95584 20.1485C8.16159 20.3472 8.43716 20.4572 8.7232 20.4547C9.00923 20.4522 9.28285 20.3375 9.48512 20.1352C9.68738 19.9329 9.80211 19.6593 9.8046 19.3733C9.80708 19.0872 9.69712 18.8117 9.4984 18.6059L3.72417 12.8317H22.9091C23.1984 12.8317 23.4759 12.7167 23.6805 12.5122C23.8851 12.3076 24 12.0301 24 11.7408C24 11.4514 23.8851 11.174 23.6805 10.9694C23.4759 10.7648 23.1984 10.6498 22.9091 10.6498Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const CrossIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.17209 24C0.940281 24 0.71367 23.9313 0.520917 23.8026C0.328165 23.6738 0.17793 23.4908 0.0892159 23.2766C0.000501989 23.0625 -0.0227053 22.8268 0.0225296 22.5995C0.0677644 22.3721 0.179409 22.1633 0.343342 21.9994L21.9995 0.343277C22.2192 0.123481 22.5174 0 22.8282 0C23.139 0 23.4371 0.123481 23.6569 0.343277C23.8767 0.563074 24.0002 0.861182 24.0002 1.17202C24.0002 1.48286 23.8767 1.78097 23.6569 2.00077L2.00083 23.6569C1.8921 23.7658 1.76291 23.8522 1.62069 23.9111C1.47847 23.97 1.32602 24.0002 1.17209 24Z"
            fill="#FF9898"
        />
        <path
            d="M22.8281 24C22.6742 24.0002 22.5217 23.97 22.3795 23.9111C22.2373 23.8522 22.1081 23.7658 21.9994 23.6569L0.343277 2.00077C0.123481 1.78097 0 1.48286 0 1.17202C0 0.861182 0.123481 0.563074 0.343277 0.343277C0.563074 0.123481 0.861182 0 1.17202 0C1.48286 0 1.78097 0.123481 2.00077 0.343277L23.6569 21.9994C23.8208 22.1633 23.9325 22.3721 23.9777 22.5995C24.0229 22.8268 23.9997 23.0625 23.911 23.2766C23.8223 23.4908 23.6721 23.6738 23.4793 23.8026C23.2865 23.9313 23.0599 24 22.8281 24Z"
            fill="#FF9898"
        />
    </svg>
);

export const HouseIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.4628 8.69904C19.4623 8.69858 19.4618 8.69812 19.4614 8.69766L11.3021 0.539551C10.9544 0.19165 10.492 0 10.0001 0C9.50828 0 9.04589 0.191498 8.69796 0.539398L0.542984 8.69339C0.540237 8.69614 0.53749 8.69904 0.534743 8.70178C-0.179441 9.42001 -0.17822 10.5853 0.538253 11.3017C0.865587 11.6292 1.29791 11.8188 1.76015 11.8387C1.77892 11.8405 1.79784 11.8414 1.81692 11.8414H2.14212V17.8453C2.14212 19.0334 3.10886 20 4.29733 20H7.48949C7.81301 20 8.07549 19.7377 8.07549 19.4141V14.707C8.07549 14.1649 8.51651 13.7239 9.05871 13.7239H10.9415C11.4837 13.7239 11.9248 14.1649 11.9248 14.707V19.4141C11.9248 19.7377 12.1871 20 12.5108 20H15.7029C16.8914 20 17.8581 19.0334 17.8581 17.8453V11.8414H18.1597C18.6514 11.8414 19.1138 11.6499 19.4618 11.302C20.1791 10.5844 20.1794 9.41711 19.4628 8.69904Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const BadgeIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.3334 0H11.4062C10.9479 0 10.3074 0.265479 9.98366 0.589016L0.486366 10.0863C-0.162122 10.734 -0.162122 11.796 0.486366 12.443L7.55787 19.5143C8.2049 20.162 9.26589 20.162 9.91389 19.5135L19.4112 10.0178C19.7347 9.69427 20.0002 9.05276 20.0002 8.59549V1.66675C20.0002 0.750247 19.2499 0 18.3334 0ZM14.9999 6.66677C14.0794 6.66677 13.3331 5.91975 13.3331 5.00002C13.3331 4.07877 14.0794 3.33326 14.9999 3.33326C15.9204 3.33326 16.6669 4.07877 16.6669 5.00002C16.6669 5.91975 15.9204 6.66677 14.9999 6.66677Z"
            fill="#7C7C7C"
        />
    </svg>
);

export const ProfileDefaultIcon = () => (
    <svg
        width="160"
        height="160"
        viewBox="0 0 160 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_0_1)">
            <circle cx="80" cy="80" r="70" fill="#C4C4C4" />
            <circle cx="80" cy="80" r="69.5" stroke="#EFEDED" />
        </g>
        <path
            d="M97 55H62C58.15 55 55 58.15 55 62V92.625L65.675 83.35C67.6 81.775 70.225 81.6 72.325 83.175L75.825 85.8L84.575 77.05C86.675 74.95 90 74.95 91.925 77.05L104 89.125V62C104 58.15 100.85 55 97 55ZM67.25 72.5C64.275 72.5 62 70.225 62 67.25C62 64.275 64.275 62 67.25 62C70.225 62 72.5 64.275 72.5 67.25C72.5 70.225 70.225 72.5 67.25 72.5Z"
            fill="#7C7C7C"
        />
        <path
            d="M87.025 79.675L78.275 88.425C77.05 89.65 75.125 89.825 73.725 88.775L70.225 86.15C69.525 85.625 68.65 85.625 68.125 86.15L55 97.35C55.175 101.025 58.15 104 62 104H97C100.85 104 104 100.85 104 97V94.2L89.475 79.675C88.775 78.975 87.725 78.975 87.025 79.675Z"
            fill="#7C7C7C"
        />
        <defs>
            <filter
                id="filter0_d_0_1"
                x="0"
                y="0"
                width="160"
                height="160"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_0_1"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);

export const ChatIcon = () => (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.5491 25.4366C4.00986 25.7631 4.61189 25.7746 5.0524 25.5424L8.20705 23.8804C8.47169 23.7429 8.75765 23.7111 8.9905 23.7852C10.1128 24.1239 11.2986 24.304 12.5052 24.304C19.094 24.304 24.5 18.977 24.5 12.2884C24.5 6.11098 19.7701 0.997817 13.818 0.367891C6.44851 -0.400804 0.5 5.35408 0.5 12.3092C0.5 15.1358 1.50602 17.8883 3.33734 20.0584C3.48545 20.2276 3.54905 20.5029 3.50658 20.7782L2.97732 24.039C2.88192 24.5789 3.10451 25.1189 3.54906 25.4364L3.5491 25.4366ZM7.24862 10.9275C7.7273 10.5905 8.38696 10.7062 8.72394 11.1849L10.2127 13.3022L16.5447 8.96113C17.0275 8.63034 17.685 8.7554 18.0168 9.23622C18.3466 9.71789 18.2236 10.3776 17.7418 10.7083L10.5465 15.6407C10.0685 15.9674 9.4169 15.8505 9.08167 15.3761L6.99128 12.4028C6.65537 11.9241 6.77015 11.2645 7.24862 10.9275Z"
            fill="white"
        />
    </svg>
);
