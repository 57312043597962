import PropertyList from "components/PropertyList";
import Search from "components/Search/Search";
import { useFetching } from "hooks/useFetching";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import EasyRentorService from "service/EasyRentorService";

const LandlordPropertyList = () => {
    const userData = useSelector((state) => state.users.userData);

    const [properties, setProperties] = useState([]);
    const [fetchProperties, loadingProperties, errorProperties] = useFetching(
        async (id) => {
            const response = await EasyRentorService.getLandlordProperties(id);
            const data = response.data.content;
            setProperties(data);
        }
    );

    useEffect(() => {
        fetchProperties(userData.id);
    }, []);

    const [searchString, setSearchString] = useState("");
    const handleSearch = (values) => {
        setSearchString(values);
    };

    const searchedProperties = useMemo(() => {
        const trimmedSearchString = searchString.trim().toLowerCase();
        if (trimmedSearchString === "") {
            return properties;
        }

        return properties.filter((property) =>
            property.name.toLowerCase().includes(trimmedSearchString)
        );
    }, [searchString, properties]);
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                    margin: "3vh 0 6vh 0",
                }}
            >
                <Search onSearch={handleSearch} />
            </div>

            <PropertyList
                properties={searchedProperties}
                loading={loadingProperties}
            />
        </>
    );
};

export default LandlordPropertyList;
