import { FC } from 'react';
import { useField } from 'formik';
import { CustomSelectProps } from '@/components/UI/inputs/CustomSelect/types';
import styles from './CustomSelect.module.css';
import clsx from 'clsx';

const CustomSelect: FC<CustomSelectProps> = ({
    label,
    options,
    defaultValue,
    className,
    ...props
}) => {
    const [field, meta] = useField(props.name);
    return (
        <div className={styles.container}>
            {label && (
                <label htmlFor={props.id || props.name} className={styles.label}>
                    {label}
                </label>
            )}
            <div className={styles.selectWrapper}>
                <select {...field} {...props} className={clsx(styles.select, className)}>
                    {defaultValue && (
                        <option value="" disabled>
                            {defaultValue}
                        </option>
                    )}

                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
            </div>
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
        </div>
    );
};

export default CustomSelect;
