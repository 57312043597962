import styled from 'styled-components';

export const ImageCanvasContainer = styled.div`
    position: relative;
    // width: calc(80vw - 8vw);
    width: 100vw;
    max-width: min(100vw, 1400px);
    aspect-ratio: 16/9;
    display: flex;
    // border: 1px solid var(--text-color-dark);
    // background-color: rgba(131, 131, 131, 0.5);
    // background-image: radial-gradient(var(--text-color-dark) 1px, transparent 1px),
    //     radial-gradient(var(--text-color-green) 1px, transparent 1px);
    // background-size: 10px 10px;
    // background-position: 0 0, 5px 5px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
`;
