import {
    ACTION_NOTIFICATION_CONNECT_WEBSOCKET,
    ACTION_NOTIFICATION_DISCONNECT_WEBSOCKET,
    ROLE_TENANT,
    STATUS_SOCKET_CONNECTED,
    STATUS_SOCKET_DISCONNECTED,
    STATUS_SOCKET_ERROR,
} from '../constants';
import {
    addNotification,
    connectWebSocket,
    setConnectionStatus,
} from '../actions/notificationActions';

let socket = null;
let heartbeatInterval = null;
let reconnectTimeout = null;

const HEARTBEAT_INTERVAL = 30000; // 30 seconds
const RECONNECT_INTERVAL = 5000; // 5 seconds

const websocketMiddleware = (store) => (next) => (action) => {
    const setupHeartbeat = () => {
        clearInterval(heartbeatInterval);
        heartbeatInterval = setInterval(() => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({ type: 'heartbeat' }));
            } else if (socket && socket.readyState === WebSocket.CLOSED) {
                store.dispatch(connectWebSocket());
            }
        }, HEARTBEAT_INTERVAL);
    };

    const clearReconnectTimeout = () => {
        if (reconnectTimeout) {
            clearTimeout(reconnectTimeout);
            reconnectTimeout = null;
        }
    };

    const setupReconnect = () => {
        clearReconnectTimeout();
        reconnectTimeout = setTimeout(() => {
            store.dispatch(connectWebSocket());
        }, RECONNECT_INTERVAL);
    };

    const closeExistingSocket = () => {
        if (socket) {
            socket.close();
            socket = null;
        }
        clearInterval(heartbeatInterval);
        clearReconnectTimeout();
    };

    switch (action.type) {
        case ACTION_NOTIFICATION_CONNECT_WEBSOCKET: {
            // console.log('ACTION_NOTIFICATION_CONNECT_WEBSOCKET received');
            if (
                socket !== null &&
                (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING)
            ) {
                console.log('WebSocket is already connected or connecting. Skipping reconnection.');
                return next(action);
            }

            // If socket exists but is closing or closed, close it properly before creating a new one
            closeExistingSocket();

            const {
                users: { userData, isAuthenticated },
            } = store.getState();

            if (!isAuthenticated) {
                console.log('User not authenticated, skipping WebSocket connection');
                return next(action);
            }

            socket = new WebSocket('wss://api.easyrentor.com/notifications-socket');

            socket.onopen = () => {
                store.dispatch(setConnectionStatus(STATUS_SOCKET_CONNECTED));
                socket.send(
                    JSON.stringify({
                        type: 'join',
                        userId: userData.id,
                    })
                );
                setupHeartbeat();
            };

            socket.onmessage = (event) => {
                const newNotification = JSON.parse(event.data);
                console.log('NOTIFICATION NEW', newNotification);
                if (newNotification.type !== 'heartbeat') {
                    store.dispatch(addNotification(newNotification, userData.role));
                    // current implementation doesnt have any stack or queue of updates .
                    // it just sends through websocket updates from backend.
                    // It works if you are viewing it on two different sessions (two tabs)
                    // However it breaks when there is one session:
                    // When viewing from LANDLORD (cause only he can change report status) and changing status --> you get notification.
                    // However, when switching to TENANT, you dont see anything, because the message was already sent to LANDLORD and TENANT WAS NOT ONLINE BY THEN.
                    // For this to work Queue Delivery system or some persistent storage needs to be implemented in the backend.

                    // uncommenting this: fixed issue with viewing from one session, but duplicates TENANT notifications if viewed from two different sessions
                    // store.dispatch(addNotification(newNotification, ROLE_TENANT));
                }
            };

            socket.onclose = () => {
                store.dispatch(setConnectionStatus(STATUS_SOCKET_DISCONNECTED));
                // setupReconnect();
            };

            socket.onerror = (error) => {
                console.error('WebSocket error:', error);
                store.dispatch(setConnectionStatus(STATUS_SOCKET_ERROR));
                setupReconnect();
            };
            break;
        }

        case ACTION_NOTIFICATION_DISCONNECT_WEBSOCKET: {
            // console.log('ACTION_NOTIFICATION_DISCONNECT_WEBSOCKET received');
            closeExistingSocket();
            store.dispatch(setConnectionStatus(STATUS_SOCKET_DISCONNECTED));
            break;
        }

        default:
            return next(action);
    }

    return next(action);
};

export default websocketMiddleware;
