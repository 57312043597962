import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useValidateParamsUUID } from '../../hooks/useValidateParamsUUID';
import { useSelector } from 'react-redux';
import { ROLE_LANDLORD, ROLE_TENANT } from '../../constants';
import ReportSingleLandlord from './ReportSingleLandlord';
import ReportSingleTenant from './ReportSingleTenant';
import { useFetching } from 'hooks/useFetching';
import EasyRentorService from 'service/EasyRentorService';
import { Spinner } from 'react-bootstrap';

const ReportSingleView = () => {
    const { report_uuid } = useParams();
    useValidateParamsUUID(report_uuid, '/reports');

    const currentRole = useSelector((state) => state.users.currentRole);

    const [reportStatus, setReportStatus] = useState(null);
    const [reportCreatedAt, setReportCreatedAt] = useState(null);
    const [fetchReport, loadingReport, errorReport] = useFetching(async (id) => {
        const response = await EasyRentorService.getReportByID(id);
        setReportCreatedAt(response.data.createdAt);
        setReportStatus(response.data.status);
    });

    const [annotations, setAnnotations] = useState(null);
    const [fetchAnnotations, loadingAnnotations, errorAnnotations] = useFetching(async (id) => {
        const response = await EasyRentorService.getReportAnnotationsByID(id);
        const data = response.data.content;
        const annotationMap = data.reduce((acc, anno) => {
            if (!acc[anno.assetId]) {
                acc[anno.assetId] = [];
            }
            acc[anno.assetId].push(anno);
            return acc;
        }, {});
        setAnnotations(annotationMap);
    });

    useEffect(() => {
        fetchReport(report_uuid);
        fetchAnnotations(report_uuid);
    }, [report_uuid]);

    const getView = useMemo(() => {
        switch (currentRole) {
            case ROLE_LANDLORD: {
                return (
                    <ReportSingleLandlord
                        report_uuid={report_uuid}
                        reportStatus={reportStatus}
                        reportCreatedAt={reportCreatedAt}
                        annotations={annotations}
                    />
                );
            }
            case ROLE_TENANT: {
                return (
                    <ReportSingleTenant
                        report_uuid={report_uuid}
                        reportStatus={reportStatus}
                        reportCreatedAt={reportCreatedAt}
                        annotations={annotations}
                    />
                );
            }
            default:
                return null;
        }
    }, [currentRole, report_uuid, reportStatus, annotations]);

    if (loadingReport || loadingAnnotations) {
        return (
            <Spinner
                animation="border"
                role="status"
                style={{ width: '10rem', height: '10rem', margin: 'auto auto' }}
            />
        );
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '3vh 0 0 0',
                }}
            >
                <h1 className="font-onest fs-24 fw-700 lh-28 text-color-dark mb-0">
                    Report damage control
                </h1>
            </div>
            {getView}
        </>
    );
};

export default ReportSingleView;
