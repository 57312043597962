import React, { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import { CustomCheckboxProps } from '@/components/UI/inputs/CustomCheckbox/types';
import styles from './CustomCheckbox.module.css';
import clsx from 'clsx';

const CustomCheckbox: FC<CustomCheckboxProps> = ({ label, onChange, className, ...props }) => {
    const [field, meta] = useField(props.name);
    const { setFieldValue } = useFormikContext();

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(props.name, e.target.checked);
        onChange?.(e, setFieldValue);
    };

    return (
        <div className={styles.checkboxContainer}>
            <div className={styles.checkboxRow}>
                {label && (
                    <label htmlFor={props.id || props.name} className={styles.label}>
                        {label}
                    </label>
                )}
                <input
                    type="checkbox"
                    id={props.id || props.name}
                    className={clsx(styles.checkbox, className)}
                    {...field}
                    {...props}
                    checked={field.value}
                    onChange={handleChangeValue}
                />
            </div>
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
        </div>
    );
};

export default CustomCheckbox;
