import React, { useEffect, useMemo, useState } from 'react';
import { useFetching } from '../../hooks/useFetching';
import EasyRentorService from '../../service/EasyRentorService';
import { Spinner } from 'react-bootstrap';
import CustomStepper from '../../components/CustomStepper/CustomStepper';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';
import { REPORT_STATUS } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { formatDateStringISO } from 'utils/utils';
import CommentModal from 'components/UI/modals/CommentModal/CommentModal';
import CustomButton from '@/components/UI/buttons/CustomButton';

const ReportSingleTenant = ({ report_uuid, reportStatus, reportCreatedAt, annotations }) => {
    const navigate = useNavigate();
    const [assetPairs, setAssetPairs] = useState([]);
    const [fetchAssetPairs, loadingAssetPairs, errorAssetPairs] = useFetching(async (id) => {
        const response = await EasyRentorService.getReportAssetPairsByID(id);
        const data = response.data.content;
        // showing only those not approved by Landlord
        const filtered = data.filter((pair) => pair.approvedByLandlord === false);
        // console.log(filtered);
        setAssetPairs(filtered);
    });

    useEffect(() => {
        fetchAssetPairs(report_uuid);
    }, [report_uuid]);

    const [step, setStep] = useState(0);
    const [visibleOriginal, setVisibleOriginal] = useState(false);
    const toggleOriginal = (showOriginal) => {
        setVisibleOriginal(showOriginal);
    };
    const assetAnnotations = useMemo(() => {
        if (loadingAssetPairs || !annotations) {
            return null;
        }
        const currentPair = assetPairs[step];
        if (!currentPair || !currentPair.newAsset) {
            return null;
        }
        return annotations[currentPair?.newAsset.id];
    }, [assetPairs, step, annotations, loadingAssetPairs]);

    if (loadingAssetPairs) {
        return (
            <Spinner
                animation="border"
                role="status"
                style={{ width: '10rem', height: '10rem', margin: 'auto auto' }}
            />
        );
    }

    if (
        reportStatus === REPORT_STATUS.EXISTS ||
        reportStatus === REPORT_STATUS.WAITING_FOR_ASSETS ||
        reportStatus === REPORT_STATUS.WAITING_FOR_ANNOTATING ||
        reportStatus === REPORT_STATUS.WAITING_FOR_LANDLORD_CHECK
    ) {
        return (
            <>
                <p className="p-3 text-center">
                    Preview unavailable. Report is processing or no asset pair data is available{' '}
                </p>
                <CustomButton className="mt-auto" onClick={() => navigate('/reports')}>
                    Back to Reports
                </CustomButton>
            </>
        );
    }

    if (assetPairs.length === 0) {
        return (
            <>
                <p className="p-3 text-center">This report was finished</p>
                <CustomButton className="mt-auto" onClick={() => navigate('/reports')}>
                    Back to Reports
                </CustomButton>
            </>
        );
    }

    return (
        <>
            <div className="flex-col my-4 font-poppins fw-400 fs-16 lh-26 text-color-dark">
                <span> Created at: {formatDateStringISO(reportCreatedAt)} </span>
                <span> Photo: {visibleOriginal ? 'Original' : 'New asset'} </span>
            </div>

            {/* stepper */}
            <CustomStepper steps={assetPairs.length} currentStep={step} onStepChange={setStep} />

            {/* carousel */}
            <ImageCarousel
                currentPair={assetPairs[step]}
                dataSize={assetPairs.length - 1}
                currentStep={step}
                onStepChange={setStep}
                annotations={assetAnnotations}
                onToggleOriginal={toggleOriginal}
            />
            <div className="mt-3">
                <CommentModal
                    name={`${assetPairs[step]?.id}.landlordComment`}
                    editable={false}
                    data={assetPairs[step]?.landlordComment || 'No comments'}
                />
            </div>
        </>
    );
};

export default ReportSingleTenant;
