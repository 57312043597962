import {
    ContractContainer,
    ListContainer,
} from 'components/ContractListSimple/ContractListSimple.styles';
import RoundWrapper from 'components/UI/wrappers/RoundWrapper/RoundWrapper';
import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CONTRACT_STATUS } from '../../constants';
import {
    Hourglass,
    Exclamation,
    ArrowClockwise,
    Check2,
    PentagonFill,
    PentagonHalf,
    Dot,
    CircleFill,
} from 'react-bootstrap-icons';
import { formatDateString } from 'utils/utils';

const ContractListSimple = ({ contracts, loading }) => {
    return (
        <div style={{ marginBottom: '5rem' }}>
            <h1 className="font-onest fs-24 fw-700 lh-28 text-color-dark"> Contracts </h1>
            <div className="flex-col">
                {loading ? (
                    <Spinner
                        animation="border"
                        role="status"
                        style={{
                            width: '10rem',
                            height: '10rem',
                            margin: '0 auto',
                            color: 'var(--primary-color)',
                        }}
                    />
                ) : (
                    <>
                        {contracts.length > 0 ? (
                            contracts.map((contract) => (
                                <ContractCardSimple
                                    key={contract.id}
                                    contractID={contract.id}
                                    propertyName={contract.property.name}
                                    numOrder={contract.contractNum}
                                    endDate={contract.endDate}
                                    status={contract.status}
                                />
                            ))
                        ) : (
                            <p className="no-items-list-text">
                                Currently, you have no active contracts. You can create a new
                                contract from property view.
                            </p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ContractListSimple;

const ContractCardSimple = React.memo(({ contractID, propertyName, numOrder, endDate, status }) => {
    const navigate = useNavigate();

    const StatusIcon = useMemo(() => {
        switch (status) {
            case CONTRACT_STATUS.WAITING_FOR_SIGNATURES: {
                return <Hourglass size={26} color="grey" opacity={1} strokeWidth={1} />;
            }
            case CONTRACT_STATUS.ANNOTATED: {
                return <PentagonHalf size={28} color="red" opacity={0.6} strokeWidth={1} />;
            }
            case CONTRACT_STATUS.ACTIVE: {
                return <CircleFill size={24} color="green" opacity={0.7} strokeWidth={4} />;
            }
            case CONTRACT_STATUS.FINISHED: {
                return <Check2 size={32} color="green" opacity={0.5} strokeWidth={1} />;
            }
            default:
                return null;
        }
    }, [status]);

    const navigateReportSingle = () => {
        navigate(`/contracts/${contractID}`);
    };

    return (
        <div onClick={navigateReportSingle} style={{ cursor: 'pointer' }}>
            <ContractContainer>
                <div className="flex-col">
                    <span className="font-onest fw-700"> {propertyName} </span>
                    <span className="font-onest"> {formatDateString(endDate)} </span>
                </div>
                <RoundWrapper>{StatusIcon}</RoundWrapper>
            </ContractContainer>
        </div>
    );
});
