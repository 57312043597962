import React, { useContext, useRef } from 'react';
import {
    Header,
    CardImageContainer,
    SquarePlusButton,
    PhotosGridContainer,
} from './PropertyForms.styles';
import RoundButton from '../UI/buttons/RoundButton';
import { PlusIconBig, CrossIcon, BackArrowIcon, NotificationsProfileIcon } from '../UI/icons';
import { FormContext } from '../../context/PropertyFormContext';
import * as Yup from 'yup';
import { FieldArray, Formik, Form, ErrorMessage } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import CustomSelect from '@/components/UI/inputs/CustomSelect/CustomSelect';
import CustomButton from '@/components/UI/buttons/CustomButton';

const roomTypes = [
    { value: 'kitchen', name: 'Kitchen' },
    { value: 'bathroom', name: 'Bathroom' },
    { value: 'dining', name: 'Dining' },
    { value: 'bedroom', name: 'Bedroom' },
    { value: 'hall', name: 'Hall' },
];

const CreateRoomForm = ({ onNextStep, onPrevStep }) => {
    const fileInputRef = useRef(null);

    const { addRoom, addRoomPhotos } = useContext(FormContext);

    const initialValues = {
        type: '',
        photos: [],
    };

    const validationSchema = Yup.object({
        type: Yup.string().required('Required'),
        photos: Yup.array().min(1, 'At least one photo is required').required('Required'),
    });

    const onSubmit = (values) => {
        console.log('CreateRoomForm Submit', values);
        const internalID = uuidv4();
        const newRoom = {
            type: values.type,
            internalID: internalID,
        };
        const roomPhotos = values.photos.map((photo) => ({
            ...photo,
            internalRoomID: internalID,
        }));

        addRoom(newRoom);
        addRoomPhotos(roomPhotos);
        onNextStep();
    };

    const handlePhotoChange = (event, setFieldValue, photos) => {
        const newPhotos = Array.from(event.target.files).map((file) => ({
            url: URL.createObjectURL(file),
            file: file,
            name: file.name,
            type: file.type,
            fileSize: file.size,
        }));

        console.log('[handlePhotoChange]', 'existing photos', photos, 'newPhotos', newPhotos);
        const updatedPhotos = [...photos, ...newPhotos];
        setFieldValue('photos', updatedPhotos);
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <RoundButton onClick={onPrevStep}>
                    {/* Button to go back */}
                    <BackArrowIcon />
                </RoundButton>
                <RoundButton>
                    <NotificationsProfileIcon />
                </RoundButton>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '3vh 0 3vh 0',
                    gap: '0.5rem',
                }}
            >
                <Header> Create room </Header>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ setFieldValue, values }) => (
                    <Form className="flex-col justify-content-between flex-grow-1">
                        <div className="flex-col">
                            <CustomSelect
                                label={'Name of the room'}
                                id="type"
                                name="type"
                                options={roomTypes}
                                placeholder={'Select room type'}
                            />

                            <div className="p-4">
                                <p className="text-center">
                                    Please add photos of rooms from different angles
                                </p>
                            </div>

                            <PhotosGridContainer>
                                <FieldArray name="photos">
                                    {({ remove }) => (
                                        <>
                                            {values.photos.map((photo, index) => (
                                                <CardImageContainer key={index}>
                                                    <img src={photo.url} alt="Room preview" />
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                        }}
                                                    >
                                                        <RoundButton
                                                            type="button"
                                                            size={3}
                                                            onClick={() => remove(index)}
                                                            style={{
                                                                overflow: 'hidden',
                                                                padding: '20%',
                                                                backgroundColor:
                                                                    'rgba(255, 254, 254, 0.9)',
                                                            }}
                                                        >
                                                            <CrossIcon />
                                                        </RoundButton>
                                                    </div>
                                                </CardImageContainer>
                                            ))}
                                        </>
                                    )}
                                </FieldArray>

                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    id="photos"
                                    name="photos"
                                    multiple
                                    style={{ display: 'none' }}
                                    onChange={(event) =>
                                        handlePhotoChange(event, setFieldValue, values.photos)
                                    }
                                />
                                <SquarePlusButton type="button" onClick={triggerFileInput}>
                                    <PlusIconBig />
                                </SquarePlusButton>
                            </PhotosGridContainer>
                            <ErrorMessage name="photos" component="div" className="text-red" />
                        </div>

                        <CustomButton type="submit" className="mt-auto">
                            Save
                        </CustomButton>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreateRoomForm;
